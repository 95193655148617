import { createSlice } from "@reduxjs/toolkit";
import addDays from "date-fns/addDays";

const initialState = {
  showClasses: false,
  uuid: "",
  agentId: 0,
  availableBalance: 0,
  airlineId: 0,
  currency: "GBP",
  loginState: "UK",
  margins: [
    { adult: 0, child: 0, infant: 0, senior: 0, youth: 0, cabinClassCode: "J" },
    { adult: 0, child: 0, infant: 0, senior: 0, youth: 0, cabinClassCode: "Y" },
  ],
  baggageMargins: [
    { adult: 0, child: 0, infant: 0, senior: 0, youth: 0, cabinClassCode: "J" },
    { adult: 0, child: 0, infant: 0, senior: 0, youth: 0, cabinClassCode: "Y" },
  ],
  rate: {},
  weights: [],
  pnr: "",
  searchType: "OW",
  airports: [],
  departAirport: null,
  returnAirport: null,
  departDate: new Date(),
  returnDate: addDays(new Date(), 7),
  adults: 1,
  childs: 0,
  infants: 0,
  seniorCitizens: 0,
  youths: 0,
  cabinClasses: [],
  cabinClassCode: "Y",
  activeDepartTab: "3",
  activeReturnTab: "3",
  availableFltSegments: [],
  journeys: [],
  priceQuote: null,
  searchUuid: "",
  baseCurrencyCode: "IRR",
  activeDepartFlight: null,
  activeDepartRbdId: null,
  activeReturnFlight: null,
  activeReturnRbdId: null,
  email: "",
  mobileDialCode: "",
  mobile: "",
  telephoneDialCode: "",
  telephone: "",
  contactEmail: "",
  contactPhone: "",
  paxDetailsDTOList: [],
  segWiseAncillaryDetails: {},
  serviceAncillaryDetails: {},
};

export const flightSlice = createSlice({
  name: "flight",
  initialState,
  reducers: {
    setShowClasses: (state, action) => {
      state.showClasses = action.payload;
    },
    setUuid: (state, action) => {
      state.uuid = action.payload;
    },
    setAgentId: (state, action) => {
      state.agentId = action.payload;
    },
    setAvailableBalance: (state, action) => {
      state.availableBalance = action.payload;
    },
    setAirlineId: (state, action) => {
      state.airlineId = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setLoginState: (state, action) => {
      state.loginState = action.payload;
    },
    setMargins: (state, action) => {
      state.margins = action.payload;
    },
    setBaggageMargins: (state, action) => {
      state.baggageMargins = action.payload;
    },
    setRate: (state, action) => {
      state.rate = action.payload;
    },
    setWeights: (state, action) => {
      state.weights = action.payload;
    },
    setPnr: (state, action) => {
      state.pnr = action.payload;
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },
    setAirports: (state, action) => {
      state.airports = action.payload;
    },
    setCabinClasses: (state, action) => {
      state.cabinClasses = action.payload;
    },
    setCabinClassCode: (state, action) => {
      state.cabinClassCode = action.payload;
    },
    setAdults: (state, action) => {
      state.adults = action.payload;
    },
    setChilds: (state, action) => {
      state.childs = action.payload;
    },
    setInfants: (state, action) => {
      state.infants = action.payload;
    },
    setSeniorCitizens: (state, action) => {
      state.seniorCitizens = action.payload;
    },
    setYouths: (state, action) => {
      state.youths = action.payload;
    },
    setDepartAirport: (state, action) => {
      state.departAirport = action.payload;
    },
    setReturnAirport: (state, action) => {
      state.returnAirport = action.payload;
    },
    setDepartDate: (state, action) => {
      state.departDate = action.payload;
    },
    setReturnDate: (state, action) => {
      state.returnDate = action.payload;
    },
    setAvailableFltSegments: (state, action) => {
      state.availableFltSegments = action.payload;
    },
    setJourneys: (state, action) => {
      state.journeys = action.payload;
    },
    setPriceQuote: (state, action) => {
      state.priceQuote = action.payload;
    },
    setSearchUuid: (state, action) => {
      state.searchUuid = action.payload;
    },
    setBaseCurrencyCode: (state, action) => {
      state.baseCurrencyCode = action.payload;
    },
    setActiveDepartFlight: (state, action) => {
      state.activeDepartFlight = action.payload;
    },
    setActiveDepartRbdId: (state, action) => {
      state.activeDepartRbdId = action.payload;
    },
    setActiveReturnFlight: (state, action) => {
      state.activeReturnFlight = action.payload;
    },
    setActiveReturnRbdId: (state, action) => {
      state.activeReturnRbdId = action.payload;
    },
    setActiveDepartTab: (state, action) => {
      state.activeDepartTab = action.payload;
    },
    setActiveReturnTab: (state, action) => {
      state.activeReturnTab = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setMobileDialCode: (state, action) => {
      state.mobileDialCode = action.payload;
    },
    setMobile: (state, action) => {
      state.mobile = action.payload;
    },
    setTelephoneDialCode: (state, action) => {
      state.telephoneDialCode = action.payload;
    },
    setTelephone: (state, action) => {
      state.telephone = action.payload;
    },
    setContactEmail: (state, action) => {
      state.contactEmail = action.payload;
    },
    setContactPhone: (state, action) => {
      state.contactPhone = action.payload;
    },
    setPaxDetailsDTOList: (state, action) => {
      state.paxDetailsDTOList = action.payload;
    },
    setSegWiseAncillaryDetails: (state, action) => {
      state.segWiseAncillaryDetails = action.payload;
    },
    setServiceAncillaryDetails: (state, action) => {
      state.serviceAncillaryDetails = action.payload;
    },
  },
});

export const {
  setShowClasses,
  setUuid,
  setAgentId,
  setAvailableBalance,
  setAirlineId,
  setCurrency,
  setLoginState,
  setMargins,
  setBaggageMargins,
  setRate,
  setWeights,
  setPnr,
  setSearchType,
  setAirports,
  setCabinClasses,
  setCabinClassCode,
  setAdults,
  setChilds,
  setInfants,
  setSeniorCitizens,
  setYouths,
  setDepartAirport,
  setReturnAirport,
  setDepartDate,
  setReturnDate,
  setAvailableFltSegments,
  setJourneys,
  setPriceQuote,
  setSearchUuid,
  setBaseCurrencyCode,
  setActiveDepartFlight,
  setActiveDepartRbdId,
  setActiveReturnFlight,
  setActiveReturnRbdId,
  setActiveDepartTab,
  setActiveReturnTab,
  setEmail,
  setMobileDialCode,
  setMobile,
  setTelephoneDialCode,
  setTelephone,
  setContactEmail,
  setContactPhone,
  setPaxDetailsDTOList,
  setSegWiseAncillaryDetails,
  setServiceAncillaryDetails,
} = flightSlice.actions;

export default flightSlice.reducer;
