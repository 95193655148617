import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import DatePickerWrapper from "../DatePickerWrapper";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import { Icon } from "@iconify/react";
import Image from "../Image";
import nationalities from "../../services/Nationalities.json";
import countries from "../../services/Countries.json";

const PassengerForm = (props) => {
  const { no, adults, passenger, updatePassenger } = props;
  const [frequentFlyer, setFrequentFlyer] = useState("");
  const cabinClassCode = useSelector(state => state.flight.cabinClassCode)
  const weights = useSelector((state) => state.flight.weights);
  const loginState = useSelector(state => state.flight.loginState)
  const searchType = useSelector(state => state.flight.searchType)

  const getTypeName = () => {
    switch (passenger.paxType) {
      case "ADT":
        return "Adult";
      case "CHD":
        return "Child";
      case "INF":
        return "Infant";
      case "SRC":
        return "Senior Citizen (+65)";
      case "YTH":
        return "Youth (12 - 15)";
      default:
        return "Adult";
    }
  };

  const getAdults = () => {
    let adultItems = [];
    for (let i = 0; i < adults; i++) {
      adultItems.push(i + 1);
    }

    return adultItems;
  };

  const getBaggageWeight = () => {
    const weight = weights.find(
      (weight) =>
        weight.paxType == "ADT" && weight.cabinClassCode == cabinClassCode
    );

    return loginState == "IR" ? weight.IRBaggageWeight : weight.UKBaggageWeight;
  };

  return (
    <Card sx={{ mb: 3, overflow: "inherit" }}>
      <CardHeader
        title={
          <Typography variant="h5">
            Passenger {no}, {getTypeName()}{" "}
            <Box component="span" sx={{ color: "#C9563A" }}>
              *
            </Box>
          </Typography>
        }
        sx={{ pb: 0, px: 3 }}
      />
      <CardContent sx={{ px: 3 }}>
        <DatePickerWrapper>
          <Grid container spacing={3}>
            <Grid item lg={4} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small">Title</InputLabel>
                {(passenger.paxType === "ADT" ||
                  passenger.paxType === "SRC" ||
                  passenger.paxType === "YTH") && (
                  <Select
                    value={passenger.title}
                    size="small"
                    label="Title"
                    onChange={(ev) =>
                      updatePassenger(no - 1, "title", ev.target.value)
                    }
                  >
                    <MenuItem value={1}>Mr</MenuItem>
                    <MenuItem value={2}>Mrs</MenuItem>
                    <MenuItem value={3}>Ms</MenuItem>
                  </Select>
                )}
                {passenger.paxType === "CHD" && (
                  <Select
                    value={passenger.title}
                    size="small"
                    label="Title"
                    onChange={(ev) =>
                      updatePassenger(no - 1, "title", ev.target.value)
                    }
                  >
                    <MenuItem value={4}>Miss</MenuItem>
                    <MenuItem value={5}>Master</MenuItem>
                  </Select>
                )}
                {passenger.paxType === "INF" && (
                  <Select
                    value={passenger.title}
                    size="small"
                    label="Title"
                    onChange={(ev) =>
                      updatePassenger(no - 1, "title", ev.target.value)
                    }
                  >
                    {getAdults().map((adult, idx) => (
                      <MenuItem key={idx} value={adult}>
                        AD{adult}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="First Name"
                  size="small"
                  value={passenger.firstName}
                  onChange={(ev) => {
                    updatePassenger(
                      no - 1,
                      "firstName",
                      ev.target.value.replace(/[!^0-9]/g, "")
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Last Name"
                  size="small"
                  value={passenger.lastName}
                  onChange={(ev) =>
                    updatePassenger(
                      no - 1,
                      "lastName",
                      ev.target.value.replace(/[!^0-9]/g, "")
                    )
                  }
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel size="small">Gender</InputLabel>
                <Select
                  label="Gender"
                  size="small"
                  value={passenger.gender}
                  onChange={(ev) =>
                    updatePassenger(no - 1, "gender", ev.target.value)
                  }
                >
                  <MenuItem value="M">Male</MenuItem>
                  <MenuItem value="F">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <FormControl fullWidth>
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  popperPlacement="bottom-start"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  selected={passenger.dob}
                  onChange={(birthday) =>
                    updatePassenger(no - 1, "dob", birthday)
                  }
                  customInput={
                    <InputMask mask="99/99/9999">
                      {(inputProps) => (
                        <TextField
                          size="small"
                          fullWidth
                          label="Date of Birth"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon icon="mdi:calcendar-month" />
                              </InputAdornment>
                            ),
                          }}
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  value={passenger.nationality}
                  sx={{ width: "100%" }}
                  options={nationalities}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(ev, val) =>
                    updatePassenger(no - 1, "nationality", val)
                  }
                  renderInput={(params) => (
                    <TextField label="Nationality" {...params} size="small" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Passport No"
                  size="small"
                  value={passenger.psptNumber}
                  onChange={(ev) =>
                    updatePassenger(no - 1, "psptNumber", ev.target.value)
                  }
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  value={passenger.psptIssuedCountry}
                  sx={{ width: "100%" }}
                  options={countries}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(ev, val) =>
                    updatePassenger(no - 1, "psptIssuedCountry", val)
                  }
                  renderInput={(params) => (
                    <TextField
                      label="Issued Country"
                      {...params}
                      size="small"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <FormControl fullWidth>
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  popperPlacement="bottom-start"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  selected={passenger.psptExpiryDate}
                  onChange={(expireDate) =>
                    updatePassenger(no - 1, "psptExpiryDate", expireDate)
                  }
                  customInput={
                    <InputMask mask="99/99/9999">
                      {(inputProps) => (
                        <TextField
                          size="small"
                          fullWidth
                          label="Expire Date"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon icon="mdi:calcendar-month" />
                              </InputAdornment>
                            ),
                          }}
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </DatePickerWrapper>
      </CardContent>
      <CardContent
        sx={{ borderTop: "1px solid #ccc", px: 3, pb: "20 !important" }}
      >
        <Grid container spacing={3}>
          <Grid item md={4}>
            {(passenger.paxType === "ADT" || passenger.paxType === "CHD") && (
              <FormControl fullWidth>
                <TextField
                  label="Frequent Flyer"
                  size="small"
                  fullWidth
                  type="number"
                  maxRows={11}
                  value={frequentFlyer}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">096</InputAdornment>
                    ),
                  }}
                  onInput={(ev) => {
                    ev.target.value = Math.max(
                      0,
                      parseInt(ev.target.value).toString().slice(0, 11)
                    );
                  }}
                  onChange={(ev) => setFrequentFlyer(ev.target.value)}
                />
              </FormControl>
            )}
          </Grid>
          <Grid item md={4}>
            <Chip
              color='success'
              label={
                <Box>
                  <Image
                    src='/images/checked-baggage.png'
                    alt='baggage'
                    width='13'
                    height='13'
                    sx={{ marginTop: -2 , marginRight: 8 }}
                  />
                  {searchType == 'OW'
                    ? `Baggage ${getBaggageWeight()}KG included`
                    : `Depart baggage ${getBaggageWeight()}KG included`}
                </Box>
              }
              sx={{ width: '100%', color: '#23262F', background: '#E7F8E9', fontWeight: 'bold', px: .1, py: 2.5 }}
            />
          </Grid>
          {searchType == 'RT' && (
            <Grid item md={4}>
              <Chip
                color='success'
                label={
                  <Box>
                    <Image
                      src='/images/checked-baggage.png'
                      alt='baggage'
                      width='13'
                      height='13'
                      sx={{ marginTop: -2, marginRight: 8 }}
                    />
                    {`Return baggage ${getBaggageWeight()}KG included`}
                  </Box>
                }
                sx={{ width: '100%', color: '#23262F', background: '#E7F8E9', fontWeight: 'bold', px: .1, py: 2.5 }}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PassengerForm;
