import { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
  setContactEmail,
  setContactPhone
} from '../../store/reducers/flightSlice'

const PassengerContactForm = (props) => {
  const dispatch = useDispatch();
  const contactEmail = useSelector(state => state.flight.contactEmail)
  const contactPhone = useSelector(state => state.flight.contactPhone)

  return (
    <Card sx={{ overflow: 'inherit !important' }}>
      <CardHeader
        title={<Typography variant="h5">Contact Detail</Typography>}
      />
      <CardContent sx={{ pt: 0 }}>
        <Typography sx={{ mb: 2 }}>Please provide your contact details</Typography>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Email"
                size="small"
                value={contactEmail}
                onChange={(ev) => dispatch(setContactEmail(ev.target.value))}
              />
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <PhoneInput
                country="gb"
                specialLabel="Phone"
                containerClass="custom-phone-input"
                inputStyle={{ paddingTop: 9, paddingBottom: 9, width: '100%' }}
                value={contactPhone}
                onChange={contactPhone => dispatch(setContactPhone(contactPhone))}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PassengerContactForm;
