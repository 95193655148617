import { styled } from '@mui/material/styles'
import Box  from '@mui/material/Box'
import 'react-datepicker/dist/react-datepicker.css'

const DatePickerWrapper = styled(Box)(() => {
    return {
        '& .react-datepicker-popper': {
            zIndex: 20
          },
          '& .react-datepicker-wrapper': {
            width: '100%'
          },
          '& .react-datepicker': {
            display: 'flex',
            color: 'rgba(0,0,0,1)',
            borderRadius: 10,
            fontFamily: 'Inter,sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Sego UI Symbol"',
            backgroundColor: '#fff',
            boxShadow: '0px 4px 5px -2px rgba(76, 78, 100, 0.2), 0px 7px 10px 1px rgba(76, 78, 100, 0.14), 0px 2px 16px 1px rgba(76, 78, 100, 0.12)',
            border: `1px solid rgba(0,0,0,0.12)`,
            '& .react-datepicker__header': {
              padding: 0,
              border: 'none',
              fontWeight: 'normal',
              backgroundColor: '#fff',
              '&:not(.react-datepicker-year-header)': {
                '& + .react-datepicker__month, & + .react-datepicker__year': {
                  margin: '0.8rem',
                  marginTop: '1.5rem'
                }
              },
              '&.react-datepicker-year-header': {
                '& + .react-datepicker__month, & + .react-datepicker__year': {
                  margin: '0.8rem',
                  marginTop: '1rem'
                }
              }
            },
            '& .react-datepicker__triangle': {
              display: 'none'
            },
            '& > .react-datepicker__navigation': {
              top: 18,
              '&.react-datepicker__navigation--previous': {
                width: 24,
                height: 24,
                border: 'none',
                left: 15,
                backgroundImage: `${"url('data:image/svg+xml,%3Csvg xmlns=\\'http://www.w3.org/2000/svg\\' style=\\'width:24px;height:24px\\' viewBox=\\'0 0 24 24\\'%3E%3Cpath fill=\\'currentColor\\' d=\\'M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z\\' /%3E%3C/svg%3E')"
                  .replace('currentColor', 'rgba(0,0,0,0.6)')
                  .replace('#', '%23')}`,
                '& .react-datepicker__navigation-icon': {
                  display: 'none'
                }
              },
              '&.react-datepicker__navigation--next': {
                width: 24,
                height: 24,
                border: 'none',
                right: 15,
                backgroundImage: `${"url('data:image/svg+xml,%3Csvg xmlns=\\'http://www.w3.org/2000/svg\\' style=\\'width:24px;height:24px\\' viewBox=\\'0 0 24 24\\'%3E%3Cpath fill=\\'currentColor\\' d=\\'M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z\\' /%3E%3C/svg%3E')"
                  .replace('currentColor', 'rgba(0,0,0,0.6)')
                  .replace('#', '%23')}`,
                '& .react-datepicker__navigation-icon': {
                  display: 'none'
                }
              },

              '&.react-datepicker__navigation--next--with-time': { right: 127 },
              '&:focus, &:active': {
                outline: 0
              }
            },
            '& .react-datepicker__month-container': {
              paddingTop: '0.8rem'
            },
            '& .react-datepicker__current-month, & .react-datepicker-year-header': {
              lineHeight: 2.1,
              fontSize: '1rem',
              fontWeight: 'normal',
              letterSpacing: '0.15px',
              marginBottom: '0.5rem',
              color: 'rgba(0,0,0,1)'
            },
            '& .react-datepicker__day-name': {
              margin: 0,
              lineHeight: 1.5,
              width: '2.25rem',
              fontSize: '0.75rem',
              letterSpacing: '0.4px',
              color: 'rgba(0,0,0,0.6)'
            },
            '& .react-datepicker__day': {
              margin: 0,
              width: '2.25rem',
              borderRadius: '50%',
              lineHeight: '2.25rem',
              color: 'rgba(0,0,0,1)',
              '&.react-datepicker__day--selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start, &.react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--in-range, &.react-datepicker__day--range-start':
                {
                  borderTopLeftRadius: '50%',
                  borderBottomLeftRadius: '50%',
                  color: `#fff !important`,
                  backgroundColor: `#254fd2 !important`
                },
              '&.react-datepicker__day--range-end.react-datepicker__day--in-range': {
                borderTopRightRadius: '50%',
                borderBottomRightRadius: '50%',
                color: `#fff !important`,
                backgroundColor: `#254fd2 !important`
              },
              '&:focus, &:active': {
                outline: 0
              },
              '&.react-datepicker__day--outside-month, &.react-datepicker__day--disabled:not(.react-datepicker__day--selected)':
                {
                  color: 'rgba(0,0,0,0.38)',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                },
              '&.react-datepicker__day--highlighted, &.react-datepicker__day--highlighted:hover': {
                color: '#72E128',
                backgroundColor: `#72e1281f !important`,
                '&.react-datepicker__day--selected': {
                  backgroundColor: `#254fd2 !important`
                }
              }
            },
            '& .react-datepicker__day--in-range, & .react-datepicker__day--in-selecting-range': {
              borderRadius: 0,
              color: '#254fd2',
              backgroundColor: `#254fd20f !important`
            },
            '& .react-datepicker__day--today': {
              fontWeight: 'normal',
              '&:not(.react-datepicker__day--selected):not(:empty)': {
                lineHeight: '2.125rem',
                color: '#254fd2',
                border: `1px solid #254fd2`,
                '&:hover': {
                  backgroundColor: '#254fd20a'
                },
                '&.react-datepicker__day--keyboard-selected': {
                  backgroundColor: '#254fd20f',
                  '&:hover': {
                    backgroundColor: '#254fd20f'
                  }
                }
              }
            },
            '& .react-datepicker__month-text--today': {
              fontWeight: 'normal',
              '&:not(.react-datepicker__month--selected)': {
                lineHeight: '2.125rem',
                color: '#254fd2',
                border: `1px solid ${'#254fd2'}`,
                '&:hover': {
                  backgroundColor: '#254fd20a'
                }
              }
            },
            '& .react-datepicker__year-text--today': {
              fontWeight: 'normal',
              '&:not(.react-datepicker__year-text--selected)': {
                lineHeight: '2.125rem',
                color: '#254fd2',
                border: `1px solid #254fd2`,
                '&:hover': {
                  backgroundColor: '#254fd20a'
                },
                '&.react-datepicker__year-text--keyboard-selected': {
                  color: '#254fd2',
                  backgroundColor: '#254fd20f',
                  '&:hover': {
                    color: '#254fd2',
                    backgroundColor: '#254fd20f'
                  }
                }
              }
            },
            '& .react-datepicker__day--keyboard-selected': {
              '&:not(.react-datepicker__day--in-range)': {
                backgroundColor: `#0000000f`,
                '&:hover': {
                  backgroundColor: `#0000000f`
                }
              },
              '&.react-datepicker__day--in-range:not(.react-datepicker__day--range-end)': {
                backgroundColor: `#254fd21f !important`,
                '&:hover': {
                  backgroundColor: `#254fd21f !important`
                }
              }
            },
            '& .react-datepicker__month-text--keyboard-selected': {
              '&:not(.react-datepicker__month--in-range)': {
                color: '#000000',
                backgroundColor: `#0000000f`,
                '&:hover': {
                  color: '#000000',
                  backgroundColor: `#0000000f`
                }
              }
            },
            '& .react-datepicker__year-text--keyboard-selected': {
              color: '#000000',
              backgroundColor: `#0000000f`,
              '&:hover': {
                color: '#000000',
                backgroundColor: `#0000000f`
              }
            },
            '& .react-datepicker__day--selected, & .react-datepicker__month--selected, & .react-datepicker__year-text--selected, & .react-datepicker__quarter--selected':
              {
                color: `'#fff' !important`,
                backgroundColor: `${'#254fd2'} !important`,
                '&:hover': {
                  backgroundColor: `#5A5FE0 !important`
                }
              },
            '& .react-datepicker__header__dropdown': {
              '& .react-datepicker__month-dropdown-container:not(:last-child)': {
                marginRight: '2rem'
              },
              '& .react-datepicker__month-dropdown-container, & .react-datepicker__year-dropdown-container': {
                marginBottom: '1rem'
              },
              '& .react-datepicker__month-read-view--selected-month, & .react-datepicker__year-read-view--selected-year': {
                fontSize: '0.875rem',
                marginRight: '0.25rem',
                color: '#000000'
              },
              '& .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, & .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow':
                {
                  borderColor: '#00000099'
                },
              '& .react-datepicker__month-read-view--down-arrow, & .react-datepicker__year-read-view--down-arrow': {
                top: 4,
                borderColor: '#00000061'
              },
              '& .react-datepicker__month-dropdown, & .react-datepicker__year-dropdown': {
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                borderColor: '#0000001f',
                borderRadius: 10,
                backgroundColor: '#fff',
                boxShadow: '0px 5px 5px -3px rgba(76, 78, 100, 0.2), 0px 8px 10px 1px rgba(76, 78, 100, 0.14), 0px 3px 14px 2px rgba(76, 78, 100, 0.12)'
              },
              '& .react-datepicker__month-option, & .react-datepicker__year-option': {
                paddingTop: '0.25rem',
                paddingBottom: '0.25rem',
                '&:first-of-type, &:last-of-type': {
                  borderRadius: 0
                },
                '&:hover': {
                  backgroundColor: '#0000000d'
                }
              },
              '& .react-datepicker__month-option.react-datepicker__month-option--selected_month': {
                backgroundColor: '#254fd214',
                '&:hover': {
                  backgroundColor: '#254fd21f'
                },
                '& .react-datepicker__month-option--selected': {
                  display: 'none'
                }
              },
              '& .react-datepicker__year-option.react-datepicker__year-option--selected_year': {
                backgroundColor: '#254fd214',
                '&:hover': {
                  backgroundColor: '#254fd21f'
                },
                '& .react-datepicker__year-option--selected': {
                  display: 'none'
                }
              },
              '& .react-datepicker__year-option': {
                // TODO: Remove some of the following styles for arrow in Year dropdown when react-datepicker give arrows in Year dropdown
                '& .react-datepicker__navigation--years-upcoming': {
                  width: 9,
                  height: 9,
                  borderStyle: 'solid',
                  borderWidth: '3px 3px 0 0',
                  transform: 'rotate(-45deg)',
                  borderTopColor: '#00000061',
                  borderRightColor: '#00000061',
                  margin: `0.687rem auto 0rem`
                },
                '&:hover .react-datepicker__navigation--years-upcoming': {
                  borderTopColor: '#00000099',
                  borderRightColor: '#00000099'
                },
                '& .react-datepicker__navigation--years-previous': {
                  width: 9,
                  height: 9,
                  borderStyle: 'solid',
                  borderWidth: '0 0 3px 3px',
                  transform: 'rotate(-45deg)',
                  borderLeftColor: '#00000061',
                  borderBottomColor: '#00000061',
                  margin: `0rem auto 0.687rem`
                },
                '&:hover .react-datepicker__navigation--years-previous': {
                  borderLeftColor: '#00000099',
                  borderBottomColor: '#00000099'
                }
              }
            },
            '& .react-datepicker__week-number': {
              margin: 0,
              fontWeight: 600,
              width: '2.25rem',
              lineHeight: '2.25rem',
              color: '#000000'
            },
            '& .react-datepicker__month-text, & .react-datepicker__year-text, & .react-datepicker__quarter-text': {
              margin: 0,
              alignItems: 'center',
              lineHeight: '2.25rem',
              display: 'inline-flex',
              justifyContent: 'center',
              borderRadius: 10,
              '&:focus, &:active': {
                outline: 0
              }
            },
            '& .react-datepicker__year--container': {
              paddingTop: '0.8rem'
            },
            '& .react-datepicker__year-wrapper': {
              maxWidth: 205,
              justifyContent: 'center'
            },
            '& .react-datepicker__input-time-container': {
              display: 'flex',
              alignItems: 'center',
            },
            '& .react-datepicker__today-button': {
              borderTop: 0,
              borderRadius: '1rem',
              margin: '0rem 1rem 1rem',
              color: '#FFF',
              backgroundColor: '#254fd2'
            },
      
            // ** Time Picker
            '&:not(.react-datepicker--time-only)': {
              '& .react-datepicker__time-container': {
                borderLeftColor: '#0000001f',
                width: '7rem'
              },
              '.react-datepicker-time__header': {
                paddingTop: '0.8rem'
              }
            },
            '&.react-datepicker--time-only': {
              width: '7rem',
              padding: '0.3rem',
              '& .react-datepicker__time-container': {
                width: 'calc(7rem - 2px)'
              }
            },
            '& .react-datepicker__time-container': {
              padding: '0.3rem',
              '& .react-datepicker-time__header': {
                fontSize: '1rem',
                lineHeight: 1.31,
                fontWeight: 'normal',
                letterSpacing: '0.15px',
                marginBottom: '0.75rem',
                color: '#000000'
              },
              '& .react-datepicker__time': {
                background: '#fff',
                '& .react-datepicker__time-box .react-datepicker__time-list-item--disabled': {
                  pointerEvents: 'none',
                  color: '#00000061',
                  '&.react-datepicker__time-list-item--selected': {
                    fontWeight: 'normal',
                    backgroundColor: '#0000001f'
                  }
                }
              },
              '& .react-datepicker__time-list-item': {
                lineHeight: 1.75,
                height: 'auto !important',
                marginLeft: '0.8rem',
                marginRight: '0.3rem',
                color: '#000000',
                borderRadius: 10,
                '&:focus, &:active': {
                  outline: 0
                },
                '&:hover': {
                  backgroundColor: `${'#0000000d'} !important`
                },
                '&.react-datepicker__time-list-item--selected:not(.react-datepicker__time-list-item--disabled)': {
                  fontWeight: '600 !important',
                  color: `'#fff' !important`,
                  backgroundColor: `${'#254fd2'} !important`
                }
              },
              '& .react-datepicker__time-box': {
                width: '100%'
              },
              '& .react-datepicker__time-list': {
                '&::-webkit-scrollbar': {
                  width: 8
                },
      
                /* Track */
                '&::-webkit-scrollbar-track': {
                  background: '#fff'
                },
      
                /* Handle */
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: 10,
                  background: '#aaa'
                },
      
                /* Handle on hover */
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#999'
                }
              }
            },
            '& .react-datepicker__day:hover, & .react-datepicker__month-text:hover, & .react-datepicker__quarter-text:hover, & .react-datepicker__year-text:hover':
              {
                backgroundColor: '#0000000d'
              }
          },
          '& .react-datepicker__close-icon': {
            paddingRight: '1rem',
            '&:after': {
              width: 'unset',
              height: 'unset',
              fontSize: '1.5rem',
              color: '#000000',
              backgroundColor: 'transparent !important'
            }
          }
    }
})

export default DatePickerWrapper