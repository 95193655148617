import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import Image from '../../components/Image'
import { useNavigate } from "react-router-dom";

const ContactConfirmation = () => {
    const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "calc(100vh - 368px);",
      }}
    >
      <Card sx={{ my: 15, flexBasis: 480, textAlign: "center", p: 4 }}>
        <CardContent>
          <Image
            src="https://uploads-ssl.webflow.com/63503c8eb379085bf5801bda/63503c8eb3790818cf801dd1_check.svg"
            width="60"
            height="60"
            alt="tickbox"
          />
          <Typography
            variant="h5"
            sx={{ py: 5 }}
            style={{ fontSize: 20, lineHeight: 1.5 }}
          >
            Your message has been successfully received and is being reviewed by
            our team
          </Typography>
          <Typography variant="p" sx={{ py: 5 }}>
            We will respond to your request as soon as possible, usually within
            24 hours.
          </Typography>
          <Box sx={{ pt: 5 }}>
            <Button
              variant="outlined"
              onClick={() => navigate("/contact")}
              style={{
                border: "1px solid #254fd2",
                padding: "10px 30px",
                borderRadius: 0,
              }}
            >
              Back
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ContactConfirmation;
