import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import flightReducer from './reducers/flightSlice'

export const store = configureStore({
  reducer: {
    flight: flightReducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false
  })
})
