import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Chip,
} from "@mui/material";
import { Icon } from "@iconify/react";
import Image from "../Image";
import moment from "moment";
import { setPaxDetailsDTOList } from "../../store/reducers/flightSlice";
import { ElectricBikeSharp } from "@mui/icons-material";
const timezone = require("moment-timezone");
const jstz = require("jstimezonedetect");

const ExtraBaggageForm = (props) => {
  const { no, passenger, priceQuote } = props;
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.flight.currency);
  const baggageMargins = useSelector((state) => state.flight.baggageMargins);
  const rate = useSelector((state) => state.flight.rate);
  const cabinClassCode = useSelector((state) => state.flight.cabinClassCode);
  const weights = useSelector((state) => state.flight.weights);
  const searchType = useSelector((state) => state.flight.searchType);
  const departAirport = useSelector((state) => state.flight.departAirport);
  const returnAirport = useSelector((state) => state.flight.returnAirport);
  const activeDepartFlight = useSelector(
    (state) => state.flight.activeDepartFlight
  );
  const activeReturnFlight = useSelector(
    (state) => state.flight.activeReturnFlight
  );
  const passengers = useSelector((state) => state.flight.paxDetailsDTOList);
  const segWiseAncillaryDetails = useSelector(
    (state) => state.flight.segWiseAncillaryDetails
  );
  const serviceAncillaryDetails = useSelector(
    (state) => state.flight.serviceAncillaryDetails
  );
  const loginState = useSelector((state) => state.flight.loginState);
  const [isDepartExtraBaggage, setIsDepartExtraBaggage] = useState(false);
  const [isReturnExtraBaggage, setIsReturnExtraBaggage] = useState(false);
  const [isDepartWheelchair, setIsDepartWheelchair] = useState(false);
  const [isReturnWheelchair, setIsReturnWheelchair] = useState(false);

  const getTypeName = () => {
    switch (passenger.paxType) {
      case "ADT":
        return "Adult";
      case "CHD":
        return "Child";
      case "INF":
        return "Infant";
      case "SRC":
        return "Senior Citizen (+65)";
      case "YTH":
        return "Youth (12 - 15)";
      default:
        return "Adult";
    }
  };

  const getGenderType = (gender) => {
    switch (gender) {
      case "M":
        return "Male";
      case "F":
        return "Female";
      default:
        return "Male";
    }
  };

  const getCurrencySymbol = () => {
    switch (currency) {
      case "GBP":
        return "£";
      case "EURO":
        return "€";
      case "USD":
        return "$";
      default:
        return "$";
    }
  };

  const formatDate = (date) => {
    return moment(date, "DD/MM/YYYY HH:mm:ss").format("ddd DD MMM, YYYY");
  };

  const getBaggageMargin = (type) => {
    switch (type) {
      case "ADT":
        return Number(baggageMargins[0].adult);
      case "CHD":
        return Number(baggageMargins[0].child);
      case "INF":
        return Number(baggageMargins[0].infant);
      case "SRC":
        return Number(baggageMargins[0].senior);
      case "YTH":
        return Number(baggageMargins[0].youth);
      default:
        return Number(baggageMargins[0].adult);
    }
  };

  const getBaggagePrice = (type, paxType) => {
    let price = 0;
    if (
      type === "depart" &&
      segWiseAncillaryDetails[activeDepartFlight.fltSegID].length > 0
    ) {
      let departAncillaryDetail = [
        ...segWiseAncillaryDetails[activeDepartFlight.fltSegID],
      ].sort((a, b) => a.toBand - b.toBand);
      if (rate === null) {
        price =
          Number(departAncillaryDetail[0].amount) + getBaggageMargin(paxType);
      } else if (priceQuote) {
        if (loginState == "IR")
          price = (
            (Number(departAncillaryDetail[0].amount) +
              getBaggageMargin(paxType) * rate) /
            rate
          ).toFixed(2);
        else
          price = (
            Number(departAncillaryDetail[0].amount) * rate +
            getBaggageMargin(paxType)
          ).toFixed(2);
      }
    } else if (
      type === "return" &&
      segWiseAncillaryDetails[activeReturnFlight.fltSegID].length > 0
    ) {
      let arrivalAncillaryDetail = [
        ...segWiseAncillaryDetails[activeReturnFlight.fltSegID],
      ].sort((a, b) => a.toBand - b.toBand);
      if (rate === null) {
        price =
          Number(arrivalAncillaryDetail[0].amount) + getBaggageMargin(paxType);
      } else if (priceQuote) {
        if (loginState == "IR")
          price = (
            (Number(arrivalAncillaryDetail[0].amount) +
              getBaggageMargin(paxType) * rate) /
            rate
          ).toFixed(1);
        else
          price = (
            Number(arrivalAncillaryDetail[0].amount) * rate +
            getBaggageMargin(paxType)
          ).toFixed(1);
      }
    }

    return price;
  };

  const handleDepartExtraBaggage = (ev) => {
    let isDepartExtraBaggage = false;
    if (ev.target.value === "false") {
      isDepartExtraBaggage = false;
    } else {
      isDepartExtraBaggage = true;
    }

    let _passengers = passengers.map((_passenger, idx) => {
      if (
        _passenger.paxIndex === passenger.paxIndex &&
        _passenger.paxType === passenger.paxType
      ) {
        return {
          ..._passenger,
          isDepartExtraBaggage: isDepartExtraBaggage,
        };
      }
      return _passenger;
    });

    dispatch(setPaxDetailsDTOList(_passengers));
    setIsDepartExtraBaggage(ev.target.value);
  };

  const handleReturnExtraBaggage = (ev) => {
    let isReturnExtraBaggage = false;
    if (ev.target.value === "false") {
      isReturnExtraBaggage = false;
    } else {
      isReturnExtraBaggage = true;
    }

    let _passengers = passengers.map((_passenger, idx) => {
      if (
        _passenger.paxIndex === passenger.paxIndex &&
        _passenger.paxType === passenger.paxType
      ) {
        return {
          ..._passenger,
          isReturnExtraBaggage: isReturnExtraBaggage,
        };
      }
      return _passenger;
    });

    dispatch(setPaxDetailsDTOList(_passengers));
    setIsReturnExtraBaggage(ev.target.value);
  };

  const handleDepartWheelchair = (ev) => {
    let isDepartWheelchair = false;
    if (ev.target.value === "false") {
      isDepartWheelchair = false;
    } else {
      isDepartWheelchair = true;
    }

    let _passengers = passengers.map((_passenger, idx) => {
      if (
        _passenger.paxIndex === passenger.paxIndex &&
        _passenger.paxType === passenger.paxType
      ) {
        return {
          ..._passenger,
          isDepartWheelchair: isDepartWheelchair,
        };
      } else {
        return _passenger;
      }
    });

    dispatch(setPaxDetailsDTOList(_passengers));
    setIsDepartWheelchair(isDepartWheelchair);
  };

  const handleReturnWheelchair = (ev) => {
    let isReturnWheelchair = false;
    if (ev.target.value === "false") {
      isReturnWheelchair = false;
    } else {
      isReturnWheelchair = true;
    }

    let _passengers = passengers.map((_passenger, idx) => {
      if (
        _passenger.paxIndex === passenger.paxIndex &&
        _passenger.paxType === passenger.paxType
      ) {
        return {
          ...passenger,
          isReturnWheelchair: isReturnWheelchair,
        };
      } else {
        return _passenger;
      }
    });
    dispatch(setPaxDetailsDTOList(_passengers));
    setIsReturnWheelchair(isReturnWheelchair);
  };

  const getBaggageWeight = () => {
    const weight = weights.find(
      (weight) =>
        weight.paxType == "ADT" && weight.cabinClassCode == cabinClassCode
    );

    return loginState == "IR" ? weight.IRBaggageWeight : weight.UKBaggageWeight;
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardHeader
        title={
          <Typography variant="h5">
            Passenger {no}, {getTypeName()}{" "}
            <Box component="span" sx={{ color: "#C9563A" }}>
              *
            </Box>
          </Typography>
        }
        sx={{ pb: 0, px: 3 }}
      />
      <CardContent sx={{ px: 3 }}>
        <Typography>
          <Box
            component={"span"}
            sx={{ display: "inline-block", minWidth: 180 }}
          >
            First Name:
          </Box>
          {passenger.firstName}
        </Typography>
        <Typography>
          <Box
            component={"span"}
            sx={{ display: "inline-block", minWidth: 180 }}
          >
            Last Name:
          </Box>
          {passenger.lastName}
        </Typography>
        <Typography>
          <Box
            component={"span"}
            sx={{ display: "inline-block", minWidth: 180 }}
          >
            Date of Birth:
          </Box>
          {moment(passenger.dob).format("DD/MM/YYYY")}
        </Typography>
        <Typography>
          <Box
            component={"span"}
            sx={{ display: "inline-block", minWidth: 180 }}
          >
            Gender:
          </Box>
          {getGenderType(passenger.gender)}
        </Typography>
        <Typography>
          <Box
            component={"span"}
            sx={{ display: "inline-block", minWidth: 180 }}
          >
            Nationality:
          </Box>
          {passenger.nationality.name}
        </Typography>
        <Typography>
          <Box
            component={"span"}
            sx={{ display: "inline-block", minWidth: 180 }}
          >
            Passport No:
          </Box>
          {passenger.psptNumber}
        </Typography>
        <Typography>
          <Box
            component={"span"}
            sx={{ display: "inline-block", minWidth: 180 }}
          >
            Issued Country:
          </Box>
          {passenger.psptIssuedCountry.name}
        </Typography>
        <Typography>
          <Box
            component={"span"}
            sx={{ display: "inline-block", minWidth: 180 }}
          >
            Passport Expired Date:
          </Box>
          {moment(passenger.psptExpiryDate).format("DD/MM/YYYY")}
        </Typography>
      </CardContent>
      <Box>
        <Divider />
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: -2,
              mb: 1,
            }}
          >
            <Typography>
              ({departAirport.airportCode}) {departAirport.displayShortName}
            </Typography>
            <Box sx={{ pt: 1.6 }}>
              <Typography sx={{ textAlign: "center", fontSize: 14 }}>
                {formatDate(activeDepartFlight.departureDateLocalStr)}
              </Typography>
              <Image
                src="/images/divider.png"
                sx={{ height: 10, width: "100%", minWidth: "300px" }}
              />
              <Box sx={{ textAlign: "center" }}>
                <Chip
                  color="success"
                  label={
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      <Image
                        src={"/images/checked-baggage.png"}
                        width={15}
                        sx={{ marginRight: 10 }}
                      />
                      <Box component="span" sx={{ fontSize: 12 }}>
                        Baggage included {getBaggageWeight()}
                        KG
                      </Box>
                    </Typography>
                  }
                  sx={{
                    color: "#23262F",
                    background: "#E7F8E9",
                    px: 2,
                  }}
                />
              </Box>
            </Box>
            <Typography>
              ({returnAirport.airportCode}) {returnAirport.displayShortName}
            </Typography>
          </Box>
          {segWiseAncillaryDetails[activeDepartFlight.fltSegID].length > 0 &&
            passenger.paxType !== "INF" && (
              <Box>
                <Typography variant="h6">Extra Baggage</Typography>
                <Typography>Other bag that you want to check in.</Typography>
                <Box sx={{ py: 1, display: "flex" }}>
                  <Image
                    src={"/images/baggage.png"}
                    height={50}
                    sx={{ marginRight: 20 }}
                  />
                  <RadioGroup
                    sx={{ flex: 1 }}
                    value={isDepartExtraBaggage}
                    onChange={handleDepartExtraBaggage}
                  >
                    <FormControlLabel
                      label={
                        <Typography
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          No extra checked baggage
                        </Typography>
                      }
                      value={false}
                      control={<Radio size="small" sx={{ px: 0.1, py: 0.1 }} />}
                      sx={{ textTransform: "none", mb: 0 }}
                    />
                    <FormControlLabel
                      label={
                        <Typography
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            Extra 1 x{" "}
                            {
                              weights.find(
                                (weight, idx) =>
                                  weight.paxType === passenger.paxType
                              ).extraBaggageWeight
                            }
                            KG
                          </span>
                          <span>
                            {getCurrencySymbol()}
                            {getBaggagePrice("depart", passenger.paxType)}
                          </span>
                        </Typography>
                      }
                      value={true}
                      control={<Radio size="small" sx={{ px: 0.1, py: 0.1 }} />}
                      sx={{ textTransform: "none" }}
                    />
                  </RadioGroup>
                </Box>
              </Box>
            )}
          {serviceAncillaryDetails[activeDepartFlight.fltSegID]?.[0]
            ?.availableQuantity > 0 &&
            serviceAncillaryDetails[activeDepartFlight.fltSegID]?.[0]?.code ==
              "WCHR" && (
              <Box sx={{ mt: 3, border: "3px solid #ffd600", py: 2, px: 3 }}>
                <Typography variant="h6">Special Services</Typography>
                <FormControlLabel
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Icon icon="mdi:wheelchair" />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        Wheelchair
                      </Typography>
                    </Box>
                  }
                  sx={{ px: 1 }}
                  control={
                    <Checkbox
                      checked={isDepartWheelchair}
                      onChange={handleDepartWheelchair}
                    />
                  }
                />
              </Box>
            )}
        </CardContent>
      </Box>
      {searchType === "RT" && (
        <Box>
          <Divider />
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: -2,
                mb: 1,
              }}
            >
              <Typography>
                ({returnAirport.airportCode}) {returnAirport.displayShortName}
              </Typography>
              <Box sx={{ pt: 1.6 }}>
                <Typography sx={{ textAlign: "center", fontSize: 14 }}>
                  {formatDate(activeReturnFlight.departureDateLocalStr)}
                </Typography>
                <Image
                  src="/images/divider.png"
                  sx={{ height: 10, width: "100%", minWidth: "300px" }}
                />
                <Box sx={{ textAlign: "center" }}>
                  <Chip
                    color="success"
                    label={
                      <Typography
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Image
                          src={"/images/checked-baggage.png"}
                          width={15}
                          sx={{ marginRight: 10 }}
                        />
                        <Box component="span" sx={{ fontSize: 12 }}>
                          Baggage included {getBaggageWeight()}
                          KG
                        </Box>
                      </Typography>
                    }
                    sx={{
                      color: "#23262F",
                      background: "#E7F8E9",
                      px: 2,
                    }}
                  />
                </Box>
              </Box>
              <Typography>
                ({departAirport.airportCode}) {departAirport.displayShortName}
              </Typography>
            </Box>
            {segWiseAncillaryDetails[activeReturnFlight.fltSegID].length > 0 &&
              passenger.paxType !== "INF" && (
                <Box>
                  <Typography variant="h6">Extra Baggage</Typography>
                  <Typography>Other bag that you want to check in.</Typography>
                  <Box sx={{ py: 1, display: "flex" }}>
                    <Image
                      src={"/images/baggage.png"}
                      height={50}
                      sx={{ marginRight: 20 }}
                    />
                    <RadioGroup
                      sx={{ flex: 1 }}
                      value={isReturnExtraBaggage}
                      onChange={handleReturnExtraBaggage}
                    >
                      <FormControlLabel
                        label={
                          <Typography
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            No extra checked baggage
                          </Typography>
                        }
                        value={false}
                        control={
                          <Radio size="small" sx={{ px: 0.1, py: 0.1 }} />
                        }
                        sx={{ textTransform: "none", mb: 0 }}
                      />
                      <FormControlLabel
                        label={
                          <Typography
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              Extra 1 x{" "}
                              {
                                weights.find(
                                  (weight, idx) =>
                                    weight.paxType === passenger.paxType
                                ).extraBaggageWeight
                              }
                              KG
                            </span>
                            <span>
                              {getCurrencySymbol()}
                              {getBaggagePrice("return", passenger.paxType)}
                            </span>
                          </Typography>
                        }
                        value={true}
                        control={
                          <Radio size="small" sx={{ px: 0.1, py: 0.1 }} />
                        }
                        sx={{ textTransform: "none" }}
                      />
                    </RadioGroup>
                  </Box>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <Image
                      src={"/images/star.png"}
                      height={15}
                      sx={{ marginRight: 10 }}
                    />{" "}
                    <Box component="span">
                      Add baggage allowance here. It's up to 50% cheaper than at
                      the airport!
                    </Box>
                  </Typography>
                </Box>
              )}
            {serviceAncillaryDetails[activeReturnFlight.fltSegID]?.[0]
              ?.availableQuantity > 0 &&
              serviceAncillaryDetails[activeReturnFlight.fltSegID]?.[0]?.code ==
                "WCHR" && (
                <Box sx={{ mt: 3, border: "3px solid #ffd600", py: 2, px: 3 }}>
                  <Typography variant="h6">Special Services</Typography>
                  <FormControlLabel
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Icon icon="mdi:wheelchair" />
                        <Typography sx={{ textTransform: "capitalize" }}>
                          Wheelchair
                        </Typography>
                      </Box>
                    }
                    sx={{ px: 1 }}
                    control={
                      <Checkbox
                        checked={isReturnWheelchair}
                        onChange={handleReturnWheelchair}
                      />
                    }
                  />
                </Box>
              )}
          </CardContent>
        </Box>
      )}
    </Card>
  );
};

export default ExtraBaggageForm;
