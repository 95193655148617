import { useState, useEffect, Fragment } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Divider,
  Chip,
  Card,
  CardHeader,
  CardContent,
  Grid,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useAuth } from "../../hooks/useAuth";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";
import Http from "../../services/Http";
import Image from "../../components/Image";

const ConfirmPayment = () => {
  const { setLoading, cookie } = useAuth();
  const navigate = useNavigate();
  const [book, setBook] = useState(null);
  useEffect(() => {
    const anchor = document.querySelector("body");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
    confirmBooking();
  }, []);

  const confirmBooking = async () => {
    setLoading(true);
    const pnr = new URLSearchParams(window.location.search).get("pnr");
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    const { data } = await Http.get("payment/get-payment-key");
    const stripePromise = loadStripe(data.STRIPE_PRIVATE_KEY);
    if (!clientSecret) return;
    stripePromise.then((stripe) => {
      stripe
        .retrievePaymentIntent(clientSecret)
        .then(async ({ paymentIntent }) => {
          switch (paymentIntent.status) {
            case "succeeded":
              const { data: result } = await Http.post(
                "/flight/global-confirm-book",
                {
                  cookie,
                  pnr,
                  orderNumber: paymentIntent.id,
                }
              );
              if (result.status) {
                setBook(result.result);
              } else {
                toast.error("Something went wrong. please try again.");
                // toast.error(result.msg)
              }
              setLoading(false);
              break;
            case "processing":
              toast.error("Your payment was not successful, please try again.");
              setLoading(false);
              navigate("/");
              break;
            default:
              toast.error("Something went wrong");
              setLoading(false);
              break;
          }
        });
    });
  };
  return (
    <Container sx={{ background: "#fafafa", py: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h4">Booking Confirmation</Typography>
        <Button variant="outlined" sx={{ textTransform: "none" }}>
          Print{" "}
          <Icon
            icon="mdi:printer-outline"
            style={{ fontSize: 18, marginLeft: 5 }}
          />
        </Button>
      </Box>
      <Box
        sx={{
          borderLeft: "4px solid #495E1D",
          background: "#fff",
          px: 2,
          py: 2,
          display: "flex",
          mb: 2,
        }}
      >
        <Icon
          icon="mdi:check-circle"
          style={{ fontSize: 25, color: "#62A50D", marginRight: 10 }}
        />
        <Typography>
          Your flight booking has been successfully confirmed.
        </Typography>
      </Box>
      <Divider>
        <Chip
          label="Booking Details"
          variant="outlined"
          color="primary"
          sx={{ borderRadius: 0 }}
        />
      </Divider>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h5">
                Booking Reference: #{book?.referenceId}
              </Typography>
            </Box>
          }
        />
        <CardContent>
          {book && (
            <Box>
              <Grid container spacing={5}>
                <Grid item md={8} xs={12}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    Your booking details:
                  </Typography>
                  <Grid container spacing={0.5}>
                    <Grid item md={3} sm={4} xs={4.5}>
                      <Typography>Departure:</Typography>
                    </Grid>
                    <Grid item md={9} sm={8} xs={7.5}>
                      <Typography>
                        <Box
                          component="span"
                          sx={{ minWidth: 105, display: "inline-block" }}
                        >
                          {book.departFlight.departCode}{" "}
                          {book.departFlight.departCity}
                        </Box>
                        {moment(
                          book.departFlight.departDate,
                          "DD/MM/YYYY HH:mm"
                        ).format("HH:mm ddd, MMM Do YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item md={3} sm={4} xs={4.5}>
                      <Typography>Arrival:</Typography>
                    </Grid>
                    <Grid item md={9} sm={8} xs={7.5}>
                      <Typography>
                        <Box
                          component="span"
                          sx={{ minWidth: 105, display: "inline-block" }}
                        >
                          {book.departFlight.arrivalCode}{" "}
                          {book.departFlight.arrivalCity}
                        </Box>
                        {moment(
                          book.departFlight.arrivalDate,
                          "DD/MM/YYYY HH:mm"
                        ).format("HH:mm ddd, MMM Do YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item md={3} sm={4} xs={4.5}>
                      <Typography>Duration:</Typography>
                    </Grid>
                    <Grid item md={9} sm={8} xs={7.5}>
                      <Typography>{book.departFlight.duration}</Typography>
                    </Grid>
                    <Grid item md={3} sm={4} xs={4.5}>
                      <Typography>Check-in:</Typography>
                    </Grid>
                    <Grid item md={9} sm={8} xs={7.5}>
                      <Typography>{book.departCheckInDateTimeStr}</Typography>
                    </Grid>
                    <Grid item md={3} sm={4} xs={4.5}>
                      <Typography>No of passenger:</Typography>
                    </Grid>
                    <Grid item md={9} sm={8} xs={7.5}>
                      <Typography>
                        <Box
                          component="span"
                          sx={{ display: "inline-block", mr: 1 }}
                        >
                          {book.adults}Adult
                        </Box>
                        {book.childs > 0 && (
                          <Box
                            component="span"
                            sx={{ display: "inline-block", mr: 1 }}
                          >
                            {book.childs}Child
                          </Box>
                        )}
                        {book.infants > 0 && (
                          <Box
                            component="span"
                            sx={{ display: "inline-block", mr: 1 }}
                          >
                            {book.infants}Infant
                          </Box>
                        )}
                        {book.seniors > 0 && (
                          <Box
                            component="span"
                            sx={{ display: "inline-block", mr: 1 }}
                          >
                            {book.seniors}Senior
                          </Box>
                        )}
                        {book.youths > 0 && (
                          <Box
                            component="span"
                            sx={{ display: "inline-block", mr: 1 }}
                          >
                            {book.youths}Youth
                          </Box>
                        )}
                      </Typography>
                    </Grid>
                    {book.departFlight.isExtraBaggage == true && (
                      <Grid item md={3} sm={4} xs={4.5}>
                        <Typography>Extra bags:</Typography>
                      </Grid>
                    )}
                    {book.departFlight.isExtraBaggage == true && (
                      <Grid item md={9} sm={8} xs={7.5}>
                        <Typography>
                          {
                            book.extraBaggagePrices.filter(
                              (baggage) => baggage.type === "OW"
                            ).length
                          }{" "}
                          x 10 KG
                        </Typography>
                      </Grid>
                    )}
                    <Grid item md={3} sm={4} xs={4.5}>
                      <Typography>Flight No:</Typography>
                    </Grid>
                    <Grid item md={9} sm={8} xs={7.5}>
                      <Typography>{book.departFlight.flightNo}</Typography>
                    </Grid>
                    <Grid item md={3} sm={4} xs={4.5}>
                      <Typography>Aircraft:</Typography>
                    </Grid>
                    <Grid item md={9} sm={8} xs={7.5}>
                      <Typography>{book.departFlight.aircraftModel}</Typography>
                    </Grid>
                    {book?.wheelchairs[0].departWheelchair == true && (
                      <Fragment>
                        <Grid item md={3} sm={4} xs={4.5}>
                          <Typography>Wheelchair:</Typography>
                        </Grid>
                        <Grid item md={9} sm={8} xs={7.5}>
                          <Typography>Yes</Typography>
                        </Grid>
                      </Fragment>
                    )}
                  </Grid>
                  {book.flightType === "RT" && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="h6">Return details:</Typography>
                      <Grid container spacing={0.5}>
                        <Grid item md={3} sm={4} xs={4.5}>
                          <Typography>Departure:</Typography>
                        </Grid>
                        <Grid item md={9} sm={8} xs={7.5}>
                          <Typography>
                            <Box
                              component="span"
                              sx={{ minWidth: 120, display: "inline-block" }}
                            >
                              {book.arrivalFlight.departCode}{" "}
                              {book.arrivalFlight.departCity}
                            </Box>
                            {moment(
                              book.arrivalFlight.departDate,
                              "DD/MM/YYYY HH:mm"
                            ).format("HH:mm ddd, MMM Do YYYY")}
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={4} xs={4.5}>
                          <Typography>Arrival:</Typography>
                        </Grid>
                        <Grid item md={9} sm={8} xs={7.5}>
                          <Typography>
                            <Box
                              component="span"
                              sx={{ minWidth: 120, display: "inline-block" }}
                            >
                              {book.arrivalFlight.arrivalCode}{" "}
                              {book.arrivalFlight.arrivalCity}
                            </Box>
                            {moment(
                              book.arrivalFlight.arrivalDate,
                              "DD/MM/YYYY HH:mm"
                            ).format("HH:mm ddd, MMM Do YYYY")}
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={4} xs={4.5}>
                          <Typography>Duration:</Typography>
                        </Grid>
                        <Grid item md={9} sm={8} xs={7.5}>
                          <Typography>{book.arrivalFlight.duration}</Typography>
                        </Grid>
                        <Grid item md={3} sm={4} xs={4.5}>
                          <Typography>Check-in:</Typography>
                        </Grid>
                        <Grid item md={9} sm={8} xs={4.5}>
                          <Typography>
                            {book.arrivalCheckInDateTimeStr}
                          </Typography>
                        </Grid>
                        {book.arrivalFlight.isExtraBaggage == true && (
                          <Grid item md={3} sm={4} xs={4.5}>
                            <Typography>Extra bags:</Typography>
                          </Grid>
                        )}
                        {book.arrivalFlight.isExtraBaggage == true && (
                          <Grid item md={9} sm={8} xs={7.5}>
                            <Typography>
                              {
                                book.extraBaggagePrices.filter(
                                  (baggage) => baggage.type === "RT"
                                ).length
                              }{" "}
                              x 10 KG
                            </Typography>
                          </Grid>
                        )}
                        <Grid item md={3} sm={4} xs={4.5}>
                          <Typography>Flight No</Typography>
                        </Grid>
                        <Grid item md={9} sm={8} xs={7.5}>
                          <Typography>{book.arrivalFlight.flightNo}</Typography>
                        </Grid>
                        <Grid item md={3} sm={4} xs={4.5}>
                          <Typography>Aircraft</Typography>
                        </Grid>
                        <Grid item md={9} sm={8} xs={7.5}>
                          <Typography>
                            {book.arrivalFlight.aircraftModel}
                          </Typography>
                        </Grid>
                        {book?.wheelchairs[0].returnWheelchair == true && (
                          <Fragment>
                            <Grid item md={3} sm={4} xs={4.5}>
                              <Typography>Wheelchair:</Typography>
                            </Grid>
                            <Grid item md={9} sm={8} xs={7.5}>
                              <Typography>Yes</Typography>
                            </Grid>
                          </Fragment>
                        )}
                      </Grid>
                    </Box>
                  )}
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "center",
                        md: "flex-end",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Image
                        src="/images/iranair.png"
                        height={25}
                        alt="IranAir"
                      />
                      <Image
                        src={`${process.env.REACT_APP_UPLOAD_URL}/qrcodes/${book.qrcode}`}
                        width="120"
                        alt="Qrcode"
                      />
                      <Typography sx={{ fontWeight: "bold" }}>
                        PNR: {book.pnr}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ textAlign: "center", py: 3 }}>
                <Button
                  variant="contained"
                  sx={{ background: "#12126E", borderRadius: 0, py: 1.5 }}
                  href={`${process.env.REACT_APP_UPLOAD_URL}/pdfs/${book.ticket}`}
                  target="blank"
                  download={book.ticket}
                >
                  Download your Ticket
                </Button>
              </Box>
              <Typography sx={{ mb: 2 }}>
                Please note that confirmation email has been sent to your
                registered email address, containing all your booking details
                and a downloadable copy of your ticket.
              </Typography>
              <Typography sx={{ mb: 0.5 }}>
                Thank you for choosing for your flight booking.
              </Typography>
              <Typography sx={{ mb: 3 }}>Best Regards</Typography>
              <Box sx={{ background: "#E0E0E0", py: 1, px: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Important information
                </Typography>
                <ul>
                  <li>
                    Check-in Time: We recommend arriving at the airport at least
                    3 hours before your flight's departure time to complete the
                    necessary check-in and security procedures smoothly.
                  </li>
                  <li>
                    Travel Documents: Please ensure you have all the required
                    travel documents, such as passports, visas, and any other
                    necessary identification, for a hassle-free travel
                    experience.
                  </li>
                  <li>
                    Baggage Allowance: Familiarize yourself with the baggage
                    allowance provided by the airline to avoid any inconvenience
                    at the airport.
                  </li>
                  <li>
                    Travel Insurance: We highly recommend considering travel
                    insurance to protect your trip against unforeseen events.
                  </li>
                </ul>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default ConfirmPayment;
