import React, { useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Zoom,
  Box,
  Grid,
  FormControl,
  TextField,
  Autocomplete,
  Button,
  FormHelperText,
  Typography,
} from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import countries from "../../services/_countries.json";
import Http from "../../services/Http";
import toast from "react-hot-toast";
import moment from "moment";

const BusinessBooking = () => {
  useEffect(() => {
    const anchor = document.querySelector("body");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const defaultValues = {
    firstName: "",
    lastName: "",
    corpName: "",
    email: "",
    phone: "",
    country: null,
    city: "",
    address: "",
  };

  const schema = yup.object().shape({
    firstName: yup.string().required("First name is required field."),
    lastName: yup.string().required("Last name is required field."),
    corpName: yup.string().required("Company name is required field."),
    email: yup
      .string()
      .email("Email is invalid.")
      .required("Email is required field."),
    country: yup.mixed().required("Country is required field."),
    city: yup.string().required("City is required field"),
    address: yup.string().required("Address is required field."),
    phone: yup
      .string()
      .min(10, "Phone must be at least 10 digits")
      .required("Phone number is required field."),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onRegister = async (agent) => {
    let country = agent.country.name;
    agent.country = agent.country.abbreviation;
    agent.password = "123456789";
    const { data } = await Http.post("agent/register", agent);
    if (data.status) {
      // const { data: admins } = await Http.get('crms/admins/available-admins');
      // for (let admin of admins) {
      //   socket.emit('notification', {
      //     receiver: admin._id,
      //     content: `New agent registered (${country}, ${agent.corpName}) ${moment().format('DD/MM/YYYY')} at ${moment().format('HH:mm')}`
      //   });
      // }
      reset(defaultValues);
      toast.success("Your request has been successfully submitted.");
    } else {
      toast.error(data.msg);
    }
  };

  return (
    <div className="page-wrapper-2">
      <div className="intro-hero">
        <div className="wrapper-5">
          <Zoom in={true} style={{ transitionDelay: "500ms" }}>
            <div
              data-w-id="adcb3f97-dc6d-e518-d875-1e3afe9b38ab"
              className="hero-intro-2"
            >
              <img
                src="images/IrFlight.svg"
                loading="lazy"
                width="164"
                alt=""
                className="image-5"
              />
              <h1 className="heading-11">
                Join Our Global B2B Network for Streamlined Flights Booking
              </h1>
              <p>
                <span className="text-span-28">Unlock</span>
                <span className="text-span-29"> Competitive Prices </span>
                <span className="text-span-28">
                  and Seamless Flights booking
                  <br />
                  Management Platform for Your Agency
                </span>
              </p>
              <div className="div-block-29">
                <div className="list-grid-item-2">
                  <div className="check-circle-3">
                    <img
                      src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/6478839e446190bbebdd0b31_icon-check-small.svg"
                      alt=""
                    />
                  </div>
                  <div className="text-small-3">Direct IranAir booking</div>
                </div>
                <div className="list-grid-item-2 sw">
                  <div className="check-circle-3">
                    <img
                      src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/6478839e446190bbebdd0b31_icon-check-small.svg"
                      alt=""
                    />
                  </div>
                  <div className="text-small-3">Competitive pricing</div>
                </div>
                <div className="list-grid-item-2 sw">
                  <div className="check-circle-3">
                    <img
                      src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/6478839e446190bbebdd0b31_icon-check-small.svg"
                      alt=""
                    />
                  </div>
                  <div className="text-small-3">Powerful management system</div>
                </div>
              </div>
              <a href="#ready" className="button-13 w-button">
                Open your account
              </a>
            </div>
          </Zoom>
        </div>
        <img
          src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/6475b590fd0a85c9a82c47f2_new%20map.svg"
          alt=""
          className="moving-bg"
        />
      </div>
      <div className="hero-section-03 juy">
        <div className="hero-content-03">
          <div className="inner-hero-content-03">
            <h1 className="heading-1">Effortless Booking System Management</h1>
            <p className="paragraph-6">
              Our powerful platform empowers agents to efficiently handle a
              large volume of flights booking.
            </p>
            <div className="w-layout-grid bulleted-list-grid-vertical-2">
              <div className="bulleted-list-item bg-gray-3 yt">
                <div className="bullet"></div>
                <div className="text-block-25">Cheapest prices</div>
              </div>
              <div className="bulleted-list-item bg-gray-3">
                <div className="bullet"></div>
                <div className="text-block-23">Instant IranAir booking</div>
              </div>
              <div className="bulleted-list-item bg-gray-3">
                <div className="bullet"></div>
                <div className="text-block-24">
                  24/7 efficient customer service
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-03-image-wrap"></div>
      </div>
      <div className="section hero-v5 hidden">
        <div className="wrapper-6">
          <div className="hero-v5-intro">
            <div className="text-label-3">Jules UI Kit Template</div>
            <h1>
              <span className="heading-thin">Advanced UI Kit Template</span> for
              Your Business
            </h1>
            <div className="hero-v5-form-2 w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                className="form-big-2"
                data-wf-page-id="64ac64cdc5098a8368dd3faf"
                data-wf-element-id="adcb3f97-dc6d-e518-d875-1e3afe9b38e7"
              >
                <input
                  type="email"
                  className="form-big-input-2 w-input"
                  maxLength="256"
                  name="email-2"
                  data-name="Email 2"
                  placeholder="Your email address"
                  id="email-2"
                  required=""
                />
                <input
                  type="submit"
                  value="Get Invite"
                  data-wait="Please wait..."
                  className="button-15 w-button"
                />
              </form>
              <div className="form-success-2 dark w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="form-error-2 dark w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
              <div className="form-info">
                <div>
                  By clicking “Get Invite” button, you agree to our Terms and
                  that you have read our Data Use Policy.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="objects">
          <div className="hero-object-10">
            <img
              src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/63964596aa7b42c0f039e07b_cup.svg"
              alt=""
              className="hero-image-10"
            />
          </div>
          <div className="hero-object-14">
            <img
              src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/63964596aa7b42b3f739e07e_notebook.svg"
              alt=""
              className="hero-image-13"
            />
          </div>
          <div className="hero-object-3">
            <img
              src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/63964596aa7b426db839e081_pantone.svg"
              alt=""
              className="hero-image-12"
            />
          </div>
          <div className="hero-object-12">
            <img
              src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/63964596aa7b429fd339e07d_paper-clip.svg"
              alt=""
              className="hero-image-14"
            />
          </div>
          <div className="hero-object-15">
            <img
              src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/63964596aa7b42067039e07c_pen.svg"
              alt=""
              className="hero-image-11"
            />
          </div>
          <div className="hero-object-13">
            <img
              src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/63964596aa7b423c0e39e07a_mouse.svg"
              alt=""
              className="hero-image-15"
            />
          </div>
          <div className="hero-object-11">
            <img
              src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/63964596aa7b425a1239e077_pin.svg"
              alt=""
              className="hero-image-9"
            />
          </div>
          <div className="hero-object-16">
            <img
              src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/63964596aa7b42019439e076_clip.svg"
              alt=""
              className="hero-image-9"
            />
          </div>
          <div className="hero-object-17">
            <img
              src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/63964596aa7b42019439e076_clip.svg"
              alt=""
              className="hero-image-9"
            />
          </div>
        </div>
        <img
          src="https://uploads-ssl.webflow.com/63964595aa7b423d8d39de95/63964596aa7b423b5239df36_decor-v1.svg"
          alt=""
          className="decor-bottom-2"
        />
      </div>
      <div className="section no-padding bav fe">
        <div className="wrapper-5">
          <div className="w-layout-grid main-grid-3">
            <div
              id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b390a-68dd3faf"
              className="w-layout-grid counters-bricks"
            >
              <div
                id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b390b-68dd3faf"
                className="counter centered"
              >
                <div className="counter-number">150+</div>
                <div className="counter-text">Daily Application </div>
              </div>
              <div className="counter centered">
                <div className="counter-number">17,425</div>
                <div className="counter-text">Processed Application</div>
              </div>
              <div
                id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b3915-68dd3faf"
                className="counter centered ok"
              >
                <div className="counter-number">100%</div>
                <div className="counter-text">Approval Rate</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section with-no oferf">
        <div className="wrapper-5">
          <div className="f-section-large">
            <div className="f-container-regular"></div>
            <div className="padding-top padding-xxlarge">
              <div className="home-stats-grid">
                <div id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b3920-68dd3faf">
                  <div
                    id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b3921-68dd3faf"
                    className="margin-bottom-35 margin-xsmall"
                  >
                    <div className="line-2"></div>
                  </div>
                  <div
                    id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b3923-68dd3faf"
                    className="margin-bottom-35 margin-xsmall"
                  >
                    <div className="clip">
                      <h2
                        data-w-id="adcb3f97-dc6d-e518-d875-1e3afe9b3925"
                        // style={{
                        //   WebkitTransform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   MozTransform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   MsTransform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   transform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   opacity: 0,
                        // }}
                        className="text-meta"
                      >
                        Registered agents
                      </h2>
                    </div>
                  </div>
                  <div className="clip">
                    <div
                      data-w-id="adcb3f97-dc6d-e518-d875-1e3afe9b3928"
                      //   style={{
                      //     WebkitTransform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     MozTransform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     MsTransform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     transform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     opacity: 0,
                      //   }}
                      className="heading-small-2 text-uppercase"
                    >
                      80<span className="text-color-secondary">+</span>
                    </div>
                  </div>
                </div>
                <div id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b392c-68dd3faf">
                  <div
                    id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b392d-68dd3faf"
                    className="margin-bottom-35 margin-xsmall"
                  >
                    <div className="line-2"></div>
                  </div>
                  <div
                    id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b392f-68dd3faf"
                    className="margin-bottom-35 margin-xsmall"
                  >
                    <div className="clip">
                      <h2
                        data-w-id="adcb3f97-dc6d-e518-d875-1e3afe9b3931"
                        // style={{
                        //   WebkitTransform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   MozTransform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   MsTransform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   transform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   opacity: 0,
                        // }}
                        className="text-meta"
                      >
                        Issued tickets
                      </h2>
                    </div>
                  </div>
                  <div className="clip">
                    <div
                      data-w-id="adcb3f97-dc6d-e518-d875-1e3afe9b3934"
                      //   style={{
                      //     WebkitTransform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     MozTransform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     MsTransform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     transform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     opacity: 0,
                      //   }}
                      className="heading-small-2 text-uppercase"
                    >
                      4K<span className="text-color-secondary">+</span>
                    </div>
                  </div>
                </div>
                <div id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b3938-68dd3faf">
                  <div
                    id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b3939-68dd3faf"
                    className="margin-bottom-35 margin-xsmall"
                  >
                    <div className="line-2"></div>
                  </div>
                  <div
                    id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b393b-68dd3faf"
                    className="margin-bottom-35 margin-xsmall"
                  >
                    <div className="clip">
                      <h2
                        data-w-id="adcb3f97-dc6d-e518-d875-1e3afe9b393d"
                        // style={{
                        //   WebkitTransform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   MozTransform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   MsTransform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   transform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   opacity: 0,
                        // }}
                        className="text-meta"
                      >
                        Faster booking process
                      </h2>
                    </div>
                  </div>
                  <div className="clip">
                    <div
                      data-w-id="adcb3f97-dc6d-e518-d875-1e3afe9b3940"
                      //   style={{
                      //     WebkitTransform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     MozTransform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     MsTransform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     transform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     opacity: 0,
                      //   }}
                      className="heading-small-2 text-uppercase"
                    >
                      10X
                    </div>
                  </div>
                </div>
                <div id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b3942-68dd3faf">
                  <div
                    id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b3943-68dd3faf"
                    className="margin-bottom-35 margin-xsmall"
                  >
                    <div className="line-2"></div>
                  </div>
                  <div
                    id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b3945-68dd3faf"
                    className="margin-bottom-35 margin-xsmall"
                  >
                    <div className="clip">
                      <h2
                        data-w-id="adcb3f97-dc6d-e518-d875-1e3afe9b3947"
                        // style={{
                        //   WebkitTransform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   MozTransform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   MsTransform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   transform:
                        //     "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        //   opacity: 0,
                        // }}
                        className="text-meta"
                      >
                        booking successful rate{" "}
                      </h2>
                    </div>
                  </div>
                  <div className="clip">
                    <div
                      data-w-id="adcb3f97-dc6d-e518-d875-1e3afe9b394a"
                      //   style={{
                      //     WebkitTransform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     MozTransform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     MsTransform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     transform:
                      //       "translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      //     opacity: 0,
                      //   }}
                      className="heading-small-2 text-uppercase"
                    >
                      99<span className="text-color-secondary">%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section with-no oferf">
        <div className="wrapper-5">
          <section className="section-home-services">
            <div className="page-padding-3">
              <div className="container-xlarge">
                <div className="padding-vertical padding-xhuge">
                  <div className="home-services-grid">
                    <div className="home-services-grid-left">
                      <div className="home-services-grid-left-inner">
                        <div className="max-width-medium">
                          <div className="clip">
                            <div className="margin-bottom-35 margin-xsmall">
                              <img
                                src="images/IrFlight.svg"
                                loading="lazy"
                                width="120"
                                style={{
                                  WebkitTransform:
                                    "translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                  MozTransform:
                                    "translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                  MsTransform:
                                    "translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                  transform:
                                    "translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                }}
                                alt=""
                                data-w-id="adcb3f97-dc6d-e518-d875-1e3afe9b395a"
                              />
                            </div>
                            <h1 className="heading-1 iuy">
                              Global Network and Collaborative Opportunities
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="home-services-about">
                        <a href="#ready" className="button-13 w-button">
                          Open your account
                        </a>
                      </div>
                    </div>
                    <div
                      id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b3961-68dd3faf"
                      className="home-services-grid-right"
                    >
                      <div className="home-services-grid-right-inner">
                        <div
                          id="w-node-adcb3f97-dc6d-e518-d875-1e3afe9b3963-68dd3faf"
                          className="home-services-card-wrapper"
                        >
                          <div className="card-2 muted">
                            <div className="card-padding">
                              <div className="margin-bottom-35 margin-xsmall">
                                <div className="home-services-item-heading">
                                  <h2 className="heading-h3 text-uppercase">
                                    <strong className="bold-text-13">
                                      Competitive Pricing and Global Reach
                                    </strong>
                                  </h2>
                                </div>
                              </div>
                              <div className="text-size-medium-3">
                                {" "}
                                Gain access to exclusive pricing through our
                                extensive network of partners, offering your
                                clients unbeatable rates.
                                <br />
                                <br />
                                Deliver exceptional value to your clients by
                                providing cost-effective visa solutions without
                                compromising on quality or service.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="home-services-card-wrapper">
                          <div className="card-2 muted">
                            <div className="card-padding">
                              <div className="margin-bottom-35 margin-xsmall">
                                <div className="home-services-item-heading">
                                  <h2 className="heading-h3 text-uppercase">
                                    <strong>
                                      Effortless Flight booking Process
                                    </strong>
                                  </h2>
                                </div>
                              </div>
                              <div className="text-size-medium-3">
                                Simplify the process with powerful real-time
                                flight booking, status updates.
                                <br />‍<br />
                                Save time and streamline operations by managing
                                thousands of booking per day, all within a
                                user-friendly interface.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="home-services-card-wrapper">
                          <div className="card-2 muted">
                            <div className="card-padding">
                              <div className="margin-bottom-35 margin-xsmall">
                                <div className="home-services-item-heading">
                                  <h2 className="heading-h3 text-uppercase">
                                    <strong className="bold-text-14">
                                      Analytics and Reporting
                                    </strong>
                                  </h2>
                                </div>
                              </div>
                              <div className="text-size-medium-3">
                                Leverage powerful analytics and reporting tools
                                to gain insights into your flight booking,
                                <br />
                                <br />
                                Access detailed reports, metrics, and
                                visualizations that help you make informed
                                business decisions and optimize your operations.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="home-services-card-wrapper">
                          <div className="card-2 muted">
                            <div className="card-padding">
                              <div className="margin-bottom-35 margin-xsmall">
                                <div className="home-services-item-heading">
                                  <h2 className="heading-h3 text-uppercase">
                                    <strong className="bold-text-14">
                                      Collaboration and Growth Opportunities
                                    </strong>
                                  </h2>
                                </div>
                              </div>
                              <div className="text-size-medium-3">
                                Explore partnership opportunities,
                                cross-referrals, and joint ventures to expand
                                your network and unlock new business
                                possibilities.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="home-services-card-wrapper">
                          <div className="card-2 muted">
                            <div className="card-padding">
                              <div className="margin-bottom-35 margin-xsmall">
                                <div className="home-services-item-heading">
                                  <h2 className="heading-h3 text-uppercase">
                                    <strong className="bold-text-15">
                                      Dedicated Support and Training
                                    </strong>
                                  </h2>
                                </div>
                              </div>
                              <div className="text-size-medium-3">
                                Benefit from our dedicated support team,
                                providing personalized assistance throughout
                                your journey with our B2B system.
                                <br />
                                <br />
                                Receive comprehensive training and guidance on
                                utilizing the platform&#x27;s features to
                                it&#x27;s fullest potential.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="section with-no oferf iuytr">
        <div className="wrapper-5 sp">
          <div id="ready" className="div-block-26"></div>
          <div className="faq-box tr fef">
            <Box>
              <Typography variant="h3" sx={{ fontWeight: "bold", mb: 5 }}>
                Join Our Global B2B Network - Request Joining Today
              </Typography>
              <Typography variant="h5">
                Revolutionize Your Iran Visa Processing, Competetive pricing,
                and limitless growth potential
              </Typography>
            </Box>
            <Box component='form' onSubmit={handleSubmit(onRegister)}>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          label="First name"
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors.firstName)}
                        />
                      )}
                    />
                    {errors.firstName && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.firstName.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          label="Last name"
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors.lastName)}
                        />
                      )}
                    />
                    {errors.lastName && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.lastName.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="corpName"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          label="Agency name"
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors.corpName)}
                        />
                      )}
                    />
                    {errors.corpName && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.corpName.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="address"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          label="Company Address"
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors.address)}
                        />
                      )}
                    />
                    {errors.address && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.address.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="city"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          label="City"
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors.city)}
                        />
                      )}
                    />
                    {errors.city && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.city.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          value={value}
                          options={countries}
                          getOptionLabel={(option) => option.name}
                          onChange={(ev, val) => onChange(val)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country"
                              error={Boolean(errors.country)}
                            />
                          )}
                        />
                      )}
                    />
                    {errors.country && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.country.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          label="Email Address"
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors.email)}
                        />
                      )}
                    />
                    {errors.email && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.email.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          label="Phone Number"
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors.phone)}
                        />
                      )}
                    />
                    {errors.phone && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {errors.phone.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      width: "100%",
                      paddingTop: "13px !important",
                      paddingBottom: "13px !important",
                    }}
                    size="large"
                    color="primary"
                  >
                    Join Now
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
      <div className="section with-no oferf">
        <div className="wrapper-2">
          <div className="faq-box tr">
            <div className="faq-box-info">
              <div className="text-label-2">Answers to</div>
              <h2 className="hand">Frequently Asked Questions</h2>
              <p className="text-grey-2">
                If you couldn&#x27;t find the answer here please visit our
                complete FAQs page
              </p>
              <a href="faq.html" className="button-2 w-button">
                Questions &amp; Answers
              </a>
              <a href="contact-us.html" className="button-2 with w-button">
                Contact Us
              </a>
            </div>
            <div>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    What payment methods do you accept?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    We accept various payment methods, including major credit
                    cards such as Visa, Mastercard, and American Express. We
                    also support payment options like banking transfers. Rest
                    assured that our payment gateway is secure and your personal
                    information is protected.
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    Can I make changes to my booking after it&#x27;s confirmed?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    Yes, we understand that plans may change. Depending on the
                    fare rules and availability, you may be able to make changes
                    to your booking. However, please note that certain changes
                    may incur additional fees or charges. We recommend
                    contacting our customer support team as soon as possible to
                    inquire about any desired changes to your booking.
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    What is the baggage allowance for Iran Air flights?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    The baggage allowance for Iran Air flights may vary
                    depending on your ticket type and destination. Generally,
                    passengers are allowed to bring a certain number of checked
                    baggage and carry-on baggage within specified weight and
                    size limits. We recommend checking the baggage policy on our
                    website or contacting our customer support team for detailed
                    information regarding your specific flight.
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    What if my flight gets delayed or canceled?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    A: In the event of a flight delay or cancellation, our team
                    will work diligently to provide you with alternative
                    options. We will inform you of any changes via email or SMS,
                    and our customer support team will be available to assist
                    you in rebooking or arranging suitable alternatives.{" "}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessBooking;
