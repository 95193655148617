import React from 'react'
import AppHeader from './Header'
import AppContent from './Content'
import AppFooter from './Footer'

const Layout = () => {
  return (
    <div className="page-wrapper" style={{ opacity: 1 }}>
        <AppHeader/>
        <AppContent/>
        <AppFooter/>
    </div>
  )
}

export default Layout
