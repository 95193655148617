import React, { useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";

const PrivacyNotice = () => {
  useEffect(() => {
    const anchor = document.querySelector("body");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div className="page-wrapper">
      <div className="section top-section nocss">
        <div className="wrapper">
          <div className="page-intro">
            <h1 className="page-heading normal-color">Privacy Notice</h1>
          </div>
        </div>
        <div className="bg-elements">
          <div className="bg-element-1"></div>
          <div className="bg-element-2"></div>
          <div className="bg-element-3"></div>
          <div className="bg-element-4"></div>
        </div>
      </div>
      <div className="section haze o pivacy">
        <div className="wrapper-4 iu">
          <div className="sidebar-grid">
            <div
              id="w-node-_76d928a9-b49f-4e61-7c5b-bf90cef92f10-68dd3f83"
              className="sidebar sticky"
            >
              <div className="sidebar-faq-links-2">
                <a href="#FAQ-1" className="faq-nav-link-2">
                  1. INTRODUCTION
                </a>
                <a href="#FAQ-2" className="faq-nav-link-2">
                  2. Sources of Personal Data
                </a>
                <a href="#FAQ-3" className="faq-nav-link-2">
                  3. Types of Personal Data We Collect
                </a>
                <a href="#FAQ-4" className="faq-nav-link-2">
                  <strong className="bold-text-2">
                    4. How We Use Your Personal Data
                  </strong>
                </a>
                <a href="#FAQ-5" className="faq-nav-link-2">
                  5. Cookies and Tracking Technologies
                </a>
                <a href="#FAQ-6" className="faq-nav-link-2">
                  6. External Links
                </a>
                <a href="#FAQ-7" className="faq-nav-link-2">
                  7. Cookies and Tracking Technologies
                </a>
                <a href="#FAQ-8" className="faq-nav-link-2">
                  8. Your Data Protection Rights
                </a>
                <a href="#FAQ-9" className="faq-nav-link-2">
                  9.
                  <strong className="bold-text-2">
                    Supplemental Information for the EEA, Switzerland, and the
                    UK
                  </strong>
                </a>
              </div>
            </div>
            <div id="w-node-_76d928a9-b49f-4e61-7c5b-bf90cef92f27-68dd3f83">
              <div id="FAQ-1" className="faq-block-2 first">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading-2">
                    <span className="text-color-3">1. INTRODUCTION</span>
                  </h2>
                </div>
                <div>
                  This notice (“Notice”)applies to IrFlight, Inc. and its
                  relevant affiliates (“IrFlight”,” “us,” “we,” or“our”).
                  IrFlight is a software-as-a-service company that builds and
                  supports a customer communication hub that keeps teams focused
                  on what technology can’t replace:ensuring every conversation
                  strengthens the customer relationship. <br />
                  <br />
                  IrFlight is the controller of personal data described in this
                  Notice, unless otherwisespecified. If you are located in the
                  European Economic Area, Switzerland or theUnited Kingdom,
                  please refer to Section 15 of this Notice for more
                  informationabout which specific entity or entities act as a
                  controller in relation to yourpersonal data. Where ourServices
                  are made available to you as an End User of IrFlight through a
                  IrFlight Customer, that Customer is the data controller of
                  your personal data and youshould contact that Customer with
                  questions or requests regarding your personaldata. IrFlight is
                  not responsible for our Customers’ privacy or
                  securitypractices which may be different from this Notice.As
                  used in this Notice, “personal data” means any informationthat
                  relates to, describes, or could be used to identify an
                  individual,directly or indirectly. As used in this Notice, the
                  “Websites” means IrFlight’swebsites including without
                  limitation www.IrFlight.Online, www.IrFlight.online, and any
                  successor URLS, mobile or localized versions and related
                  domains and subdomains. Capitalized terms not defined herein
                  (such as Customer, Services,and other terms) have the meaning
                  provided in our Services Agreement.
                  <br />
                  <br />‍<strong>Applicability</strong>:This Notice applies to
                  personal data that IrFlight is the controller of, whichmay
                  include: (i) data collected through the Websites, the IrFlight
                  mobileapplications, our branded social media pages, and other
                  websites which weoperate (collectively, our “Digital
                  Properties”); (ii) data collected inconnection with digital
                  communications, paper forms, in person interactionswhich may
                  include marketing and outreach activities, like surveys,
                  contests,promotions, sweepstakes, conferences, webinars, and
                  events where we post adirect link to this Notice; (iii)
                  Customer contact information; (iv) datacollected about
                  individuals who visit our offices or engage in
                  commercialtransactions with us; and (v) data collected through
                  IrFlight’s corporateactivities.This Notice does not apply to
                  the following information:· Personal data about IrFlight
                  employees and candidates, and certain contractors and agents
                  acting in similarroles.·  Personal data that IrFlight
                  processes on behalf of our Customers.
                  <br />
                  <br />‍<strong>Changes</strong>: Wemay update this Notice from
                  time-to-time to reflect changes in legal,regulatory or
                  operational requirements, our practices, and other
                  factors.Please check this Notice periodically for updates. If
                  any of the changes areunacceptable to you, you should cease
                  interacting with us. When required underapplicable law, we
                  will notify you of any changes to this Notice by posting
                  anupdate on our Website and updating the “Last Updated” date
                  at the beginning ofthis Notice or in another appropriate
                  manner. 
                </div>
              </div>
              <div id="FAQ-2" className="faq-block-2">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading-2">
                    <span className="text-color-3">
                      <strong>2. Sources of Personal Data</strong>
                    </span>
                  </h2>
                  <div>
                    IrFlight collects informationabout you and how you interact
                    with us in several ways, including:·       
                    <br />
                    <br />‍
                    <strong>Informationyou provide to us directly</strong>. We
                    collect the informationyou provide to us directly. This
                    includes instances when you register and communicatewith us
                    directly through our Digital Properties, when you visit our
                    offices,when you participate in our events, or when you
                    participate in our marketingand outreach activities
                    including surveys, contests, promotions,
                    sweepstakes,conferences, webinars, and events.·      
                    <br />
                    <br /> 
                    <strong>
                      Informationcollected from your Employer, Coworkers,
                      Family, or Friends
                    </strong>
                    . We may collect and process personal data concerning
                    representatives (e.g.,employees) of our current, past and
                    prospective customers, suppliers, investorsand business
                    partners from such companies or other employees of such
                    companies.We may also receive your name, address, phone
                    number, and company name from afriend as part of a
                    referral.·       
                    <br />
                    <br />‍
                    <strong>
                      Informationautomatically collected or inferred from your
                      interactions with us
                    </strong>
                    . Weautomatically collect technical information about your
                    interactions with ourDigital Properties (such as IP address,
                    browsing preferences, and cart andpurchase history). More
                    information is available in Section 6 below (“Cookiesand
                    Tracking Technologies”), our Website Cookie Policy.·       
                    <br />
                    <br />‍<strong>Informationfrom public sources</strong>. We
                    may collect information fromgovernment entities from which
                    public records are obtained and information yousubmit in
                    public forums, including information made publicly available
                    onsocial media networks.·       
                    <br />
                    <br />‍<strong>Informationfrom other third parties</strong>.
                    We receive informationabout you from other third parties,
                    such as third party service and contentproviders, entities
                    with whom we partner to sell or promote products
                    andservices, telephone and fax companies, authentication
                    service providers, databrokers, and social media networks
                    (including widgets related to such networks,such as the
                    “Facebook Like” button). Your interactions with social
                    mediafeatures are governed by the privacy statement of the
                    companies that providethem.We may combine information that
                    we receive from the varioussources described in this Notice,
                    including third party sources and publicsources, and use or
                    disclose it for the purposes identified below.
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              <div id="FAQ-3" className="faq-block-2">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading-2">
                    <span className="text-color-3">
                      <strong>3. Types of Personal Data We Collect</strong>
                    </span>
                  </h2>
                  <div>
                    The types of personal data that we collect include:
                    <strong>Identifiers</strong>, such as your name, alias,
                    postal address, unique personal identifier, online
                    identifiers (such as various advertising identifiers),
                    instant messaging ID, internet protocol (IP) address, email
                    address, account or user name, phone number, social media
                    identifiers (e.g., Twitter handle, Instagram name, etc.), or
                    other similar identifiers.
                    <br />
                    <br />‍<strong>Customer records</strong>, such as customer
                    contact name, email address, and credit card information.
                    For example, if you sign-up for a free trial Account, you
                    are not required to enter your credit card information
                    unless and until you decide to continue with a paid
                    subscription to our Services. As further described in
                    Section 5, we may use third-party processors to process this
                    information.
                    <br />
                    <br />‍
                    <strong>Commercial information and preferences</strong>,
                    including records of creditworthiness, products or services
                    purchased, obtained, or considered, or other purchasing or
                    consuming histories or tendencies.
                    <br />
                    <br />‍
                    <strong>
                      Internet or other electronic network activity and device
                      information
                    </strong>
                    , such as your browsing history, search history, information
                    about your device, operating system, cellular carrier,
                    location, and other information regarding your interactions
                    with our Digital Properties or products and services,
                    emails, mobile application, or advertisements. Some of this
                    information may be collected using cookies and other
                    tracking technologies as described more in Section 6 and our
                    Website Cookie Policy
                    <a href="https://front.com/cookie-policy"></a>.<br />
                    <br />‍<strong>Geolocation information</strong>, such as
                    longitude and latitude from your IP address or mobile device
                    location; city and state through a webform
                    <br />
                    <br />.
                    <strong>
                      Audio, electronic, visual, and other sensory information
                    </strong>
                    , such as video/photo or audio recordings of our premises
                    (e.g., if you attend an event or visit our offices);
                    recordings of your interactions with our sales, support,
                    technical support engineers, and other supporting teams
                    (e.g., for quality assurance, training, and analysis
                    purposes in accordance with applicable laws); or customer
                    support logs.
                    <br />
                    <br />‍
                    <strong>
                      Professional or employment-related information
                    </strong>
                    , such as your company or employer, company website, your
                    job title and role, training and certification activity,
                    company information, or business address.
                    <br />
                    <br />‍<strong>Inferences</strong>
                    drawn from any of the information we collect to create a
                    profile about you reflecting your preferences,
                    characteristics, and behavior.
                  </div>
                </div>
              </div>
              <div id="FAQ-4" className="faq-block-2">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading-2">
                    <span className="text-color-3">
                      4. How We Use Your Personal Data
                      <br />
                    </span>
                  </h2>
                  <div>
                    We may use each category of your information described above
                    in the following ways:
                    <br />
                    <br />‍
                    <strong>
                      To enable interactions and provide our Services to you
                    </strong>
                    . We may use your personal data to create, maintain,
                    customize, administer, and secure your Account; enable you
                    to access and use the Websites and the Services; to enter,
                    manage, and fulfill our contract with you or your company;
                    provide, operate, maintain, improve and promote the Websites
                    and Services; process and complete your contact and support
                    requests and send you related information, including
                    purchase confirmations and invoices; inform you of
                    additional features and other Services offered by us;
                    diagnose, repair and track service and quality issues;
                    facilitate an order, download, expiration or termination;
                    send you transactional messages, provide security alerts and
                    updates, and communicate with you about our data practices;
                    manage and promote conferences, webinars, and events
                    registrations; manage and promote surveys, contests,
                    promotions, and sweepstakes; personalize and improve our
                    Digital Properties and your Services experience; deliver
                    content information relevant to your interests; install and
                    configure changes and updates to programs and technologies
                    related to interactions with us; authenticate those who
                    interact with us; and to respond to your requests,
                    complaints, and inquiries.
                    <br />‍<br />‍<strong>To fulfill a referral request</strong>
                    . If you choose to use our referral service to tell a friend
                    about our Services, we will use the name and email address
                    that you provide us to contact your friend. You must only
                    provide your friend’s name and email address if you have a
                    reasonable belief they will not object to IrFlight
                    contacting them.
                    <br />
                    <br />‍<strong>For our own business purposes</strong>. We
                    may use your personal data to evaluate or audit the usage
                    and performance of programs and technologies related to
                    interactions with us; evaluate and improve the quality of
                    your interactions with us, our Services and Websites, and
                    programs and technologies related to interactions with us;
                    design new services; process and catalog your responses to
                    surveys or questionnaires (e.g., customer satisfaction
                    reviews); record phone calls and/or video meetings for
                    quality assurance, training and analysis purposes in
                    accordance with applicable laws (including obtaining consent
                    or an opportunity to object if required by law); perform
                    internal research for development and demonstration; conduct
                    analysis and testing; credit and payment collection,
                    accounting and other similar business functions; and
                    maintain proper business records and other relevant records.
                    <br />
                    <br />‍
                    <strong>For legal, safety, or security reasons</strong>. We
                    may use your personal data to comply with legal requirements
                    such as export controls and know-your-customer; establish,
                    exercise or defend against legal claims that we may have
                    against you or pursue together with you, whether in court
                    proceedings or in an administrative or out-of-court
                    procedure; protect the safety, security, and integrity of
                    our property (such as our Digital Properties, Services,
                    databases and other technology assets) and rights of those
                    who interact with us or others; review compliance with
                    applicable terms of use, investigate fraudulent
                    transactions, unauthorized access to our Digital Properties
                    and Services, content and conduct policy violations, and
                    illegal activities (in compliance with legal obligations
                    under applicable laws); and otherwise detect, prevent, and
                    respond to security incidents or other malicious, deceptive,
                    fraudulent, or illegal activity. These safety purposes may
                    also involve collecting and processing special categories of
                    personal data (i.e., health data), for office visits and
                    events where necessary for reasons of public health interest
                    or required by applicable law.
                    <br />
                    <br />‍<strong>For marketing</strong>. We may use your
                    personal data to market our products or Services or those of
                    third parties, such as our business partners. This may
                    include sending you marketing communications, product
                    recommendations, and other non-transactional communications
                    (via email, phone, or other online and offline
                    communications) about our Services or those of third
                    parties, promotions, news, and events. We may use your
                    personal data when you participate in marketing surveys,
                    questionnaires, promotions, testimonials, online comments,
                    product feedback, events, sweepstakes or other contests. We
                    may audit aspects of our ad impressions. We may use your
                    personal data for contextual ad customization. You can
                    opt-out of marketing communications by clicking the
                    unsubscribe link at the bottom of our marketing
                    communications. If there is no unsubscribe link, you may
                    reply to the communication directly stating that you no
                    longer wish to receive marketing communications. For further
                    information, please see Section 6 below (“Cookies and
                    Tracking Technologies”) and our Website Cookie Policy
                    <a href="https://front.com/cookie-policy"></a>.<br />
                    <br />‍<strong>Corporate transactions</strong>. We may use
                    your personal data in connection with corporate
                    transactions, sales, mergers, acquisitions, reorganizations,
                    bankruptcy, and other corporate events. For example, we may
                    use your personal data to comply with requests of a
                    prospective or an actual purchaser interested in our
                    companies and other assets, or in relation to a prospective
                    or actual purchase of companies or assets by us.
                    <br />
                    <br />‍<strong>Consent</strong>. We may use your personal
                    data for any other purposes for which you provide consent.
                    <br />
                    <br />‍
                    <strong>
                      In a de-identified, anonymized, or aggregated format
                    </strong>
                    . Depending on the applicable law, when converted to a
                    de-identified, anonymized, or aggregated format, data may no
                    longer constitute personal data and we may use this
                    information for any purpose not prohibited by law.EEA
                    individuals: Our legal basis for collecting and using the
                    personal data described above will depend on the personal
                    data concerned and the specific context. We will collect
                    personal data from you where we need the personal data in
                    performance of a services agreement with you (to provide
                    Services to you), where the processing is in our legitimate
                    interests and not overridden by your data protection
                    interests or fundamental rights and freedoms, or where we
                    have your consent (which can be withdrawn at any time). In
                    some cases, we may also have a legal obligation to collect
                    personal data from you. For more information, please see
                    Section 15 (“Supplemental Information for the EEA,
                    Switzerland, and the UK”).
                    <br />
                  </div>
                </div>
              </div>
              <div id="FAQ-5" className="faq-block-2">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading-2">
                    <span className="text-color-3">
                      <strong>5. Cookies and Tracking Technologies</strong>
                      <br />
                    </span>
                  </h2>
                  <div>
                    Cookies, Pixels, and Similar Tracking Technologies Used: We
                    and our authorized partners also collect information via
                    cookies, web beacons, pixels, tags, embedded scripts,
                    session replay tools, SDKs, Local Storage such as HTML5 and
                    Local Shared Objects (“LSOs,” also known as Flash cookies),
                    or other data retrieval and tracking technologies (“Tracking
                    Technologies”), such as your Internet Service Provider and
                    IP address, device identifier, browser type, operating
                    system, your device’s WiFi MAC address or Bluetooth MAC
                    address, the date and time you access our Digital
                    Properties, the pages you accessed while visiting our
                    Digital Properties, and the Internet address from which you
                    accessed our Digital Properties. Cookies are a type of
                    technology that installs a small amount of information on a
                    user’s computer or other device when they visit a website.
                    Cookies permit a website to, for example, recognize future
                    visits using that computer or device. Some cookies exist
                    only during a single session and some are persistent over
                    multiple sessions over time. We and our partners use these
                    Tracking Technologies to ensure basic functionality of our
                    Digital Properties; to remember user preferences (including
                    your preferences regarding Tracking Technologies); maximize
                    the performance of our Digital Properties and Services;
                    provide you with offers that may be of interest to you;
                    measure the effectiveness of our Digital Properties,
                    marketing campaigns, and email communications; and to
                    personalize online content. These Tracking Technologies may
                    be used to track you over time and across devices, websites,
                    and Services.Additionally, we may employ, either directly or
                    through third parties, tracking pixels. Tracking pixels are
                    tiny, transparent graphics with a unique identifier, similar
                    in function to cookies, and are used to provide analytical
                    information concerning the user experience as well as to
                    support custom marketing activities for users of our Digital
                    Properties. In contrast to cookies, which are stored on a
                    user’s computer hard drive, tracking pixels are embedded
                    invisibly on web pages. Our Digital Properties may use
                    tracking pixels to help us better manage content, such as by
                    improving the user interface or improving our marketing
                    programs or the marketing of our affiliates, business
                    partners, and other third parties (including for
                    interest-based advertising as described below). The Digital
                    Properties may use information to create aggregate tracking
                    information reports regarding user demographics, traffic
                    patterns and purchases. We may also link tracking
                    information with personal data.Third-Party Companies: To
                    provide you with a more relevant and interesting experience,
                    we may work with third party companies to display ads or
                    customize the content on our Digital Properties or through
                    other communication channels. These companies may use
                    Tracking Technologies as described in this Notice to gather
                    information about you, which may include your precise
                    location, your visits to the Digital Properties, and your
                    visits elsewhere on the Internet. These companies also may
                    use this information to provide you with more relevant
                    advertising known as interest-based advertising, which may
                    include sending you an ad on another online service after
                    you have left our Digital Properties (i.e.,
                    retargeting).Your Choices: You may choose whether to receive
                    some interest-based advertising. If located in the European
                    Economic Area, Switzerland or the United Kingdom, then
                    essential cookies must always be used in order to provide
                    the Services you have requested, However, you will be
                    provided notice of our use of and subsequent processing of
                    data collected through non-essential cookies (i.e.
                    functional cookies, analytics cookies, targeting cookies and
                    social media cookies) at the time when you first visit the
                    IrFlight website. If located in the European Economic Area,
                    you may also more generally opt in to the use of cookies
                    <a href="http://www.youronlinechoices.eu/"></a>. The Digital
                    Advertising Alliance (“DAA”) and Network Advertising
                    Initiative (“NAI”) also provide mechanisms for you to opt
                    out of interest-based advertising performed by participating
                    members at
                    <a href="http://www.aboutads.info/choices/">
                      http://www.aboutads.info/choices/
                    </a>
                    and
                    <a href="http://optout.networkadvertising.org/">
                      optout.networkadvertising.org
                    </a>
                    . You can also visit
                    <a href="http://www.aboutads.info/appchoices">
                      http://www.aboutads.info/appchoices
                    </a>
                    for information on the DAA’s opt-out program specifically
                    for mobile apps (including use of precise location for third
                    party ads). Opting out of interest-based advertising will
                    not opt you out of all advertising, but rather only
                    interest-based advertising from us or our agents or
                    representatives. Further, cookie-based opt-outs must be
                    performed on each device and browser that you wish to have
                    opted-out. For example, if you have opted out on your
                    computer browser, that opt-out will not necessarily be
                    effective on your mobile device. DAA/NAI browser based
                    opt-outs will not function or may no longer be effective if
                    your browsers are configured to reject cookies, if you
                    subsequently erase your cookies, or if you use a different
                    device or web browser. We are not responsible for the
                    effectiveness of these or any other third-parties’ opt-out
                    options or programs.Do Not Track: Some browsers have
                    incorporated Do Not Track (“DNT”) preferences. Most of these
                    features, when turned on, send signals to the website you
                    are visiting that you do not wish to have information about
                    your online searching and browsing activities collected and
                    used. As there is not yet a common agreement about how to
                    interpret DNT signals, we do not honor DNT signals from
                    website browsers at this time. However, you may refuse or
                    delete cookies. If you refuse or delete cookies, some of our
                    website functionality may be impaired or some of the
                    advertising served to you may not be relevant for you
                    anymore. If you change computers, devices, or browsers, or
                    use multiple computers, devices, or browsers, and delete
                    your cookies, you may need to repeat this process for each
                    computer, device, or browser. Please refer to your browser’s
                    Help instructions to learn more about how to manage cookies
                    and the use of other Tracking Technologies
                    <a href="https://www.allaboutcookies.org/manage-cookies/"></a>
                    .<br />
                  </div>
                </div>
              </div>
              <div id="FAQ-6" className="faq-block-2">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading-2">
                    <span className="text-color-3">
                      <strong>6. External Links</strong>
                      <br />
                    </span>
                  </h2>
                  <div>
                    When interacting with us you may encounter links to external
                    sites or other online services, including those embedded in
                    third party advertisements. We do not control and are not
                    responsible for the privacy and data collection policies for
                    such third party sites and services. You should consult such
                    third parties and their respective privacy notices for more
                    information or if you have any questions about their
                    practices.
                  </div>
                </div>
              </div>
              <div id="FAQ-7" className="faq-block-2">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading-2">
                    <span className="text-color-3">
                      <strong>7. Cookies and Tracking Technologies</strong>
                      <br />
                    </span>
                  </h2>
                  <div>
                    Cookies, Pixels, and Similar Tracking Technologies Used: We
                    and our authorized partners also collect information via
                    cookies, web beacons, pixels, tags, embedded scripts,
                    session replay tools, SDKs, Local Storage such as HTML5 and
                    Local Shared Objects (“LSOs,” also known as Flash cookies),
                    or other data retrieval and tracking technologies (“Tracking
                    Technologies”), such as your Internet Service Provider and
                    IP address, device identifier, browser type, operating
                    system, your device’s WiFi MAC address or Bluetooth MAC
                    address, the date and time you access our Digital
                    Properties, the pages you accessed while visiting our
                    Digital Properties, and the Internet address from which you
                    accessed our Digital Properties. Cookies are a type of
                    technology that installs a small amount of information on a
                    user’s computer or other device when they visit a website.
                    Cookies permit a website to, for example, recognize future
                    visits using that computer or device. Some cookies exist
                    only during a single session and some are persistent over
                    multiple sessions over time. We and our partners use these
                    Tracking Technologies to ensure basic functionality of our
                    Digital Properties; to remember user preferences (including
                    your preferences regarding Tracking Technologies); maximize
                    the performance of our Digital Properties and Services;
                    provide you with offers that may be of interest to you;
                    measure the effectiveness of our Digital Properties,
                    marketing campaigns, and email communications; and to
                    personalize online content. These Tracking Technologies may
                    be used to track you over time and across devices, websites,
                    and Services.Additionally, we may employ, either directly or
                    through third parties, tracking pixels. Tracking pixels are
                    tiny, transparent graphics with a unique identifier, similar
                    in function to cookies, and are used to provide analytical
                    information concerning the user experience as well as to
                    support custom marketing activities for users of our Digital
                    Properties. In contrast to cookies, which are stored on a
                    user’s computer hard drive, tracking pixels are embedded
                    invisibly on web pages. Our Digital Properties may use
                    tracking pixels to help us better manage content, such as by
                    improving the user interface or improving our marketing
                    programs or the marketing of our affiliates, business
                    partners, and other third parties (including for
                    interest-based advertising as described below). The Digital
                    Properties may use information to create aggregate tracking
                    information reports regarding user demographics, traffic
                    patterns and purchases. We may also link tracking
                    information with personal data.Third-Party Companies: To
                    provide you with a more relevant and interesting experience,
                    we may work with third party companies to display ads or
                    customize the content on our Digital Properties or through
                    other communication channels. These companies may use
                    Tracking Technologies as described in this Notice to gather
                    information about you, which may include your precise
                    location, your visits to the Digital Properties, and your
                    visits elsewhere on the Internet. These companies also may
                    use this information to provide you with more relevant
                    advertising known as interest-based advertising, which may
                    include sending you an ad on another online service after
                    you have left our Digital Properties (i.e.,
                    retargeting).Your Choices: You may choose whether to receive
                    some interest-based advertising. If located in the European
                    Economic Area, Switzerland or the United Kingdom, then
                    essential cookies must always be used in order to provide
                    the Services you have requested, However, you will be
                    provided notice of our use of and subsequent processing of
                    data collected through non-essential cookies (i.e.
                    functional cookies, analytics cookies, targeting cookies and
                    social media cookies) at the time when you first visit the
                    IrFlight website. If located in the European Economic Area,
                    you may also more generally opt in to the use of cookies, by
                    clicking here. The Digital Advertising Alliance (“DAA”) and
                    Network Advertising Initiative (“NAI”) also provide
                    mechanisms for you to opt out of interest-based advertising
                    performed by participating members at
                    http://www.aboutads.info/choices/ and
                    optout.networkadvertising.org. You can also visit
                    http://www.aboutads.info/appchoices for information on the
                    DAA’s opt-out program specifically for mobile apps
                    (including use of precise location for third party ads).
                    Opting out of interest-based advertising will not opt you
                    out of all advertising, but rather only interest-based
                    advertising from us or our agents or representatives.
                    Further, cookie-based opt-outs must be performed on each
                    device and browser that you wish to have opted-out. For
                    example, if you have opted out on your computer browser,
                    that opt-out will not necessarily be effective on your
                    mobile device. DAA/NAI browser based opt-outs will not
                    function or may no longer be effective if your browsers are
                    configured to reject cookies, if you subsequently erase your
                    cookies, or if you use a different device or web browser. We
                    are not responsible for the effectiveness of these or any
                    other third-parties’ opt-out options or programs.Do Not
                    Track: Some browsers have incorporated Do Not Track (“DNT”)
                    preferences. Most of these features, when turned on, send
                    signals to the website you are visiting that you do not wish
                    to have information about your online searching and browsing
                    activities collected and used. As there is not yet a common
                    agreement about how to interpret DNT signals, we do not
                    honor DNT signals from website browsers at this time.
                    However, you may refuse or delete cookies. If you refuse or
                    delete cookies, some of our website functionality may be
                    impaired or some of the advertising served to you may not be
                    relevant for you anymore. If you change computers, devices,
                    or browsers, or use multiple computers, devices, or
                    browsers, and delete your cookies, you may need to repeat
                    this process for each computer, device, or browser. Please
                    refer to your browser’s Help instructions to learn more
                    about how to manage cookies and the use of other Tracking
                    Technologies. You can also find more information on managing
                    cookies at All About Cookies – Manage Cookies.For more
                    information about the use of cookies on our Websites and how
                    to manage your cookie settings, please read our Website
                    Cookie Policy found here.Section 6 does not apply to
                    in-product cookies used within our Services. Our Product
                    Cookie Policy can be found here.
                  </div>
                </div>
              </div>
              <div id="FAQ-8" className="faq-block-2">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading-2">
                    <span className="text-color-3">
                      <strong>8. Your Data Protection Rights</strong>
                      <br />
                    </span>
                  </h2>
                  <div>
                    Laws in certain jurisdictions may provide individuals with
                    rights relating to personal data, such as those listed
                    below. We will honor these rights to the extent required by
                    law.<strong>Access</strong>. You may have the right to
                    obtain confirmation from us if personal data is being
                    processed, and related information; and the right to obtain
                    a copy of your personal data undergoing the processing.
                    <br />
                    <br />‍<strong>Rectification</strong>. You may have the
                    right to request the rectification of inaccurate personal
                    data and to have incomplete data completed.
                    <br />
                    <br />‍<strong>Objection</strong>. You may have the right to
                    object to the processing of your personal data for
                    compelling and legitimate reasons relating to your
                    particular situation, except in cases where legal provisions
                    expressly provide for that processing. You also have the
                    right to object / opt-out to the processing of your personal
                    data for direct marketing purposes by clicking the
                    unsubscribe link at the bottom of the email marketing
                    communication received or by emailing us at
                    privacy@irflight.online.
                    <br />
                    <br />‍<strong>Portability</strong>. You may have the right
                    to receive your personal data that you have provided to us,
                    in a structured, commonly used and machine-readable format
                    and have the right to transmit it to other data controllers
                    without hindrance. This right only exists if the processing
                    is based on your consent or a contract and the processing is
                    carried out by automated means.
                    <br />
                    <br />‍<strong>Restriction</strong>. You may have the right
                    to request to restrict the processing of your personal data
                    in certain cases.
                    <br />
                    <br />‍<strong>Erasure</strong>. You may request to erase
                    your personal data if (i) it is no longer necessary for the
                    purposes for which we have collected it, (ii) you have
                    withdrawn your consent and no other legal ground for the
                    processing exists, (iii) you objected and no overriding
                    legitimate grounds for the processing exist, (iv) the
                    processing is unlawful, or erasure is required to comply
                    with a legal obligation.
                    <br />
                    <br />‍<strong>Right to lodge a complaint</strong>. You also
                    may have the right to lodge a complaint with a supervisory
                    authority in the country where you reside. The contact
                    details for data supervisory authorities in the EEA,
                    Switzerland, and the UK.
                    <br />
                    <br />‍
                    <a href="http://ec.europa.eu/newsroom/article29/document.cfm?action=display&amp;doc_id=50061"></a>
                    <strong>Right to refuse or withdraw consent</strong>. In
                    case we ask for your consent to processing, you are free to
                    refuse to give consent and you can withdraw your consent at
                    any time without any adverse negative consequences by
                    contacting us using the contact information provided above.
                    The lawfulness of any processing of your personal data that
                    occurred prior to the withdrawal of your consent will not be
                    affected.
                    <br />
                    <br />‍<strong>Automated decision-making</strong>. We hereby
                    inform you that the types of automated decision-making
                    referred to in Article 22(1) and (4) GDPR do not take place
                    on our Websites or in our Services. Should this change, we
                    will inform you about it and the fact that you have the
                    right not to be subject to those types of decisions based
                    solely on automated processing and to be given more
                    information about why any such decision was made.In order to
                    exercise your rights (or other rights that may be available
                    to you under your local data protection laws), please
                    contact us by emailing us at privacy@IrFlight.online. We try
                    to respond to all legitimate requests within one (1) month
                    of receipt of the request or as otherwise required under
                    applicable law. If the response will take us longer, we will
                    notify you. If we have reasonable doubts concerning your
                    identity, we may request you to provide us with additional
                    information to verify your identity.
                  </div>
                </div>
              </div>
              <div id="FAQ-9" className="faq-block-2">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading-2">
                    <span className="text-color-3">
                      <strong>
                        9. Supplemental Information for the EEA, Switzerland,
                        and the UK
                      </strong>
                      <br />
                    </span>
                  </h2>
                  <div>
                    The following terms supplement the Notice with respect to
                    our processing of European Economic Area (i.e., European
                    Union Member States, Iceland, Liechtenstein and Norway),
                    Swiss, and UK personal data. To the extent applicable, in
                    the event of any conflict or inconsistency between the other
                    parts of the Notice and the terms of this Section 15,
                    Section 15 shall govern and prevail with regard to the
                    processing of EEA, Swiss and UK personal data.
                    <br />
                    <br />‍<strong>Data Controller</strong>: The IrFlight entity
                    with which you have a primary relationship with (such as the
                    entity that concluded sales/services/supply contract with
                    you; the entity that has provided you with marketing and
                    promotional materials and communications; the primary entity
                    in the region where you access our Website) is the
                    controller of personal data collected from individuals
                    within the scope of this Notice. In the majority of cases,
                    this will be IrFlight, Inc., unless we specifically inform
                    you otherwise. On some occasions, more than one IrFlight
                    entity may process your personal data as independent
                    controllers. If you have any questions about controllership,
                    please contact us (see Section 12 for contact information).
                    <br />
                    <br />‍<strong>Legal bases for processing</strong>: We rely
                    on the following legal grounds for the collection,
                    processing, and use of your personal data:
                    <br />
                    <br />‍
                    <strong>
                      The processing is necessary for the performance of a
                      contract to which you are a party or to take steps at your
                      request prior to entering into a contract
                    </strong>
                    . This includes instances when we need to enable
                    interactions between you and us and to provide our services
                    to you; when we need to facilitate our business relationship
                    with you or companies acting as our investors, suppliers and
                    other business partners; and when we conclude and fulfill
                    our part of the contract with our customers.
                    <br />
                    <br />‍
                    <strong>
                      The processing is necessary for compliance with a legal or
                      statutory obligation to which we are subject
                    </strong>
                    . This includes instances when we are required by various
                    business laws to carry out various compliance checks (such
                    as export controls) related to our customers, investors,
                    suppliers, and other business partners. It may also include
                    various local tax and accountancy compliance obligations we
                    have to comply with due to the operation of our business.
                    <br />
                    <br />‍
                    <strong>
                      The processing is necessary for the purposes of the
                      legitimate interests pursued by us or by a third party
                    </strong>
                    . This includes instances where we process your personal
                    data for our own internal business-improvement purposes,
                    certain survey and questionnaires we may carry out, and our
                    marketing activities (for example by sending you digital
                    direct marketing related to similar products/services we
                    have provided to you), unless consent is required under
                    applicable laws. We may also provide some of the auxiliary
                    support to our services based on our legitimate business
                    interest to do so, even though we are not required to do so
                    under our contracts, including through various digital
                    communication and other tools we provide in the course of
                    our business relationship with you.
                    <br />
                    <br />‍
                    <strong>
                      Where you provided us with your consent to the processing
                      of your data for one or more specific purposes
                    </strong>
                    . This includes digital direct marketing communications
                    where your consent is required by law or in other instances
                    where we asked for your consent in order to collect and
                    process your personal data (we will inform you at each such
                    occasion).
                    <br />
                    <br />‍
                    <strong>
                      The processing is necessary for reasons of public interest
                      in the area of public health
                    </strong>
                    . This may include our legitimate interests and legal
                    obligations in the collection and processing of health data
                    from office visitors or event attendees in the context of a
                    pandemic or related health threatening scenarios in order to
                    protect individuals against serious cross-border threats to
                    health or ensuring high standards of quality and safety of
                    health care;
                    <br />
                    <br />‍
                    <strong>
                      The processing is necessary for our legitimate interests
                      in the establishment, exercise or defense of legal claims
                    </strong>
                    , whether in court proceedings or in an administrative or
                    out-of-court procedure.
                    <br />
                    <br />‍
                    <strong>International Transfers of Personal Data</strong>:
                    Due to the global nature of our operations, some of the
                    recipients mentioned in Section 5 of the Notice may be
                    located in countries outside the EEA, Switzerland, or the
                    UK, which do not provide an adequate level of data
                    protection as defined by data protection laws in the EEA,
                    Switzerland and the UK. Certain third countries have been
                    officially recognized by the EEA, Swiss and UK authorities
                    as providing an adequate level of protection and no further
                    safeguards are necessary. The below outlines how we protect
                    your personal data when transferring it outside those
                    countries.
                    <br />
                    <br />‍<strong>Intra-group</strong>: Intra-group
                    international transfers will be to countries where IrFlight
                    entities are located, in particular the United States of
                    America. The transfer of your personal data outside the EEA,
                    Switzerland and the UK to our group companies located in
                    third countries which do not offer an adequate level of
                    protection in comparison with the EEA, Swiss or UK privacy
                    standards will be based on the following safeguards:The UK
                    Standard Contractual Clauses, as applicable. We may also
                    utilize addendums and other data transfer agreements
                    specific to certain countries.
                    <br />
                    <br />‍<strong>Third parties</strong>: Some of the third
                    parties with whom we share personal data are also located
                    outside the EEA, Switzerland or the UK in third countries,
                    which do not provide an adequate level of data protection as
                    defined by data protection laws in the EEA, Switzerland or
                    the UK. Transfers to third parties located in such third
                    countries take place using an acceptable data transfer
                    mechanism, such as the EU/UK Standard Contractual Clauses,
                    approved Codes of Conduct and Certifications, on the basis
                    of permissible statutory derogations, or any other valid
                    data transfer mechanism issued by the EEA, Swiss or UK
                    authorities. Please reach out to us using the Contact Info
                    above, if you want to receive further information or, where
                    available, a copy of the relevant data transfer
                    mechanism.IrFlight, Inc., abides by and has certified
                    adherence to the principles of the EU-U.S. and the
                    Swiss-U.S. Privacy Shield frameworks as set forth by the
                    U.S. Department of Commerce; however, we do NOT rely on the
                    Privacy Shield as a lawful mechanism to transfer personal
                    data from the EU, UK, or Switzerland. For more information
                    on the Privacy Shield frameworks, and to view the scope of
                    IrFlight’s certification, please visit
                    <a href="https://www.privacyshield.gov/list">
                      https://www.privacyshield.gov/list
                    </a>
                    .<br />
                    <br />
                    <strong>United Kingdom</strong>: Personal data collected,
                    stored, used and/or processed by the IrFlight, as described
                    in this Privacy Notice, is collected, stored, used and/or
                    processed in accordance with the IrFlight obligations under
                    the UK Data Protection Act 2018, as amended by the Data
                    Protection, Privacy and Electronic Communications
                    (Amendments etc.) (EU Exit) Regulations 2019, as amended,
                    superseded or replaced (“UK GDPR”).
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section no-padding-top dd">
        <div className="wrapper">
          <div className="main-grid">
            <div
              id="w-node-f2cdf1b3-065d-102d-8a60-5c6b346190d4-68dd3f83"
              data-w-id="f2cdf1b3-065d-102d-8a60-5c6b346190d4"
              style={{ opacity: 0 }}
              className="intro left"
            >
              <div className="text-label">Our Mission</div>
              <h2>We create the best Webflow templates for creatives</h2>
              <p className="text-big text-grey">
                How you write your advertising copy will be based on where you
                will place your ad. If it’s a billboard ad, you’ll need a super
                catchy headline and simple design.
              </p>
              <div className="person">
                <img
                  src="images/avatar-10.jpg"
                  alt=""
                  className="person-avatar"
                />
                <div className="person-info">
                  <h6>Sebastian Evans</h6>
                  <div className="person-text">founder of IndieGo</div>
                </div>
              </div>
            </div>
            <div
              id="w-node-f2cdf1b3-065d-102d-8a60-5c6b346190e2-68dd3f83"
              className="w-layout-grid mini-gallery"
            >
              <a
                href="#"
                id="w-node-f2cdf1b3-065d-102d-8a60-5c6b346190e3-68dd3f83"
                className="gallery-photo _1 w-inline-block w-lightbox"
              >
                <div className="gallery-hover">
                  <div className="gallery-zoom-icon"></div>
                  <div>Zoom In</div>
                </div>
              </a>
              <a
                href="#"
                id="w-node-f2cdf1b3-065d-102d-8a60-5c6b346190e8-68dd3f83"
                className="gallery-photo _2 w-inline-block w-lightbox"
              >
                <div className="gallery-hover">
                  <div className="gallery-zoom-icon"></div>
                  <div>Zoom In</div>
                </div>
              </a>
              <a
                href="#"
                id="w-node-f2cdf1b3-065d-102d-8a60-5c6b346190ed-68dd3f83"
                className="gallery-photo _3 w-inline-block w-lightbox"
              >
                <div className="gallery-hover">
                  <div className="gallery-zoom-icon"></div>
                  <div>Zoom In</div>
                </div>
              </a>
              <a
                href="#"
                id="w-node-f2cdf1b3-065d-102d-8a60-5c6b346190f2-68dd3f83"
                className="gallery-photo _4 w-inline-block w-lightbox"
              >
                <div className="gallery-hover">
                  <div className="gallery-zoom-icon"></div>
                  <div>Zoom In</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section with-no oferf">
        <div className="wrapper-2">
          <div className="faq-box tr">
            <div className="faq-box-info">
              <div className="text-label-2">Answers to</div>
              <h2 className="hand">Frequently Asked Questions</h2>
              <p className="text-grey-2">
                If you couldn&#x27;t find the answer here please visit our
                complete FAQs page
              </p>
              <a href="/faq" className="button-2 w-button">
                Questions &amp; Answers
              </a>
              <a href="/contact" className="button-2 with w-button">
                Contact Us
              </a>
            </div>
            <div>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    What payment methods do you accept?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    We accept various payment methods, including major credit
                    cards such as Visa, Mastercard, and American Express. We
                    also support payment options like banking transfers. Rest
                    assured that our payment gateway is secure and your personal
                    information is protected.
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    Can I make changes to my booking after it&#x27;s confirmed?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    Yes, we understand that plans may change. Depending on the
                    fare rules and availability, you may be able to make changes
                    to your booking. However, please note that certain changes
                    may incur additional fees or charges. We recommend
                    contacting our customer support team as soon as possible to
                    inquire about any desired changes to your booking.
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    What is the baggage allowance for Iran Air flights?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    The baggage allowance for Iran Air flights may vary
                    depending on your ticket type and destination. Generally,
                    passengers are allowed to bring a certain number of checked
                    baggage and carry-on baggage within specified weight and
                    size limits. We recommend checking the baggage policy on our
                    website or contacting our customer support team for detailed
                    information regarding your specific flight.
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    What if my flight gets delayed or canceled?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    A: In the event of a flight delay or cancellation, our team
                    will work diligently to provide you with alternative
                    options. We will inform you of any changes via email or SMS,
                    and our customer support team will be available to assist
                    you in rebooking or arranging suitable alternatives.{" "}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyNotice;
