import Home from '../views/Home'
import Search from '../views/Search'
import PassengerInformation from '../views/PassengerInfomation'
import ExtraBaggage from '../views/ExtraBaggage'
import ConfirmPayment from '../views/ConfirmPayment'
import Faq from '../views/Faq'
import Contact from '../views/Contact'
import ContactConfirmation from '../views/ContactConfirmation'
import Services from '../views/Services'
import PrivacyNotice from '../views/PrivacyNotice'
import BusinessBooking from '../views/BusinessBooking'

const routes = [{
    path: '/',
    exact: true,
    name: 'Home',
    element: Home
}, {
    path: '/search',
    exact: true,
    name: 'Search',
    element: Search
}, {
    path: '/passenger-information',
    exact: true,
    name: 'PassengerInformation',
    element: PassengerInformation
}, {
    path: '/extra-baggage',
    exact: true,
    name: 'ExtraBaggage',
    element: ExtraBaggage
}, {
    path: '/confirm-payment',
    exact: true,
    name: 'ConfirmPayment',
    element: ConfirmPayment
}, {
    path: '/faq',
    exact: true,
    name: 'Faq',
    element: Faq
}, {
    path: '/contact',
    exact: true,
    name: 'Contact',
    element: Contact
}, {
    path: '/contact-confirmation',
    exact: true,
    name: 'ContactConfirmation',
    element: ContactConfirmation
}, {
    path: '/services',
    exact: true,
    name: 'Services',
    element: Services
}, {
    path: 'privacy-notice',
    exact: true,
    name: 'PrivacyNotice',
    element: PrivacyNotice
}, {
    path: 'business-booking',
    exact: true,
    name: 'BusinessBooking',
    element: BusinessBooking
}]

export default routes
