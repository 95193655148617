import axios from 'axios';


const Http = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    timeout: 150000
});

Http.interceptors.request.use(function(config) {
    return config;
}, err => {
    return Promise.reject(err)
});

Http.interceptors.response.use(function(response) {
    return response;
}, err => {
    console.log(err.response)
    if (err.response.status === 419) {
        localStorage.removeItem('cookie')
        window.location.reload('/')
        return false;
    } else {
        return Promise.reject(err)
    }
})
export default Http;