import { useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Box, Grid, Typography } from '@mui/material'
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { toast } from "react-hot-toast";

const CheckoutForm = ({ pnr, amount, currency, passengers }) => {
    const stripe = useStripe()
    const elements = useElements()
    const [isLoading, setIsLoading] = useState(false)

    const getCurrencyCode = () => {
        switch(currency) {
            case "GBP":
                return "£"
            case "EURO":
                return "€"
            case "USD":
                return "$"
        }
    }

    const checkout = async ev => {
        ev.preventDefault()
        if (!stripe || !elements) return
        setIsLoading(true)

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${process.env.REACT_APP_URL}/confirm-payment?pnr=${pnr}`
            }
        })

        if (error.type === 'card_error' || error.type === 'validation_error') {
            toast.error(error.message)
        }

        setIsLoading(false)
    }

    return (
        <Box component='form' id='payment-form' onSubmit={checkout}>
            <Grid container spacing={5}>
                <Grid item md={6}>

                    <PaymentElement id='payment-element'/>
                    <LoadingButton
                        disabled={isLoading || !stripe || !elements}
                        style={{ width: '100%', padding: 15, marginTop: 30 }}
                        size='large'
                        variant='contained'
                        color='primary'
                        loading={isLoading}
                        loadingPosition='start'
                        startIcon={<Icon icon='mui:payment'/>}
                        type='submit'
                    >Pay Now</LoadingButton>
                </Grid>
                <Grid item md={6}>
                    <Box sx={{ height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='h4'>Booking fee</Typography>
                        <Typography variant='h6' sx={{ textAlign: 'left' }}>Number of Passengers: {passengers}</Typography>
                        <Box sx={{ minHeight: 200, background: '#f7f7f7', width: '90%', margin: '30px auto', borderRadius: '17px', display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='h2' sx={{ textAlign: 'center', mt: 5, mb: 5, fontSize: '50px !important' }}>{getCurrencyCode()}{amount}</Typography>
                            <Typography variant='h6' sx={{ position: 'absolute', top: 20, left: 20 }}>Total</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CheckoutForm