import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import routes from '../router'

const Content = () => {
    return (
        <Suspense>
            <Routes>
                {routes.map((route, idx) => (
                    <Route key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        element={<route.element/>}
                    />  
                ))}
            </Routes>
        </Suspense>
    )
}

export default Content