import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Grid, Box, Typography, Button } from "@mui/material";
import { Icon } from "@iconify/react";
import DepartureFlightList from "../../components/Search/DepartureFlightList";
import ReturnFlightList from "../../components/Search/ReturnFlightList";
import OrderDetailBox from "../../components/Search/OrderDetailBox";
import moment from "moment";

const Search = () => {
  const navigate = useNavigate();
  const agentId = useSelector((state) => state.flight.agentId);
  const searchType = useSelector((state) => state.flight.searchType);
  const currency = useSelector((state) => state.flight.currency);
  const margins = useSelector((state) => state.flight.margins);
  const rate = useSelector((state) => state.flight.rate);
  const adults = useSelector((state) => state.flight.adults);
  const childs = useSelector((state) => state.flight.childs);
  const infants = useSelector((state) => state.flight.infants);
  const seniorCitizens = useSelector((state) => state.flight.seniorCitizens);
  const youths = useSelector((state) => state.flight.youths);
  const departDate = useSelector((state) => state.flight.departDate);
  const returnDate = useSelector((state) => state.flight.returnDate);
  const activeDepartFlight = useSelector(
    (state) => state.flight.activeDepartFlight
  );
  const activeReturnFlight = useSelector(
    (state) => state.flight.activeReturnFlight
  );
  const priceQuote = useSelector((state) => state.flight.priceQuote);
  const journeys = useSelector((state) => state.flight.journeys);
  const availableFltSegments = useSelector(
    (state) => state.flight.availableFltSegments
  );

  const [departFlights, setDepartFlights] = useState([]);
  const [returnFlights, setReturnFlights] = useState([]);
  const [departDates, setDepartDates] = useState([]);
  const [returnDates, setReturnDates] = useState([]);

  useEffect(() => {
    const anchor = document.querySelector("body");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
    if (agentId === 0) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    let _departFlights = [],
      _returnFlights = [];
    for (let flight of availableFltSegments) {
      const departJourney = journeys.find(
        journey => journey.onDInfoDTOs[0].flightSegmentUId == flight.fltSegID && journey.seatAvailability == true
      )
      if (departJourney) {
        const availableRBD = departJourney.onDInfoDTOs[0].availableRBD[flight.fltSegID]
        const sortedAvailableRBD = [...availableRBD].sort((a, b) => b.nestRank - a.nestRank)
        _departFlights.push({ ...flight, rbd: sortedAvailableRBD })
      }

      if (searchType === 'RT') {
        const returnJourney = journeys.find(journey => journey.onDInfoDTOs[1].flightSegmentUId == flight.fltSegID && journey.seatAvailability == true);
        if (returnJourney) {
          const availableRBD = returnJourney.onDInfoDTOs[1].availableRBD[flight.fltSegID]
          const sortedAvailableRBD = [...availableRBD].sort((a, b) => b.nestRank - a.nestRank)
          _returnFlights.push({ ...flight, rbd: sortedAvailableRBD })
        }
      }
    }

    setDepartFlights(_departFlights);
    setReturnFlights(_returnFlights);
  }, [availableFltSegments, journeys]);

  useEffect(() => {
    let departDates = [],
      returnDates = [];
    for (let i = -3; i < 4; i++) {
      departDates.push(moment(departDate).add(i, "d").format("DD/MM/YYYY"));
      returnDates.push(moment(returnDate).add(i, "d").format("DD/MM/YYYY"));
    }
    setDepartDates(departDates);
    setReturnDates(returnDates);
  }, [departDate, returnDate]);

  const getCurrencySymbol = () => {
    switch (currency) {
      case "GBP":
        return "£";
      case "EURO":
        return "€";
      case "USD":
        return "$";
      default:
        return "$";
    }
  };

  const getMargin = (type) => {
    switch (type) {
      case "ADT":
        return Number(margins[0].adult);
      case "CHD":
        return Number(margins[0].child);
      case "INF":
        return Number(margins[0].infant);
      case "SRC":
        return Number(margins[0].senior);
      case "YTH":
        return Number(margins[0].youth);
      default:
        return Number(margins[0].adult);
    }
  };

  const getTotalPrice = () => {
    let price = 0;
    if (rate === null) {
      if (priceQuote) {
        price =
          Number(priceQuote?.total?.total) +
          adults * getMargin("ADT") +
          childs * getMargin("CHD") +
          infants * getMargin("INF") +
          seniorCitizens * getMargin("SRC") +
          youths * getMargin("YTH");
      } else {
        price = 0;
      }
    } else {
      if (priceQuote && priceQuote?.currency == "IRR") {
        price = (
          (Number(priceQuote?.total?.total) +
            adults * getMargin("ADT") * rate +
            childs * getMargin("CHD") * rate +
            infants * getMargin("INF") * rate +
            seniorCitizens * getMargin("SRC") * rate +
            youths * getMargin("YTH") * rate) / rate
        ).toFixed(1);
      } else if (priceQuote && priceQuote.currency == "GBP") {
        price =
          Number(priceQuote?.total?.total) +
          adults * getMargin("ADT") +
          childs * getMargin("CHD") +
          infants * getMargin("INF") +
          seniorCitizens * getMargin("SRC") +
          youths * getMargin("YTH");
      } else {
        price = 0;
      }
    }

    return price;
  };

  const isCanContinue = () => {
    if (
      priceQuote &&
      Object.keys(priceQuote).length &&
      activeDepartFlight &&
      (searchType == "OW" || activeReturnFlight)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container sx={{ py: 3 }}>
      <Button variant="outlined" sx={{ mb: 2 }} onClick={() => navigate("/")}>
        <Icon icon="mdi:chevron-left" style={{ fontSize: 20 }} /> Go home
      </Button>
      <Grid container spacing={2}>
        <Grid item lg={9} xs={12}>
          <DepartureFlightList flights={departFlights} dates={departDates} />
          {searchType === "RT" && (
            <ReturnFlightList flights={returnFlights} dates={returnDates} />
          )}
          <Box sx={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              color="success"
              sx={{ py: 0.25, background: "#FAFFEB", mr: 1.5 }}
            >
              <Box>
                <Typography sx={{ lineHeight: 1.0, fontWeight: 700 }}>
                  {getCurrencySymbol()}
                  {getTotalPrice()}
                </Typography>
                <Typography sx={{ fontSize: 10 }}>Total Price</Typography>
              </Box>
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ background: "#2B0EAF" }}
              disabled={!isCanContinue()}
              onClick={() => navigate("/passenger-information")}
            >
              Book
            </Button>
          </Box>
        </Grid>
        <Grid item lg={3} xs={12}>
          <OrderDetailBox path="/passenger-information" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Search;
