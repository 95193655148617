import { useState, useEffect } from "react";
import Image from "../Image";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import Http from "../../services/Http";

const CheckoutDialog = (props) => {
  const { open, currency, amount, pnr, passengers, clientSecret, handleClose } = props;
  const [stripePromise, setStripePromise] = useState("");

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#0570de",
      borderRadius: "5px",
      spacingUnit: "5px",
      fontSizeBase: "20px",
    },
    rules: {
      ".Label": {
        marginBottom: "10px",
      },
    },
  };

  useEffect(() => {
    (async () => {
        const { data } = await Http.get('payment/get-payment-key')
        setStripePromise(loadStripe(data.STRIPE_PRIVATE_KEY))
    })()
  }, [])

  return (
    <Dialog fullWidth maxWidth='md' open={open} onClose={handleClose}>
        <DialogTitle>
            <Box>
                <Typography variant='h5'>Payment Details</Typography>
                <IconButton 
                    onClick={handleClose}
                    sx={{ 
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        color: '#aaa'
                    }}
                >
                    <Icon icon='mdi:close'/>
                </IconButton>
            </Box>
        </DialogTitle>
        <DialogContent>
            {clientSecret && (
                <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                    <CheckoutForm pnr={pnr} currency={currency} amount={amount} passengers={passengers}/>
                </Elements>
            )}
        </DialogContent>
    </Dialog>
  )
};

export default CheckoutDialog;
