import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Grid, Typography, Button } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import Http from "../../services/Http";
import PassengerForm from "../../components/Passengers/PassengerForm";
import PassengerContactForm from "../../components/Passengers/PassengerContactForm";
import OrderDetailBox from "../../components/Passengers/OrderDetailBox";
import toast from "react-hot-toast";
import {
  setPaxDetailsDTOList,
  setSegWiseAncillaryDetails,
  setServiceAncillaryDetails
} from "../../store/reducers/flightSlice";
import moment from 'moment'

const PassengerInformation = () => {
  const { cookie, setLoading } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uuid = useSelector((state) => state.flight.uuid);
  const agentId = useSelector((state) => state.flight.agentId);
  const airlineId = useSelector((state) => state.flight.airlineId);
  const searchType = useSelector((state) => state.flight.searchType);
  const currency = useSelector((state) => state.flight.currency);
  const adults = useSelector((state) => state.flight.adults);
  const departFlight = useSelector((state) => state.flight.activeDepartFlight);
  const returnFlight = useSelector((state) => state.flight.activeReturnFlight);
  const passengers = useSelector((state) => state.flight.paxDetailsDTOList);
  const [extraBaggagePrice, setExtraBaggagePrice] = useState(0);

  useEffect(() => {
    if (agentId === 0) {
      navigate("/");
    }
  }, [agentId]);

  useEffect(() => {
    const anchor = document.querySelector("body");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
    fetchBaggagePrice();
  }, []);

  const fetchBaggagePrice = async () => {
    setLoading(true);
    const { data: prices } = await Http.get("/crms/global-baggage-prices");
    const price = prices.find((price) => price.currency === currency);
    setExtraBaggagePrice(price.amount);
    setTimeout(() => setLoading(false), 500);
  };

  const updatePassenger = (id, key, val) => {
    dispatch(
      setPaxDetailsDTOList(
        passengers.map((passenger, idx) => {
          let _passenger = { ...passenger };
          if (id === idx) {
            if ((_passenger.paxType == 'ADT' || _passenger.paxType == 'SRC' || _passenger.paxType == 'YTH') && key == 'title') {
              if (val == '1') {
                _passenger.gender = 'M'
              } else {
                _passenger.gender = 'F'
              }
            } else if ((_passenger.paxType == 'ADT' || _passenger.paxType == 'SRC' || _passenger.paxType == 'YTH') && key == 'gender') {
              if (val == 'M') {
                _passenger.title = 1
              } else if (_passenger.title == 1) {
                _passenger.title = 2
              }
            } else if (_passenger.paxType == 'CHD' && key == 'title') {
              if (val == '4') {
                _passenger.gender = 'F'
              } else {
                _passenger.gender = 'M'
              }
            } else if (_passenger.paxType == 'CHD' && key == 'gender') {
              if (val == 'M') {
                _passenger.title = '5'
              } else {
                _passenger.title = '4'
              }
            }
            _passenger[key] = val;
          }

          return _passenger;
        })
      )
    );
  };

  const handleAncillarySearch = async () => {
    let status = true;
    for (let passenger of passengers) {
      if (passenger.paxType == "ADT") {
        if (!moment(passenger.dob).isBefore(moment().subtract(12, "y"))) {
          status = false;
          toast.error(
            `Adult's Date of Birth should be less than ${moment()
              .subtract(12, "y")
              .format("DD/MM/YYYY")}`
          );
          break;
        }
      }
      if (passenger.paxType == "CHD") {
        if (
          !(
            moment(passenger.dob).isAfter(moment().subtract(12, "y")) &&
            moment(passenger.dob).isBefore(moment().subtract(2, "y"))
          )
        ) {
          status = false;
          toast.error(
            `Child's Date of Birth should be greater than ${moment()
              .subtract(12, "y")
              .format("DD/MM/YYYY")} and less than ${moment()
              .subtract(2, "y")
              .format("DD/MM/YYYY")}`
          );
          break;
        }
      }
      if (passenger.paxType == "INF") {
        if (
          !(
            moment(passenger.dob).isAfter(moment().subtract(2, "y")) &&
            moment(passenger.dob).isBefore(moment())
          )
        ) {
          status = false;
          toast.error(
            `Infant's Date of Birth should be greater than ${moment()
              .subtract(2, "y")
              .format("DD/MM/YYYY")} and less than ${moment().format(
              "DD/MM/YYYY"
            )}`
          );
          break;
        }
      }
      if (passenger.paxType == "SRC") {
        if (
          !(
            moment(passenger.dob).isBefore(moment().subtract(60, "y"))
          )
        ) {
          status = false;
          toast.error(
            `Senior Citizen's Date of Birth should be less than ${moment()
              .subtract(2, "y")
              .format("DD/MM/YYYY")}
            )}`
          );
          break;
        }
      }
      if (passenger.paxType == "YTH") {
        if (
          !(
            moment(passenger.dob).isAfter(moment().subtract(16, "y")) &&
            moment(passenger.dob).isBefore(moment().subtract(12, "y"))
          )
        ) {
          status = false;
          toast.error(
            `Youth's Date of Birth should be greater than ${moment()
              .subtract(16, "y")
              .format("DD/MM/YYYY")} and less than ${moment().subtract(12, "y").format(
              "DD/MM/YYYY"
            )}`
          );
          break;
        }
      }
      if (moment(passenger.psptExpiryDate).diff(moment(), 'months') < 6) {
        status = false;
        toast.error(`Please check passenger ${passenger.paxIndex + 1}'s passport expiry date.`);
      }
    }

    if (status) {
      let bookingPaxTypes = passengers.map((passenger) => passenger.paxType);

      let params = {
        ancillarySearchAgent: agentId,
        bkgWithExtraSeatAndWaitlist: false,
        bookingPaxTypes: bookingPaxTypes,
        rand: new Date().toISOString(),
        transferSegmentSearch: false,
        uuid: uuid,
      };

      setLoading(true);

      const { data } = await Http.post("/flight/ancillarySearch", {
        cookie: cookie,
        params: params,
      });

      if (data.status) {
        let flightTypes = {},
          paxDetailsDTOList = [];
        flightTypes[departFlight.fltSegID] = departFlight.flightType;
        if (searchType === "RT") {
          flightTypes[returnFlight.fltSegID] = returnFlight.flightType;
        }
        paxDetailsDTOList = passengers.map((passenger, idx) => {
          return {
            airlineID: airlineId,
            paxIndex: passenger.paxIndex,
            paxType: passenger.paxType,
            ffpNumber: passenger.ffpNumber,
            firstName: passenger.firstName,
            lastName: passenger.lastName,
            gender: passenger.gender,
            dob: passenger.dob,
            nationality: passenger.nationality.id,
            psptExpiryDate: passenger.psptExpiryDate,
            psptIssuedCountry: passenger.psptIssuedCountry.id,
            psptNumber: passenger.psptNumber,
            sequence: idx,
          };
        });

        let params = {
          bookingPaxTypes: bookingPaxTypes,
          flightTypes: flightTypes,
          paxDetailsDTOList: paxDetailsDTOList,
          posInfo: null,
          rand: new Date().toISOString(),
          transferSegmentSearch: false,
          uuid: uuid,
        };

        const { data: result } = await Http.post("/flight/baggageDetails", {
          cookie,
          params,
        });

        if (result.status) {
          let _paxDetailsDTOList = passengers.map((paxDetailDTO, idx) => {
            return {
              ...paxDetailDTO,
              isDepartureExtraBaggage: false,
              isReturnExtraBaggage: false,
            };
          });
          dispatch(setPaxDetailsDTOList(_paxDetailsDTOList));
          dispatch(
            setSegWiseAncillaryDetails(result.result.segWiseAncillaryDetails)
          );
          let params = {
            ancillarySearchAgent: agentId,
            bkgWithExtraSeatAndWaitlist: false,
            bookingPaxTypes: bookingPaxTypes,
            extraSeatType: [],
            flightTypes: flightTypes,
            paxDetailsDTOList: paxDetailsDTOList,
            rand: new Date().toISOString(),
            transferSegmentSearch: false,
            uuid: uuid
          }

          const { data: result1 } = await Http.post('/flight/ssrDetails', {
            cookie,
            params
          })

          if (result1.status) {
            dispatch(setServiceAncillaryDetails(result1.result.segWiseAncillaryDetails))
            navigate("/extra-baggage");
          } else {
            toast.error(result1.msg)
          }
        } else {
          toast.error(result.msg);
        }
      } else {
        toast.error(data.msg);
      }
      setLoading(false);
    }
  };

  return (
    <Container sx={{ py: 3 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Traveler Information
      </Typography>
      <Typography
        sx={{
          p: 2,
          color: "#1C3E57",
          background: "#F5F7FF",
          borderLeft: "4px solid #5B6DCC",
          mb: 3,
        }}
      >
        Please enter your first name, middle names and last name exactly as they
        appear on your passport to avoid issues at the airport, If there is a
        middle name, please enter it in the name field.
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => navigate("/search")}
        sx={{ mb: 2 }}
      >
        Go back
      </Button>
      <Grid container spacing={3}>
        <Grid item lg={8} md={7} xs={12}>
          {passengers.map((passenger, idx) => (
            <PassengerForm
              key={idx}
              no={idx + 1}
              adults={adults}
              passenger={passenger}
              baggagePrice={extraBaggagePrice}
              updatePassenger={updatePassenger}
            />
          ))}
          <PassengerContactForm />
        </Grid>
        <Grid item lg={4} md={5} xs={12}>
          <OrderDetailBox
            passengers={passengers}
            handleAncillarySearch={handleAncillarySearch}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PassengerInformation;
