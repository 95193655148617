import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Chip,
  Button,
} from "@mui/material";
import Image from "../Image";
import moment from "moment";

const OrderDetailBox = (props) => {
  const navigate = useNavigate();
  const { path } = props;
  const searchType = useSelector((state) => state.flight.searchType);
  const cabinClassCode = useSelector((state) => state.flight.cabinClassCode);
  const currency = useSelector((state) => state.flight.currency);
  const margins = useSelector((state) => state.flight.margins);
  const rate = useSelector((state) => state.flight.rate);
  const adults = useSelector((state) => state.flight.adults);
  const childs = useSelector((state) => state.flight.childs);
  const infants = useSelector((state) => state.flight.infants);
  const seniorCitizens = useSelector((state) => state.flight.seniorCitizens);
  const youths = useSelector((state) => state.flight.youths);
  const weights = useSelector((state) => state.flight.weights);
  const departAirport = useSelector((state) => state.flight.departAirport);
  const returnAirport = useSelector((state) => state.flight.returnAirport);
  const loginState = useSelector((state) => state.flight.loginState);
  const activeDepartFlight = useSelector(
    (state) => state.flight.activeDepartFlight
  );
  const activeReturnFlight = useSelector(
    (state) => state.flight.activeReturnFlight
  );
  const priceQuote = useSelector((state) => state.flight.priceQuote);

  const formatDate = (date) => {
    return moment(date, "DD/MM/YYYY HH:mm").format("ddd DD MMM, YYYY");
  };

  const formatTime = (date) => {
    return moment(date, "DD/MM/YYYY HH:mm").format("HH:mm");
  };

  const getCurrencySymbol = () => {
    switch (currency) {
      case "GBP":
        return "£";
      case "EURO":
        return "€";
      case "USD":
        return "$";
      default:
        return "$";
    }
  };

  const getMargin = (type) => {
    switch (type) {
      case "ADT":
        return Number(margins[0].adult);
      case "CHD":
        return Number(margins[0].child);
      case "INF":
        return Number(margins[0].infant);
      case "SRC":
        return Number(margins[0].senior);
      case "YTH":
        return Number(margins[0].youth);
      default:
        return Number(margins[0].adult);
    }
  };

  const getPricePerPaxType = (type) => {
    let price = 0;
    if (rate === null) {
      price = Number(priceQuote.perPaxTotal.paxPrice[type]) + getMargin(type);
    } else {
      if (priceQuote && priceQuote.currency == "IRR")
        price = (
          (Number(priceQuote.perPaxTotal.paxPrice[type]) +
            getMargin(type) * rate) /
          rate
        ).toFixed(1);
      else if (priceQuote && priceQuote.currency == "GBP")
        price = Number(priceQuote.perPaxTotal.paxPrice[type]) + getMargin(type);
      else price = 0;
    }
    return price;
  };

  const getTotalPrice = () => {
    let price = 0;
    if (rate === null) {
      if (priceQuote) {
        price =
          Number(priceQuote.total.total) +
          adults * getMargin("ADT") +
          childs * getMargin("CHD") +
          infants * getMargin("INF") +
          seniorCitizens * getMargin("SRC") +
          youths * getMargin("YTH");
      } else {
        price = 0;
      }
    } else {
      if (priceQuote && priceQuote.currency == "IRR") {
        price = (
          (Number(priceQuote.total.total) +
            adults * getMargin("ADT") * rate +
            childs * getMargin("CHD") * rate +
            infants * getMargin("INF") * rate +
            seniorCitizens * getMargin("SRC") * rate +
            youths * getMargin("YTH") * rate) /
          rate
        ).toFixed(1);
      } else if (priceQuote && priceQuote.currency == "GBP") {
        price =
          Number(priceQuote.total.total) +
          adults * getMargin("ADT") +
          childs * getMargin("CHD") +
          infants * getMargin("INF") +
          seniorCitizens * getMargin("SRC") +
          youths * getMargin("YTH");
      } else {
        price = 0;
      }
    }
    return price;
  };

  const getBaggageWeight = () => {
    const weight = weights.find(
      (weight) =>
        weight.paxType == "ADT" && weight.cabinClassCode == cabinClassCode
    );

    return loginState == "IR" ? weight.IRBaggageWeight : weight.UKBaggageWeight;
  };

  const isCanContinue = () => {
    if (
      priceQuote &&
      Object.keys(priceQuote).length &&
      activeDepartFlight &&
      (searchType === "OW" || activeReturnFlight)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box sx={{ display: "flex", position: "sticky", top: 160 }}>
      <Card sx={{ flex: 1 }}>
        <CardHeader
          sx={{ py: 1 }}
          title={<Typography variant="h6">Your Order</Typography>}
        />
        <Divider />
        <CardContent>
          {activeDepartFlight && (
            <Box sx={{ mb: 1.5 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography variant="h6">Departure</Typography>
                  <Typography>
                    {formatDate(activeDepartFlight.departureDateLocalStr)}
                  </Typography>
                </Box>
                <Box>
                  <Image
                    src={"/images/iranair.png"}
                    alt="Iranair"
                    width={100}
                  />
                </Box>
              </Box>
              <Typography variant="h6">
                {formatTime(activeDepartFlight.departureDateLocalStr)} -{" "}
                {formatTime(activeDepartFlight.arrivalDateLocalStr)} (
                {activeDepartFlight.duration})
              </Typography>
              <Typography>
                ({departAirport.airportCode}) {departAirport.displayShortName} -
                ({returnAirport.airportCode}) {returnAirport.displayShortName}
              </Typography>
            </Box>
          )}
          {searchType === "RT" && activeReturnFlight && (
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography variant="h6">Return</Typography>
                  <Typography>
                    {formatDate(activeReturnFlight.departureDateLocalStr)}
                  </Typography>
                </Box>
                <Box>
                  <Image
                    src={"/images/iranair.png"}
                    alt="Iranair"
                    width={100}
                  />
                </Box>
              </Box>
              <Typography variant="h6">
                {formatTime(activeReturnFlight.departureDateLocalStr)} -{" "}
                {formatTime(activeReturnFlight.arrivalDateLocalStr)} (
                {activeReturnFlight.duration})
              </Typography>
              <Typography>
                ({returnAirport.airportCode}) {returnAirport.displayShortName} -
                ({departAirport.airportCode}) {departAirport.displayShortName}
              </Typography>
            </Box>
          )}
        </CardContent>
        <Divider>
          <Chip label="Travel details" />
        </Divider>
        <CardContent sx={{ pt: 0, borderBottom: "1px solid #ccc" }}>
          {priceQuote && (
            <Box>
              {adults > 0 && (
                <Box>
                  <Typography variant="h6">{adults} x Adult</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography>Price per Adult </Typography>
                    <Typography>
                      {getCurrencySymbol()}
                      {getPricePerPaxType("ADT")}
                    </Typography>
                  </Box>
                </Box>
              )}
              {childs > 0 && (
                <Box>
                  <Typography variant="h6">{childs} x Child</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography>Price per Child</Typography>
                    <Typography>
                      {getCurrencySymbol()}
                      {getPricePerPaxType("CHD")}
                    </Typography>
                  </Box>
                </Box>
              )}
              {infants > 0 && (
                <Box>
                  <Typography variant="h6">{infants} x Infant</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography>Price per Infant</Typography>
                    <Typography>
                      {getCurrencySymbol()}
                      {getPricePerPaxType("INF")}
                    </Typography>
                  </Box>
                </Box>
              )}
              {seniorCitizens > 0 && (
                <Box>
                  <Typography variant="h6">
                    {seniorCitizens} x Senior Citizen (60 +)
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography>Price per Senior Citizen</Typography>
                    <Typography>
                      {getCurrencySymbol()}
                      {getPricePerPaxType("SRC")}
                    </Typography>
                  </Box>
                </Box>
              )}
              {youths > 0 && (
                <Box>
                  <Typography variant="h6">{youths} x Youth (12-15)</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography>Price per Youth (12-15)</Typography>
                    <Typography>
                      {getCurrencySymbol()}
                      {getPricePerPaxType("YTH")}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </CardContent>
        <CardContent sx={{ pt: 2 }}>
          <Box sx={{ mb: 1.5 }}>
            <Typography variant="h6">Hand Baggage 1x5KG </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 13.5,
                fontWeight: 100,
                mb: 1,
              }}
            >
              <Image
                src={"/images/hand-baggage.png"}
                alt="Hand baggage"
                sx={{ width: 18, height: 19, marginRight: 5 }}
              />
              1x5 KG included for all passengers
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">Checked In Baggage</Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 13.5,
                fontWeight: 100,
              }}
            >
              <Image
                src={"/images/checked-baggage.png"}
                alt="Checked baggage"
                sx={{ width: 16, height: 15, marginRight: 5 }}
              />
              {getBaggageWeight()}KG included
            </Typography>
          </Box>
        </CardContent>
        <Divider />
        <CardContent>
          <Typography variant="h6">Total amount</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography>Subtotal</Typography>
            <Typography>
              {getCurrencySymbol()}
              {getTotalPrice()}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h6">Amount to pay</Typography>
            <Typography variant="h6">
              {getCurrencySymbol()}
              {getTotalPrice()}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{ background: "#2B0EAF", width: "100%", py: 1, mb: 2 }}
            disabled={!isCanContinue()}
            onClick={() => navigate(path)}
          >
            Continue
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{ width: "100%" }}
            onClick={() => navigate("/")}
          >
            Edit Search
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default OrderDetailBox;
