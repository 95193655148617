import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  FormControl,
  TextField,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Typography,
  Zoom,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import DatePickerWrapper from "../../components/DatePickerWrapper";
import DatePicker from "react-datepicker";
import { useAuth } from "../../hooks/useAuth";
import Http from "../../services/Http";
import { toast } from "react-hot-toast";
import moment from "moment";
import Image from "../../components/Image";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowClasses,
  setAgentId,
  setAvailableBalance,
  setUuid,
  setCurrency,
  setMargins,
  setBaggageMargins,
  setRate,
  setLoginState,
  setAirlineId,
  setPnr,
  setEmail,
  setMobileDialCode,
  setMobile,
  setTelephoneDialCode,
  setTelephone,
  setSearchType,
  setAirports,
  setCabinClasses,
  setCabinClassCode,
  setAdults,
  setChilds,
  setInfants,
  setDepartAirport,
  setReturnAirport,
  setDepartDate,
  setReturnDate,
  setAvailableFltSegments,
  setJourneys,
  setPriceQuote,
  setBaseCurrencyCode,
  setPaxDetailsDTOList,
  setSeniorCitizens,
  setYouths,
  setWeights,
} from "../../store/reducers/flightSlice";
import { useNavigate } from "react-router-dom";
import { ExpandMoreOutlined } from "@mui/icons-material";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cookie, setLoading, setCookie } = useAuth();
  const uuid = useSelector((state) => state.flight.uuid);
  const searchType = useSelector((state) => state.flight.searchType);
  const airlineId = useSelector((state) => state.flight.airlineId);
  const cabinClasses = useSelector((state) => state.flight.cabinClasses);
  const airports = useSelector((state) => state.flight.airports);
  const adults = useSelector((state) => state.flight.adults);
  const childs = useSelector((state) => state.flight.childs);
  const infants = useSelector((state) => state.flight.infants);

  const seniorCitizens = useSelector((state) => state.flight.seniorCitizens);
  const youths = useSelector((state) => state.flight.youths);

  const currency = useSelector(state => state.flight.currency)
  const cabinClassCode = useSelector((state) => state.flight.cabinClassCode);
  const departAirport = useSelector((state) => state.flight.departAirport);
  const returnAirport = useSelector((state) => state.flight.returnAirport);
  const departDate = useSelector((state) => state.flight.departDate);
  const returnDate = useSelector((state) => state.flight.returnDate);
  const baseCurrencyCode = useSelector(
    (state) => state.flight.baseCurrencyCode
  );
  const [passengerAnchorEl, setPassengerAnchorEl] = useState(false);
  const [ukUsername, setUKUsername] = useState('')
  const [ukPassword, setUKPassword] = useState('')
  const [irUsername, setIRUsername] = useState('')
  const [irPassword, setIRPassword] = useState('')

  useEffect(() => {
    if (!!cookie) {
      fetchInitData(cookie);
    }
  }, [cookie]);

  useEffect(() => {
    const fetchCredential = async () => {
      const { data } = await Http.get('/crms/flight/credential')
      if (data) {
        setUKUsername(data.ukUsername)
        setUKPassword(data.ukPassword)
        setIRUsername(data.irUsername)
        setIRPassword(data.irPassword)
        dispatch(setShowClasses(data.showClasses));
      } 

      iranAirLogin(data.ukUsername, data.ukPassword);
      dispatch(setLoginState(localStorage.getItem('stateLogin')))
    }
    fetchCredential();
    const anchor = document.querySelector("body");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const iranAirLogin = async (name, pwd) => {

    setLoading(true)
    let carrierID = 15,
      carrierCode = "IR",
      keySize = 128,
      iterationCount = 1000,
      passphrase = "pss@avileague",
      jUsername = name,
      jPassword = pwd,
      startLength = CryptoJS.lib.WordArray.random(128 / 8).toString(
        CryptoJS.enc.Hex
      ),
      endLength = CryptoJS.lib.WordArray.random(128 / 8).toString(
        CryptoJS.enc.Hex
      ),
      aes = new AesUtil(keySize, iterationCount),
      password = aes.encrypt(startLength, endLength, passphrase, jPassword);

    let body = {
      j_username: jUsername,
      username: `${carrierCode}:${jUsername}`.toUpperCase(),
      captcha: "",
      language: "en",
      carrierID: carrierID,
      carrierCode: carrierCode,
      password: `${password} ${endLength} ${startLength}`,
    };
    const { data } = await Http.post('/flight/login', body)
    if (data?.status) {
      localStorage.setItem('cookie', data.cookie)
      setCookie(data.cookie)
      await fetchInitData(data.cookie);
    } else {
      localStorage.removeItem('cookie')
      setCookie(null)
      await iranAirLogin(name, pwd)
    }
    setLoading(false)
  }
  
  const callIranAirLogin = async() => {
    
    let loginStateResult;
    if (departAirport.airportCountryCode == "IR") {
      loginStateResult = "IR"
      const { data } = await Http.post('/flight/getRate', {
        currency: currency,
        loginAgent: "IR"
      })

      if (data.status) {
        dispatch(setRate(data?.rate?.rate))
      }
     
      dispatch(setLoginState('IR'))
      window.localStorage.setItem("stateLogin", "IR")
      await iranAirLogin(irUsername, irPassword)
      return loginStateResult;
    }
    else {
      loginStateResult = 'UK'
      const { data } = await Http.post('/flight/getRate', {
        currency: currency,
        loginAgent: ""
      })

      if (data.status) {
        dispatch(setRate(data?.rate?.rate))
      }
  
      dispatch(setLoginState('UK'))
      window.localStorage.setItem("stateLogin", "UK")
      await iranAirLogin(ukUsername, ukPassword)
      return loginStateResult;
    }
  }

  const fetchInitData = async (cookie) => {
    setLoading(true);
    let { data } = await Http.post("/flight/init", {
      cookie: cookie,
      type: "global",
    });

    if (data.status) {
      const agent = JSON.parse(data.result.agent);
      const groupAirlines = JSON.parse(data.result.groupAirlines);
      const airlineId = Object.keys(groupAirlines)[0];
      const airports = Object.values(JSON.parse(data.result.airlineAirports));
      const cabinClasses = JSON.parse(data.result.cabinClass);
      dispatch(setAgentId(agent.agentID));
      dispatch(setAvailableBalance(agent.availableBalance))
      dispatch(setAirlineId(airlineId));
      dispatch(setEmail(data.email));
      dispatch(setMobileDialCode(data.mobileDialCode));
      dispatch(setMobile(data.mobile));
      dispatch(setTelephoneDialCode(data.telephoneDialCode));
      dispatch(setTelephone(data.telephone));
      dispatch(setCurrency(data.currency));
      dispatch(setRate(data.rate?.rate));
      dispatch(setWeights(data.weights))
      dispatch(setAirports(airports));
      dispatch(setCabinClasses(cabinClasses));

      const { data: result1 } = await Http.post("/flight/bookingInit", {
        cookie: cookie,
      });

      if (result1.status) {
        const uuid = JSON.parse(result1.result.UUID);
        dispatch(setUuid(uuid));
      } else {
        toast.error(result1.msg);
      }
      getPnr(cookie);
    } else {
      toast.error(data.msg);
    }
    setLoading(false);
  };

  const getPnr = async (cookie) => {
    const { data } = await Http.post("/flight/getPNR", {
      cookie: cookie,
    });

    if (data.status) {
      dispatch(setPnr(data.result));
    } else {
      toast.error(data.msg);
    }
  };

  const getPassengers = () => {
    let label = "";
    if (adults == 1) {
      label = "1 Adult";
    } else if (adults > 1) {
      label = `${adults} Adults`;
    }
    if (childs == 1) {
      label += `, 1 Child`;
    } else if (childs > 1) {
      label += `, ${childs} Children`;
    }
    if (infants == 1) {
      label += `, 1 Infant`;
    } else if (infants > 1) {
      label += `, ${infants} Infants`;
    }
    if (seniorCitizens == 1) {
      label += `, 1 Senior Citizen`;
    } else if (seniorCitizens > 1) {
      label += `, ${seniorCitizens} Senior Citizens`;
    }
    if(youths == 1) {
      label += `, 1 Youth`;
    } else if (youths > 1) {
      label += `, ${youths} Youths`
    }

    return label;
  };

  const handleSwapDestination = () => {
    if (departAirport && returnAirport) {
      let _returnAirport = { ...departAirport };
      let _departAirport = { ...returnAirport };
      dispatch(setDepartAirport(_departAirport));
      dispatch(setReturnAirport(_returnAirport));
    } else {
      toast.error("Please choose the from and to field.");
    }
  };

  const handleSearch = async () => {
    if (!departAirport) {
      toast.error("Flying from is required field.");
    } else if (!returnAirport) {
      toast.error("Flying to is a required field.");
    } else {
      const loginState = await callIranAirLogin();
      setLoading(true);
      let searchedOnDInfos = [
        {
          advanceRoutingCode: "",
          airlineID: airlineId,
          cabinClassCode: cabinClassCode,
          depDayVariance: "PlusMinusThreeDays",
          destination: {
            airportCodes: [returnAirport.airportCode],
            code: returnAirport.airportCode,
            isAirport: true,
          },
          origin: {
            airportCodes: [departAirport.airportCode],
            code: departAirport.airportCode,
            isAirport: true,
          },
          selectedDepDateStr: moment(departDate).format("DD/MM/YYYY"),
          sequence: 1,
        },
      ];

      if (searchType === "RT") {
        searchedOnDInfos.push({
          advanceRoutingCode: "",
          airlineID: airlineId,
          cabinClassCode: cabinClassCode,
          depDayVariance: "PlusMinusThreeDays",
          destination: {
            airportCodes: [departAirport.airportCode],
            code: departAirport.airportCode,
            isAirport: true,
          },
          origin: {
            airportCodes: [returnAirport.airportCode],
            code: returnAirport.airportCode,
            isAirport: true,
          },
          selectedDepDateStr: moment(returnDate).format("DD/MM/YYYY"),
          sequence: 2,
        });
      }

      const params = {
        availabilitySearchType: searchType,
        posInfo: null,
        pqPref: {
          preferredCurrencyCode: baseCurrencyCode,
          salesTypePref: "STANDARD",
        },
        rand: new Date().toISOString(),
        searchBehaviour: {
          quotePriceForAllJourneys: false,
          sameFareProductPerOnd: false,
        },
        searchRequestContext: {
          includeWaitlistFares: false,
          modifyBookingContext: false,
          opnRtnConfirmationSearch: false,
          opnRtnFareSearch: false,
          overbookingConfirmSearch: false,
          rescheduleSegmentWithoutReprice: false,
        },
        searchedOnDInfos: searchedOnDInfos,
        travelPaxInfo: {
          adultCount: adults + childs + seniorCitizens + youths,
          infantCount: infants,
          extraSeatQuantities: {},
          paxQuantities: {
            ADT: adults.toString(),
            CHD: childs.toString(),
            INF: infants.toString(),
            SRC: seniorCitizens.toString(),
            YTH: youths.toString()
          },
        },
        uuid: uuid,
      };

      
      const { data } = await Http.post("/flight/search", {
        cookie,
        params,
        currency,
        cabinClassCode,
        searchType,
        type: "global",
        loginState
      });

      if (data.status) {
        if (data.result) {
          let availableFltSegments = Object.values(
            data.result.availableFltSegments
          ).map((flgSegment) => flgSegment[0]);
          dispatch(setAvailableFltSegments(availableFltSegments));
          dispatch(setJourneys(data.result.journeys));
          if (data.result.priceQuote) dispatch(setPriceQuote(data.result.priceQuote));
          dispatch(setBaseCurrencyCode(data.result.baseCurrencyCode));
          if (data.margins && data.margins.length) dispatch(setMargins(data.margins));
          if (data.baggageMargins && data.baggageMargins.length) dispatch(setBaggageMargins(data.baggageMargins));
          initPassengers();
          navigate("/search");
        } else {
          toast.error(
            "No availability for requested search. No Flight combinations."
          );
        }
      } else {
        toast.error(data.msg);
      }
      setLoading(false);
    }
  };

  const initPassengers = () => {
    let _passengers = [];
    for (let i = 0; i < adults; i++) {
      _passengers.push({
        paxType: "ADT",
        paxIndex: i,
        title: "",
        firstName: "",
        lastName: "",
        gender: "",
        ffpNumber: "IR",
        dob: null,
        nationality: null,
        psptNumber: "",
        psptIssuedCountry: null,
        psptExpiryDate: null,
        isDepartExtraBaggage: false,
        isReturnExtraBaggage: false,
        isDepartWheelchair: false,
        isReturnWheelchair: false
      });
    }

    for (let i = 0; i < childs; i++) {
      _passengers.push({
        paxType: "CHD",
        paxIndex: i,
        title: "",
        firstName: "",
        lastName: "",
        gender: "",
        ffpNumber: "IR",
        dob: null,
        nationality: null,
        psptNumber: "",
        psptIssuedCountry: null,
        psptExpiryDate: null,
        isDepartExtraBaggage: false,
        isReturnExtraBaggage: false,
        isDepartWheelchair: false,
        isReturnWheelchair: false
      });
    }

    for (let i = 0; i < infants; i++) {
      _passengers.push({
        paxType: "INF",
        paxIndex: i,
        title: "",
        firstName: "",
        lastName: "",
        gender: "",
        ffpNumber: "IR",
        dob: null,
        nationality: null,
        psptNumber: "",
        psptIssuedCountry: null,
        psptExpiryDate: null,
        isDepartExtraBaggage: false,
        isReturnExtraBaggage: false,
        isDepartWheelchair: false,
        isReturnWheelchair: false
      });
    }

    for (let i = 0; i < seniorCitizens; i++) {
      _passengers.push({
        paxType: "SRC",
        paxIndex: i,
        title: "",
        firstName: "",
        lastName: "",
        gender: "",
        ffpNumber: "IR",
        dob: null,
        nationality: null,
        psptNumber: "",
        psptIssuedCountry: null,
        psptExpiryDate: null,
        isDepartExtraBaggage: false,
        isReturnExtraBaggage: false,
        isDepartWheelchair: false,
        isReturnWheelchair: false
      });
    }

    for (let i = 0; i < youths; i++) {
      _passengers.push({
        paxType: "YTH",
        paxIndex: i,
        title: "",
        firstName: "",
        lastName: "",
        gender: "",
        ffpNumber: "IR",
        dob: null,
        nationality: null,
        psptNumber: "",
        psptIssuedCountry: null,
        psptExpiryDate: null,
        isDepartExtraBaggage: false,
        isReturnExtraBaggage: false,
        isDepartWheelchair: false,
        isReturnWheelchair: false
      });
    }

    dispatch(setPaxDetailsDTOList(_passengers));
  };

  return (
    <Box>
      <Box className="section hero-v4 h4 wf-section">
        <Box className="wrapper top2">
          <Box>
            <div
              id="w-node-_7879e9b4-b0bb-9ebc-68de-d94055ee78a3-68dd3fa3"
              className="div-block top"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  pt: 5,
                  flex: 1,
                }}
              >
                <Zoom in={true} style={{ transitionDelay: "500ms" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src="images/Flight.svg"
                      loading="lazy"
                      width="67"
                      alt=""
                      className="justlogo"
                    />
                    <h1
                      data-w-id="9ccf03b1-8fb4-7693-f856-4d4fec5c828f"
                      className="heading-6"
                    >
                      IRAN Air Booking
                    </h1>
                  </Box>
                </Zoom>
                <p className="text-big">
                  Easy and convenient online booking for Iran Air flights
                </p>
                <div className="div-block-27">
                  <div className="list-grid-item">
                    <div className="check-circle-2">
                      <img src="images/icon-check-small.svg" alt="" />
                    </div>
                    <div className="text-small-2">Instant Booking</div>
                  </div>
                  <div className="list-grid-item sw">
                    <div className="check-circle-2">
                      <img src="images/icon-check-small.svg" alt="" />
                    </div>
                    <div className="text-small-2">Online Payment</div>
                  </div>
                  <div className="list-grid-item sw">
                    <div className="check-circle-2">
                      <img src="images/icon-check-small.svg" alt="" />
                    </div>
                    <div className="text-small-2">24/7 Customer Service</div>
                  </div>
                </div>
                <DatePickerWrapper
                  sx={{ width: "calc(100% - 80px)", maxWidth: 1240 }}
                >
                  <Box
                    sx={{
                      background: "#fff",
                      borderRadius: 4,
                      px: 3,
                      pt: 3,
                      pb: 2,
                    }}
                  >
                    <FormControl sx={{ mb: 1 }}>
                      <RadioGroup
                        row
                        value={searchType}
                        onChange={(ev) =>
                          dispatch(setSearchType(ev.target.value))
                        }
                      >
                        <FormControlLabel
                          value="RT"
                          control={<Radio />}
                          label="Return"
                        />
                        <FormControlLabel
                          value="OW"
                          control={<Radio />}
                          label="One-way"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: {
                          md: "nowrap",
                          xs: "wrap",
                        },
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: {
                              md: "nowrap",
                              xs: "wrap",
                            },
                          }}
                        >
                          <FormControl fullWidth sx={{ mb: 3 }}>
                            <Autocomplete
                              value={departAirport}
                              options={airports}
                              getOptionLabel={(option) =>
                                `(${option.airportCode}) ${option.displayShortName}`
                              }
                              onChange={(ev, val) =>
                                dispatch(setDepartAirport(val))
                              }
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((elm) => {
                                  if (
                                    elm.displayShortName
                                      .toLowerCase()
                                      .includes(
                                        state.inputValue.toLowerCase()
                                      ) ||
                                    elm.airportCode
                                      .toLowerCase()
                                      .includes(state.inputValue.toLowerCase())
                                  ) {
                                    newOptions.push(elm);
                                  }
                                });

                                return newOptions;
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <Box>
                                    <Typography sx={{ lineHeight: 1 }}>
                                      {option.airportCode}
                                    </Typography>
                                    <Typography sx={{ fontSize: 10.5 }}>
                                      {option.displayName}
                                    </Typography>
                                  </Box>
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="From"
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Icon icon="mdi:airplane-takeoff" />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                          <Button
                            variant="text"
                            size="large"
                            sx={{
                              py: "15px !important",
                              px: "5px !important",
                              minWidth: 55,
                              mb: 3,
                              display: {
                                xs: "none",
                                lg: "inline-flex",
                              },
                              "&.MuiButtonBase-root": {
                                borderRadius: "50px !important",
                              },
                            }}
                            onClick={handleSwapDestination}
                          >
                            <Image
                              src="/images/swap.svg"
                              alt="Swap"
                              width="20"
                              height="25"
                            />
                          </Button>
                          <FormControl fullWidth sx={{ mb: 3 }}>
                            <Autocomplete
                              value={returnAirport}
                              options={airports}
                              getOptionLabel={(option) =>
                                `(${option.airportCode}) ${option.displayShortName}`
                              }
                              onChange={(ev, val) =>
                                dispatch(setReturnAirport(val))
                              }
                              filterOptions={(options, state) => {
                                let newOptions = [];
                                options.forEach((elm) => {
                                  if (
                                    elm.displayShortName
                                      .toLowerCase()
                                      .includes(
                                        state.inputValue.toLowerCase()
                                      ) ||
                                    elm.airportCode
                                      .toLowerCase()
                                      .includes(state.inputValue.toLowerCase())
                                  ) {
                                    newOptions.push(elm);
                                  }
                                });

                                return newOptions;
                              }}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <Box>
                                    <Typography sx={{ lineHeight: 1 }}>
                                      {option.airportCode}
                                    </Typography>
                                    <Typography sx={{ fontSize: 10.5 }}>
                                      {option.displayName}
                                    </Typography>
                                  </Box>
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="To"
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Icon icon="mdi:airplane-landing" />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: {
                              md: "nowrap",
                              xs: "wrap",
                            },
                          }}
                        >
                          <FormControl
                            fullWidth
                            sx={{ mb: 3, flex: searchType == "RT" ? 1 : 2 }}
                          >
                            <DatePicker
                              showYearDropdown
                              showMonthDropdown
                              popperPlacement="top-start"
                              selected={departDate}
                              minDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                              onChange={(val) => dispatch(setDepartDate(val))}
                              customInput={
                                <TextField label="Depart" fullWidth />
                              }
                            />
                          </FormControl>
                          {searchType == "RT" && (
                            <FormControl fullWidth sx={{ mb: 3, flex: 1 }}>
                              <DatePicker
                                showYearDropdown
                                showMonthDrodpwon
                                popperPlacement="top-start"
                                selected={returnDate}
                                minDate={departDate}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD/MM/YYYY"
                                onChange={(val) => dispatch(setReturnDate(val))}
                                customInput={<TextField label="Return" />}
                              />
                            </FormControl>
                          )}
                          <Box
                            sx={{
                              flexBasis: 55,
                              minWidth: 55,
                              mb: 3,
                              display: {
                                xs: "none",
                                lg: "inline-flex",
                              },
                            }}
                          />
                          <FormControl fullWidth sx={{ mb: 3, flex: 1 }}>
                            <TextField
                              label="Passengers"
                              readOnly
                              value={getPassengers()}
                              onChange={(ev) => ev.preventDefault()}
                              onClick={(ev) => {
                                setPassengerAnchorEl(ev.currentTarget);
                              }}
                            />
                            <Menu
                              anchorEl={passengerAnchorEl}
                              open={Boolean(passengerAnchorEl)}
                              onClose={() => setPassengerAnchorEl(false)}
                            >
                              <MenuItem
                                sx={{ px: 2, borderBottom: "1px solid #ccc" }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: 230,
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography>Adults</Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      variant="text"
                                      disabled={adults < 1}
                                      sx={{
                                        minWidth: 20,
                                        px: 0.2,
                                        py: 0.2,
                                        "&.MuiButtonBase-root": {
                                          borderRadius: "50px !important",
                                        },
                                      }}
                                      onClick={() =>
                                        dispatch(setAdults(adults - 1))
                                      }
                                    >
                                      <Icon
                                        icon="mdi:minus-circle-outline"
                                        style={{ fontSize: 18 }}
                                      />
                                    </Button>
                                    <Typography
                                      sx={{
                                        px: 1,
                                        minWidth: 30,
                                        textAlign: "center",
                                      }}
                                    >
                                      {adults}
                                    </Typography>
                                    <Button
                                      variant="text"
                                      sx={{
                                        minWidth: 20,
                                        px: 0.2,
                                        py: 0.2,
                                        "&.MuiButtonBase-root": {
                                          borderRadius: "50px !important",
                                        },
                                      }}
                                      onClick={() =>
                                        dispatch(setAdults(adults + 1))
                                      }
                                    >
                                      <Icon
                                        icon="mdi:plus-circle-outline"
                                        style={{ fontSize: 18 }}
                                      />
                                    </Button>
                                  </Box>
                                </Box>
                              </MenuItem>
                              <MenuItem
                                sx={{ px: 2, borderBottom: "1px solid #ccc" }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: 230,
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography>Children</Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      variant="text"
                                      disabled={childs === 0}
                                      sx={{
                                        minWidth: 20,
                                        px: 0.2,
                                        py: 0.2,
                                        "&.MuiButtonBase-root": {
                                          borderRadius: "50px !important",
                                        },
                                      }}
                                      onClick={() =>
                                        dispatch(setChilds(childs - 1))
                                      }
                                    >
                                      <Icon
                                        icon="mdi:minus-circle-outline"
                                        style={{ fontSize: 18 }}
                                      />
                                    </Button>
                                    <Typography
                                      sx={{
                                        px: 1,
                                        minWidth: 30,
                                        textAlign: "center",
                                      }}
                                    >
                                      {childs}
                                    </Typography>
                                    <Button
                                      variant="text"
                                      sx={{
                                        minWidth: 20,
                                        px: 0.2,
                                        py: 0.2,
                                        "&.MuiButtonBase-root": {
                                          borderRadius: "50px !important",
                                        },
                                      }}
                                      onClick={() =>
                                        dispatch(setChilds(childs + 1))
                                      }
                                    >
                                      <Icon
                                        icon="mdi:plus-circle-outline"
                                        style={{ fontSize: 18 }}
                                      />
                                    </Button>
                                  </Box>
                                </Box>
                              </MenuItem>
                              <MenuItem sx={{ px: 2, borderBottom: "1px solid #ccc" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: 230,
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography>Infants</Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      variant="text"
                                      disabled={infants === 0}
                                      sx={{
                                        minWidth: 20,
                                        px: 0.2,
                                        py: 0.2,
                                        "&.MuiButtonBase-root": {
                                          borderRadius: "50px !important",
                                        },
                                      }}
                                      onClick={() =>
                                        dispatch(setInfants(infants - 1))
                                      }
                                    >
                                      <Icon
                                        icon="mdi:minus-circle-outline"
                                        style={{ fontSize: 18 }}
                                      />
                                    </Button>
                                    <Typography
                                      sx={{
                                        px: 1,
                                        minWidth: 30,
                                        textAlign: "center",
                                      }}
                                    >
                                      {infants}
                                    </Typography>
                                    <Button
                                      variant="text"
                                      sx={{
                                        minWidth: 20,
                                        px: 0.2,
                                        py: 0.2,
                                        "&.MuiButtonBase-root": {
                                          borderRadius: "50px !important",
                                        },
                                      }}
                                      onClick={() =>
                                        dispatch(setInfants(infants + 1))
                                      }
                                    >
                                      <Icon
                                        icon="mdi:plus-circle-outline"
                                        style={{ fontSize: 18 }}
                                      />
                                    </Button>
                                  </Box>
                                </Box>
                              </MenuItem>
                              <MenuItem
                                sx={{ px: 2, borderBottom: "1px solid #ccc" }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: 230,
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography>Senior Citizen (60+)</Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    <Button
                                      variant="text"
                                      disabled={seniorCitizens == 0}
                                      sx={{
                                        minWidth: 20,
                                        px: 0.2,
                                        py: 0.2,
                                        "&.MuiButtonBase-root": {
                                          borderRadius: "50px !important",
                                        },
                                      }}
                                      onClick={() =>
                                        dispatch(setSeniorCitizens(seniorCitizens - 1))
                                      }
                                    >
                                      <Icon
                                        icon="mdi:minus-circle-outline"
                                        style={{ fontSize: 18 }}
                                      />
                                    </Button>
                                    <Typography
                                      sx={{
                                        px: 1,
                                        minWidth: 30,
                                        textAlign: "center",
                                      }}
                                    >
                                      {seniorCitizens}
                                    </Typography>
                                    <Button
                                      variant="text"
                                      sx={{
                                        minWidth: 20,
                                        px: 0.2,
                                        py: 0.2,
                                        "&.MuiButtonBase-root": {
                                          borderRadius: "50px !important",
                                        },
                                      }}
                                      onClick={() =>
                                        dispatch(setSeniorCitizens(seniorCitizens + 1))
                                      }
                                    >
                                      <Icon
                                        icon="mdi:plus-circle-outline"
                                        style={{ fontSize: 18 }}
                                      />
                                    </Button>
                                  </Box>
                                </Box>
                              </MenuItem>
                              <MenuItem
                                sx={{ px: 2 }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: 230,
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography>Youth (12-16)</Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      variant="text"
                                      disabled={youths == 0}
                                      sx={{
                                        minWidth: 20,
                                        px: 0.2,
                                        py: 0.2,
                                        "&.MuiButtonBase-root": {
                                          borderRadius: "50px !important",
                                        },
                                      }}
                                      onClick={() =>
                                        dispatch(setYouths(youths - 1))
                                      }
                                    >
                                      <Icon
                                        icon="mdi:minus-circle-outline"
                                        style={{ fontSize: 18 }}
                                      />
                                    </Button>
                                    <Typography
                                      sx={{
                                        px: 1,
                                        minWidth: 30,
                                        textAlign: "center",
                                      }}
                                    >
                                      {youths}
                                    </Typography>
                                    <Button
                                      variant="text"
                                      sx={{
                                        minWidth: 20,
                                        px: 0.2,
                                        py: 0.2,
                                        "&.MuiButtonBase-root": {
                                          borderRadius: "50px !important",
                                        },
                                      }}
                                      onClick={() =>
                                        dispatch(setYouths(youths + 1))
                                      }
                                    >
                                      <Icon
                                        icon="mdi:plus-circle-outline"
                                        style={{ fontSize: 18 }}
                                      />
                                    </Button>
                                  </Box>
                                </Box>
                              </MenuItem>
                            </Menu>
                          </FormControl>
                          <FormControl fullWidth sx={{ mb: 3, flex: 1 }}>
                            <InputLabel sx={{ textTransform: "none" }}>
                              Class
                            </InputLabel>
                            <Select
                              label="Class"
                              value={cabinClassCode}
                              onChange={(ev) =>
                                dispatch(setCabinClassCode(ev.target.value))
                              }
                            >
                              {cabinClasses.map((cabinClass, idx) => (
                                <MenuItem
                                  key={idx}
                                  value={cabinClass.cabinClass}
                                >
                                  {cabinClass.cabinClassName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          flexBasis: {
                            md: 230,
                            xs: "100%",
                          },
                          textAlign: "right",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="large"
                          sx={{
                            py: "15px !important",
                            textTransform: "none",
                            flex: 1,
                            ml: {
                              md: 3,
                              xs: 0,
                            },
                          }}
                          onClick={handleSearch}
                        >
                          <Icon
                            icon="mdi:search"
                            style={{ fontSize: 22, marginRight: 10 }}
                          />{" "}
                          Search flights
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </DatePickerWrapper>
              </Box>
            </div>
            <div
              id="w-node-_28df6004-54c7-6975-c355-1f26634777c3-68dd3fa3"
              className="intro full yt"
            >
              <img
                src="images/Flight.svg"
                loading="lazy"
                width="110"
                alt=""
                className="justlogo"
              />
              <h1
                data-w-id="28df6004-54c7-6975-c355-1f26634777c6"
                // style={{ opacity: 0 }}
                className="heading-6"
              >
                IRAN Air Booking
              </h1>
            </div>
          </Box>
        </Box>
        <Box className="hero-v4-bg">
          <img src="images/decor-v1.svg" alt="" className="decor-bottom" />
          <div className="bg-elements">
            <div className="bg-element-1"></div>
            <div className="bg-element-2"></div>
            <div className="bg-element-3"></div>
            <div className="bg-element-4"></div>
          </div>
        </Box>
      </Box>
      <Box className="section hide wf-section">
        <Box className="wrapper">
          <div className="w-layout-grid main-grid">
            <div
              id="w-node-_5db9c68a-778f-8343-e789-c3c552ae7205-68dd3fa3"
              data-w-id="5db9c68a-778f-8343-e789-c3c552ae7205"
              // style={{ opacity: 0 }}
              className="feature"
            >
              <img src="images/trophy.svg" alt="" className="icon" />
              <h4>Beautifully Designed</h4>
              <p className="text-grey">
                A successful marketing plan relies heavily on the pulling-power
                of advertising copy. It must appeal to, entice, and convince
                consumers to take action.
              </p>
            </div>
            <div
              id="w-node-_5db9c68a-778f-8343-e789-c3c552ae720d-68dd3fa3"
              data-w-id="5db9c68a-778f-8343-e789-c3c552ae720d"
              // style={{ opacity: 0 }}
              className="feature"
            >
              <img src="images/smartphone-tablet.svg" alt="" className="icon" />
              <h4>100% Responsive</h4>
              <p className="text-grey">
                A successful marketing plan relies heavily on the pulling-power
                of advertising copy. It must appeal to, entice, and convince
                consumers to take action.
              </p>
            </div>
            <div
              id="w-node-_5db9c68a-778f-8343-e789-c3c552ae7215-68dd3fa3"
              data-w-id="5db9c68a-778f-8343-e789-c3c552ae7215"
              // style={{ opacity: 0 }}
              className="feature"
            >
              <img src="images/workflow.svg" alt="" className="icon" />
              <h4>Smooth Interactions</h4>
              <p className="text-grey">
                A successful marketing plan relies heavily on the pulling-power
                of advertising copy. It must appeal to, entice, and convince
                consumers to take action.
              </p>
            </div>
          </div>
        </Box>
      </Box>
      <Box className="section no-padding-top wf-section" sx={{ zIndex: 1 }}>
        <Box className="wrapper">
          <div className="main-grid">
            <img
              src="images/02-Jet-Airplane-A321-Mock-Up.png"
              // style={{ opacity: 0 }}
              data-w-id="6afb3839-a63d-5692-9e9b-6d2a01844ce6"
              id="w-node-_6afb3839-a63d-5692-9e9b-6d2a01844ce6-68dd3fa3"
              alt=""
            />
            <div
              id="w-node-_6afb3839-a63d-5692-9e9b-6d2a01844ce7-68dd3fa3"
              data-w-id="6afb3839-a63d-5692-9e9b-6d2a01844ce7"
              // style={{ opacity: 0 }}
              className="intro left"
            >
              <h2 className="heading-7">Book Your Iran Air Flights Online</h2>
              <div className="divider"></div>
              <p className="text-grey color">
                Book your Iran Air flights quickly and effortlessl booking with
                user-friendly online booking system.
              </p>
              <div className="w-layout-grid bulleted-list-grid-vertical">
                <div className="bulleted-list-item bg-gray-3 yt b2">
                  <div className="check-circle-2 c2">
                    <img src="images/icon-check-small.svg" alt="" />
                  </div>
                  <div className="text-block-22">Competitive prices</div>
                </div>
                <div className="bulleted-list-item bg-gray-3 b2">
                  <div className="check-circle-2 c2">
                    <img src="images/icon-check-small.svg" alt="" />
                  </div>
                  <div className="text-block-22">Flexible Search Options</div>
                </div>
                <div className="bulleted-list-item bg-gray-3 b2">
                  <div className="check-circle-2 c2">
                    <img src="images/icon-check-small.svg" alt="" />
                  </div>
                  <div className="text-block-22">
                    Reliable 24/7Customer Support
                  </div>
                </div>
                <div className="bulleted-list-item bg-gray-3 b2">
                  <div className="check-circle-2 c2">
                    <img src="images/icon-check-small.svg" alt="" />
                  </div>
                  <div className="text-block-22">
                    Wide Range of Destinations
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
      <Box className="section dark-mask white wf-section">
        <Box className="wrapper">
          <div className="intro margin-bottom">
            <div className="text-label">Discover More</div>
            <h2 className="heading-7">Begin Your Expedition</h2>
            <div className="divider animated"></div>
            <p className="text-grey">
              Explore the captivating cities, cultural landmarks, and natural
              wonders that Iran Air connects you to.
            </p>
          </div>
          <div className="ticket">
            <img
              src="images/Ticket4.jpg"
              loading="lazy"
              sizes="(max-width: 479px) 97vw, (max-width: 816px) 98vw, 800px"
              srcSet="images/Ticket4-p-500.jpg 500w, images/Ticket4.jpg 800w"
              alt=""
            />
            <div className="div-block-28">
              <a href="#" className="button-3 tick w-button">
                Book your ticket
              </a>
            </div>
          </div>
        </Box>
        <div className="bg-elements">
          <div className="bg-element-1"></div>
          <div className="bg-element-2"></div>
          <div className="bg-element-3"></div>
          <div className="bg-element-4"></div>
        </div>
      </Box>
      <Box className="section no-padding wf-section">
        <Box className="wrapper">
          <div className="logotypes">
            <a href="#" target="_blank" className="logo-link w-inline-block">
              <img src="images/Special.svg" alt="" className="logo" />
            </a>
            <a
              href="http://elasticthemes.com"
              target="_blank"
              className="logo-link w-inline-block"
            >
              <img
                src="images/Turkish_Airlines_logo_large.svg"
                alt=""
                className="logo"
              />
            </a>
            <a href="#" target="_blank" className="logo-link w-inline-block">
              <img
                src="images/Qatar_Airways_logo.svg"
                alt=""
                className="logo"
              />
            </a>
            <a href="#" target="_blank" className="logo-link w-inline-block">
              <img src="images/Emirates_logo.svg" alt="" className="logo" />
            </a>
            <a href="#" target="_blank" className="logo-link w-inline-block">
              <img
                src="images/Etihad-airways-logo.svg"
                alt=""
                className="logo"
              />
            </a>
            <a href="#" target="_blank" className="logo-link w-inline-block">
              <img
                src="images/Pegasus_Airlines_logo.svg"
                width="143"
                alt=""
                className="logo"
              />
            </a>
          </div>
        </Box>
      </Box>
      <Box className="section with-no oferf wf-section">
        <Box className="wrapper-2">
          <div className="faq-box tr">
            <div className="faq-box-info">
              <div className="text-label-2">Answers to</div>
              <h2 className="hand">Frequently Asked Questions</h2>
              <p className="text-grey-2">
                If you couldn&#x27;t find the answer here please visit our
                complete FAQs page
              </p>
              <a href="#" className="button-2 w-button">
                Questions &amp; Answers
              </a>
              <a href="#" className="button-2 with w-button">
                Contact Us
              </a>
            </div>
            <div>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    What payment methods do you accept?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    We accept various payment methods, including major credit
                    cards such as Visa, Mastercard, and American Express. We
                    also support payment options like banking transfers. Rest
                    assured that our payment gateway is secure and your personal
                    information is protected.
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    Can I make changes to my booking after it&#x27;s confirmed?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    Yes, we understand that plans may change. Depending on the
                    fare rules and availability, you may be able to make changes
                    to your booking. However, please note that certain changes
                    may incur additional fees or charges. We recommend
                    contacting our customer support team as soon as possible to
                    inquire about any desired changes to your booking.
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    What is the baggage allowance for Iran Air flights?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    The baggage allowance for Iran Air flights may vary
                    depending on your ticket type and destination. Generally,
                    passengers are allowed to bring a certain number of checked
                    baggage and carry-on baggage within specified weight and
                    size limits. We recommend checking the baggage policy on our
                    website or contacting our customer support team for detailed
                    information regarding your specific flight.
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    What if my flight gets delayed or canceled?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    A: In the event of a flight delay or cancellation, our team
                    will work diligently to provide you with alternative
                    options. We will inform you of any changes via email or SMS,
                    and our customer support team will be available to assist
                    you in rebooking or arranging suitable alternatives.{" "}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
