import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Grid, Typography, Button } from "@mui/material";
import ExtraBaggageForm from "../../components/ExtraBaggages/ExtraBaggageForm";
import OrderDetailBox from "../../components/ExtraBaggages/OrderDetailBox";
import CheckoutDialog from "../../components/ExtraBaggages/CheckoutDialog";
import { useAuth } from "../../hooks/useAuth";
import Http from "../../services/Http";
import moment from "moment";

const ExtraBaggage = () => {
  const { setLoading, cookie } = useAuth();
  const navigate = useNavigate();
  const uuid = useSelector((state) => state.flight.uuid);
  const agentId = useSelector((state) => state.flight.agentId);
  const margins = useSelector((state) => state.flight.margins);
  const baggageMargins = useSelector((state) => state.flight.baggageMargins);
  const rate = useSelector((state) => state.flight.rate);
  const currency = useSelector((state) => state.flight.currency);
  const contactEmail = useSelector((state) => state.flight.contactEmail);
  const contactPhone = useSelector((state) => state.flight.contactPhone);
  const airlineId = useSelector((state) => state.flight.airlineId);
  const cabinClassCode = useSelector((state) => state.flight.cabinClassCode);
  const searchType = useSelector((state) => state.flight.searchType);
  const searchUuid = useSelector((state) => state.flight.searchUuid);
  const baseCurrencyCode = useSelector(
    (state) => state.flight.baseCurrencyCode
  );
  const adults = useSelector((state) => state.flight.adults);
  const childs = useSelector((state) => state.flight.childs);
  const infants = useSelector((state) => state.flight.infants);
  const seniorCitizens = useSelector((state) => state.flight.seniorCitizens);
  const youths = useSelector((state) => state.flight.youths);
  const departAirport = useSelector((state) => state.flight.departAirport);
  const activeDepartFlight = useSelector(
    (state) => state.flight.activeDepartFlight
  );
  const activeDepartRbdId = useSelector(
    (state) => state.flight.activeDepartRbdId
  );
  const returnAirport = useSelector((state) => state.flight.returnAirport);
  const activeReturnFlight = useSelector(
    (state) => state.flight.activeReturnFlight
  );
  const activeReturnRbdId = useSelector(
    (state) => state.flight.activeReturnRbdId
  );
  const email = useSelector((state) => state.flight.email);
  const mobileDialCode = useSelector((state) => state.flight.mobileDialCode);
  const mobile = useSelector((state) => state.flight.mobile);
  const telephoneDialCode = useSelector(
    (state) => state.flight.telephoneDialCode
  );
  const telephone = useSelector((state) => state.flight.telephone);
  const passengers = useSelector((state) => state.flight.paxDetailsDTOList);
  const pnr = useSelector((state) => state.flight.pnr);
  const priceQuote = useSelector((state) => state.flight.priceQuote);
  const segWiseAncillaryDetails = useSelector(
    (state) => state.flight.segWiseAncillaryDetails
  );
  const serviceAncillaryDetails = useSelector(
    (state) => state.flight.serviceAncillaryDetails
  );
  const loginState = useSelector((state) => state.flight.loginState);
  const [showCheckoutDialog, setShowCheckoutDialog] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (agentId === 0) {
      navigate("/");
    }
  }, [agentId]);

  useEffect(() => {
    const anchor = document.querySelector("body");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const getBaggageRequest = () => {
    let baggageRequest = {};
    passengers.map((passenger, idx) => {
      if (passenger.isDepartExtraBaggage || passenger.isReturnExtraBaggage) {
        let baggages = [];
        if (
          passenger.isDepartExtraBaggage &&
          segWiseAncillaryDetails[activeDepartFlight.fltSegID].length > 0
        ) {
          let departAncillaryDetail = [
            ...segWiseAncillaryDetails[activeDepartFlight.fltSegID],
          ].sort((a, b) => a.toBand - b.toBand);
          baggages.push({
            amount: departAncillaryDetail[0].amount,
            excessQuantity: departAncillaryDetail[0].toBand,
            flightSegmentID: activeDepartFlight.fltSegID,
            invBaggageID: departAncillaryDetail[0].invBagFltSegCCID,
            quantity: 1,
            segmentCode: activeDepartFlight.segmentCode,
            vatAmount: departAncillaryDetail[0].vatAmount,
          });
        }
        if (
          passenger.isReturnExtraBaggage &&
          segWiseAncillaryDetails[activeReturnFlight.fltSegID].length > 0
        ) {
          let arrivalAncillaryDetail = [
            ...segWiseAncillaryDetails[activeReturnFlight.fltSegID],
          ].sort((a, b) => a.toBand - b.toBand);
          baggages.push({
            amount: arrivalAncillaryDetail[0].amount,
            excessQuantity: arrivalAncillaryDetail[0].toBand,
            flightSegmentID: activeReturnFlight.fltSegID,
            invBaggageID: arrivalAncillaryDetail[0].invBagFltSegCCID,
            quantity: 1,
            segmentCode: activeReturnFlight.segmentCode,
            vatAmount: arrivalAncillaryDetail[0].vatAmount,
          });
        }
        baggageRequest[idx + 1] = baggages;
      }
    });

    return baggageRequest;
  };

  const getMargin = (type) => {
    switch (type) {
      case "ADT":
        return Number(margins[0].adult);
      case "CHD":
        return Number(margins[0].child);
      case "INF":
        return Number(margins[0].infant);
      case "SRC":
        return Number(margins[0].senior);
      case "YTH":
        return Number(margins[0].youth);
      default:
        return Number(margins[0].adult);
    }
  };

  const getBaggageMargin = (type) => {
    switch (type) {
      case "ADT":
        return Number(baggageMargins[0].adult);
      case "CHD":
        return Number(baggageMargins[0].child);
      case "INF":
        return Number(baggageMargins[0].infant);
      case "SRC":
        return Number(baggageMargins[0].senior);
      case "YTH":
        return Number(baggageMargins[0].youth);
      default:
        return Number(baggageMargins[0].adult);
    }
  };

  const getTotalPricePerPassenger = (passenger) => {
    let amount = Number(priceQuote.perPaxTotal.paxPrice[passenger.paxType]);
    if (
      passenger.isDepartExtraBaggage &&
      segWiseAncillaryDetails[activeDepartFlight.fltSegID].length > 0
    ) {
      let departAncillaryDetail = [
        ...segWiseAncillaryDetails[activeDepartFlight.fltSegID],
      ].sort((a, b) => a.toBand - b.toBand);
      amount += Number(departAncillaryDetail[0].amount);
    }
    if (
      passenger.isReturnExtraBaggage &&
      segWiseAncillaryDetails[activeReturnFlight.fltSegID].length > 0
    ) {
      let arrivalAncillaryDetail = [
        ...segWiseAncillaryDetails[activeReturnFlight.fltSegID],
      ].sort((a, b) => a.toBand - b.toBand);
      amount += Number(arrivalAncillaryDetail[0].amount);
    }
    return amount.toFixed(1);
  };

  const getTotalPrice = () => {
    let price = 0;

    if (rate === null) {
      if (!priceQuote) {
        price = 0;
      } else {
        price =
          Number(priceQuote.total.total) +
          adults * getMargin("ADT") +
          childs * getMargin("CHD") +
          infants * getMargin("INF") +
          seniorCitizens * getMargin("SRC") +
          youths * getMargin("YTH");
      }
    } else {
      if (priceQuote && priceQuote?.currency == "IRR") {
        price =
          (Number(priceQuote.total.total) +
            adults * getMargin("ADT") * rate +
            childs * getMargin("CHD") * rate +
            infants * getMargin("INF") * rate +
            seniorCitizens * getMargin("SRC") * rate +
            youths * getMargin("YTH") * rate) /
          rate;
      } else if (priceQuote) {
        price =
          Number(priceQuote.total.total) +
          adults * getMargin("ADT") +
          childs * getMargin("CHD") +
          infants * getMargin("INF") +
          seniorCitizens * getMargin("SRC") +
          youths * getMargin("YTH");
      } else {
        price = 0;
      }
    }
    passengers.forEach((passenger, idx) => {
      if (passenger.paxType !== "INF") {
        if (
          passenger.isDepartExtraBaggage &&
          segWiseAncillaryDetails[activeDepartFlight.fltSegID].length > 0
        ) {
          if (!segWiseAncillaryDetails[activeDepartFlight.fltSegID][0]?.name) {
            let departAncillaryDetail = [
              ...segWiseAncillaryDetails[activeDepartFlight.fltSegID],
            ].sort((a, b) => a.toBand - b.toBand);
            if (rate === null) {
              price +=
                Number(departAncillaryDetail[0]?.amount) +
                getBaggageMargin(passenger.paxType);
            } else if (priceQuote) {
              if (priceQuote.currency == "IRR")
                price +=
                  (Number(departAncillaryDetail[0]?.amount) +
                    getBaggageMargin(passenger.paxType) * rate) /
                  rate;
              else
                price +=
                  Number(departAncillaryDetail[0]?.amount) +
                  getBaggageMargin(passenger.paxType);
            }
          }
        }

        if (
          passenger.isReturnExtraBaggage &&
          segWiseAncillaryDetails[activeReturnFlight.fltSegID].length > 0
        ) {
          if (!segWiseAncillaryDetails[activeReturnFlight.fltSegID][0]?.name) {
            let arrivalAncillaryDetail = [
              ...segWiseAncillaryDetails[activeReturnFlight.fltSegID],
            ].sort((a, b) => a.toBand - b.toBand);
            if (rate === null) {
              price +=
                Number(arrivalAncillaryDetail[0].amount) +
                getBaggageMargin(passenger.paxType);
            } else if (priceQuote) {
              if (priceQuote.currency == "IRR")
                price +=
                  (Number(arrivalAncillaryDetail[0].amount) +
                    getBaggageMargin(passenger.paxType) * rate) /
                  rate;
              else
                price +=
                  Number(arrivalAncillaryDetail[0].amount) +
                  getBaggageMargin(passenger.paxType);
            }
          }
        }
      }
    });

    return price.toFixed(2);
  };

  const getSearchedOnDInfos = () => {
    let departRbdIDMap = {},
      departRbdInvTypeMap = {},
      returnRbdIDMap = {},
      returnRbdInvTypeMap = {};

    departRbdIDMap[activeDepartFlight.segmentCode] = activeDepartRbdId;
    departRbdInvTypeMap[activeDepartFlight.segmentCode] = "STANDARD";
    if (searchType === "RT") {
      returnRbdIDMap[activeReturnFlight.segmentCode] = activeReturnRbdId;
      returnRbdInvTypeMap[activeReturnFlight.segmentCode] = "STANDARD";
    }

    let searchedOnDInfos = [
      {
        advanceRoutingCode: "",
        airlineID: Number(airlineId),
        cabinClassCode: cabinClassCode,
        depDayVariance: "PlusMinusThreeDays",
        destination: {
          airportCodes: [activeDepartFlight.arrivalCode],
          code: activeDepartFlight.arrivalCode,
          isAirport: true,
        },
        fareProductMap: {},
        origin: {
          airportCodes: [activeDepartFlight.departureCode],
          code: activeDepartFlight.departureCode,
          isAirport: true,
        },
        rbdIDMap: departRbdIDMap,
        rbdInvTypeMap: departRbdInvTypeMap,
        selectedDepDateStr: moment(
          activeDepartFlight.departureDateUTCStr,
          "DD/MM/YYYY HH:mm:ss"
        ).format("DD/MM/YYYY"),
        selectedFlightList: [
          {
            flightId: `${activeDepartFlight.flightID}`,
            flightSegId: `${activeDepartFlight.fltSegID}`,
          },
        ],
        sequence: 1,
      },
    ];

    if (searchType === "RT") {
      searchedOnDInfos.push({
        advanceRoutingCode: "",
        airlineID: airlineId,
        cabinClassCode: cabinClassCode,
        depDayVariance: "PlusMinusThreeDays",
        destination: {
          airportCodes: [activeReturnFlight.arrivalCode],
          code: activeReturnFlight.arrivalCode,
          isAirport: true,
        },
        fareProductMap: {},
        origin: {
          airportCodes: [activeReturnFlight.departureCode],
          code: activeReturnFlight.departureCode,
          isAirport: true,
        },
        rbdIDMap: returnRbdIDMap,
        rbdInvTypeMap: returnRbdInvTypeMap,
        selectedDepDateStr: moment(
          activeReturnFlight.departureDateUTCStr,
          "DD/MM/YYYY HH:mm:ss"
        ).format("DD/MM/YYYY"),
        selectedFlightList: [
          {
            flightId: `${activeReturnFlight.flightID}`,
            flightSegId: `${activeReturnFlight.fltSegID}`,
          },
        ],
        sequence: 2,
      });
    }

    return searchedOnDInfos;
  };

  const getSsrRequests = () => {
    let ssrRequests = {};
    let i = 0;
    passengers.map((passenger, idx) => {
      i++;
      let ssrRequest = [];
      if (
        passenger.isDepartWheelchair &&
        serviceAncillaryDetails[activeDepartFlight.fltSegID].length > 0 &&
        serviceAncillaryDetails[activeDepartFlight.fltSegID]?.[0]?.code ==
          "WCHR"
      ) {
        ssrRequest.push({
          amount:
            serviceAncillaryDetails[activeDepartFlight.fltSegID][0].amount,
          flightSegmentID: activeDepartFlight.fltSegID,
          invSSRId:
            serviceAncillaryDetails[activeDepartFlight.fltSegID][0]
              .invSSRSegCCID,
          quantity: 1,
          segmentCode: activeDepartFlight.segmentCode,
          ssrId: serviceAncillaryDetails[activeDepartFlight.fltSegID][0].ssrID,
          ssrName: serviceAncillaryDetails[activeDepartFlight.fltSegID][0].name,
          vatAmount:
            serviceAncillaryDetails[activeDepartFlight.fltSegID][0].vatAmount,
        });
      }

      if (
        passenger.isReturnWheelchair &&
        serviceAncillaryDetails[activeReturnFlight.fltSegID].length > 0 &&
        serviceAncillaryDetails[activeReturnFlight.fltSegID]?.[0]?.code ==
          "WCHR"
      ) {
        ssrRequest.push({
          amount:
            serviceAncillaryDetails[activeReturnFlight.fltSegID][0].amount,
          flightSegmentID: activeReturnFlight.fltSegID,
          invSSRId:
            serviceAncillaryDetails[activeReturnFlight.fltSegID][0]
              .invSSRSegCCID,
          quantity: 1,
          segmentCode: activeReturnFlight.segmentCode,
          ssrId: serviceAncillaryDetails[activeReturnFlight.fltSegID][0].ssrID,
          ssrName: serviceAncillaryDetails[activeReturnFlight.fltSegID][0].name,
          vatAmount:
            serviceAncillaryDetails[activeReturnFlight.fltSegID][0].vatAmount,
        });
      }

      if (ssrRequest.length > 0) ssrRequests[i] = ssrRequest;
    });

    return ssrRequests;
  };

  const handlePayStripe = async () => {
    setLoading(true);
    const fareRuleIds = priceQuote.baseFares.map(
      (baseFare) => baseFare.fareRuleId
    );
    const { data: result1 } = await Http.post("/flight/paymentDetails", {
      cookie: cookie,
      params: {
        currency: baseCurrencyCode,
        firstFltSegOwnedByCarrier: "IR",
        operation: "CREATE_BOOKING",
        selectedFareRuleIds: fareRuleIds,
        uuid: uuid,
      },
    });

    let extraBaggagePrices = [],
      passengerPrices = [],
      actualExtraBaggagePrices = [],
      actualPassengerPrices = [];
    let totalAmount = Number(priceQuote.total.total);
    passengers.forEach((passenger, idx) => {
      if (
        passenger.isDepartExtraBaggage &&
        segWiseAncillaryDetails[activeDepartFlight.fltSegID].length > 0
      ) {
        let departAncillaryDetail = [
          ...segWiseAncillaryDetails[activeDepartFlight.fltSegID],
        ].sort((a, b) => a.toBand - b.toBand);
        let actualExtraBaggagePrice = {
          type: "OW",
          paxType: passenger.paxType,
          price: Number(departAncillaryDetail[0].amount),
        };
        let extraBaggagePrice = {
          type: "OW",
          paxType: passenger.paxType,
          price:
            Number(departAncillaryDetail[0].amount) +
            getBaggageMargin(passenger.paxType),
        };
        actualExtraBaggagePrices.push(actualExtraBaggagePrice);
        extraBaggagePrices.push(extraBaggagePrice);

        totalAmount +=
          Number(departAncillaryDetail[0].amount) +
          Number(departAncillaryDetail[0].vatAmount);
      }
      if (
        passenger.isReturnExtraBaggage &&
        segWiseAncillaryDetails[activeReturnFlight.fltSegID].length > 0
      ) {
        let arrivalAncillaryDetail = [
          ...segWiseAncillaryDetails[activeReturnFlight.fltSegID],
        ].sort((a, b) => a.toBand - b.toBand);
        let actualExtraBaggagePrice = {
          type: "RT",
          paxType: passenger.paxType,
          price: Number(arrivalAncillaryDetail[0].amount),
        };
        let extraBaggagePrice = {
          type: "RT",
          paxType: passenger.paxType,
          price:
            Number(arrivalAncillaryDetail[0].amount) +
            getBaggageMargin(passenger.paxType),
        };
        actualExtraBaggagePrices.push(actualExtraBaggagePrice);
        extraBaggagePrices.push(extraBaggagePrice);

        totalAmount +=
          Number(arrivalAncillaryDetail[0].amount) +
          Number(arrivalAncillaryDetail[0].vatAmount);
      }
    });

    if (adults > 0) {
      actualPassengerPrices.push({
        paxType: "ADT",
        count: adults,
        price: Number(priceQuote.perPaxTotal.paxPrice.ADT),
      });
      passengerPrices.push({
        paxType: "ADT",
        count: adults,
        price: Number(priceQuote.perPaxTotal.paxPrice.ADT) + getMargin("ADT"),
      });
    }
    if (childs > 0) {
      actualPassengerPrices.push({
        paxType: "CHD",
        count: childs,
        price: Number(priceQuote.perPaxTotal.paxPrice.CHD),
      });
      passengerPrices.push({
        paxType: "CHD",
        count: childs,
        price: Number(priceQuote.perPaxTotal.paxPrice.CHD) + getMargin("CHD"),
      });
    }
    if (infants > 0) {
      actualPassengerPrices.push({
        paxType: "INF",
        count: infants,
        price: Number(priceQuote.perPaxTotal.paxPrice.INF),
      });
      passengerPrices.push({
        paxType: "INF",
        count: infants,
        price: Number(priceQuote.perPaxTotal.paxPrice.INF) + getMargin("INF"),
      });
    }
    if (seniorCitizens > 0) {
      actualPassengerPrices.push({
        paxType: "SRC",
        count: seniorCitizens,
        price: Number(priceQuote.perPaxTotal.paxPrice.SRC),
      });
      passengerPrices.push({
        paxType: "SRC",
        count: seniorCitizens,
        price: Number(priceQuote.perPaxTotal.paxPrice.SRC) + getMargin("SRC"),
      });
    }
    if (youths > 0) {
      actualPassengerPrices.push({
        paxType: "YTH",
        count: youths,
        price: Number(priceQuote.perPaxTotal.paxPrice.YTH),
      });
      passengerPrices.push({
        paxType: "YTH",
        count: youths,
        price: Number(priceQuote.perPaxTotal.paxPrice.YTH) + getMargin("YTH"),
      });
    }

    const { data: result2 } = await Http.post("/flight/currencyConversion", {
      cookie: cookie,
      params: {
        amount: totalAmount.toString(),
        fromCurrency: baseCurrencyCode,
        toCurrency: baseCurrencyCode,
      },
    });

    let paxQuantities = {};
    if (adults > 0) {
      paxQuantities["ADT"] = adults.toString();
    }
    if (childs > 0) {
      paxQuantities["CHD"] = childs.toString();
    }
    if (infants > 0) {
      paxQuantities["INF"] = infants.toString();
    }
    if (seniorCitizens > 0) {
      paxQuantities["SRC"] = seniorCitizens.toString();
    }
    if (youths > 0) {
      paxQuantities["YTH"] = youths.toString();
    }

    let flightDetails = [
      {
        arrivalCode: activeDepartFlight.arrivalCode,
        departureCode: activeDepartFlight.departureCode,
        departureDateLocalStr: activeDepartFlight.departureDateLocalStr,
        departureDateUTCStr: activeDepartFlight.departureDateUTCStr,
        flightDesignator: activeDepartFlight.flightDesignator,
        operatedCarrier: activeDepartFlight.operatedCarrier,
        segmentCode: activeDepartFlight.segmentCode,
      },
    ];
    if (searchType == "RT") {
      flightDetails.push({
        arrivalCode: activeReturnFlight.arrivalCode,
        departureCode: activeReturnFlight.departureCode,
        departureDateLocalStr: activeReturnFlight.departureDateLocalStr,
        departureDateUTCStr: activeReturnFlight.departureDateUTCStr,
        flightDesignator: activeReturnFlight.flightDesignator,
        operatedCarrier: activeReturnFlight.operatedCarrier,
        segmentCode: activeReturnFlight.segmentCode,
      });
    }

    let params = {
      agentSalesUserId: null,
      ancillaryRequest: {
        baggageRequest: getBaggageRequest(),
        mealRequests: {},
        seatRequests: {},
        ssrRequests: getSsrRequests(),
      },
      bkgExtraSeatType: [],
      blockRequest: {
        deferBlockingFltSegIDs: [],
        nonBlockingFltSegIds: [],
        ovbTempBlockExistingFltSegIDs: [],
        uuid: uuid,
      },
      bookingContactInfo: {
        email: email,
        mobile: `+${mobileDialCode}-${mobile.split(mobileDialCode)[1]}`,
        telephone: `+${telephoneDialCode}-${
          telephone.split(telephoneDialCode)[1]
        }`,
      },
      bookingCurrencyCode: baseCurrencyCode,
      bookingNotes: [],
      bookingPaymentInfo: {
        onAccountPayment: {
          agentID: agentId.toString(),
          amount: totalAmount.toFixed(1).toString(),
          paymentSubMethod: result1.result.paymentMethods[0],
        },
        receiptNumber: "",
      },
      bookingPreference: {
        confirmed: true,
        standBy: false,
      },
      deliveryMethods: [],
      firstFlightDepDateTime: activeDepartFlight.departureDateUTCStr,
      firstFlightSegmentOperatingCarrier: "IR",
      flightDetails: flightDetails,
      itineraryLanguage: "",
      localForeign: "ANY",
      passengers: passengers.map((passenger, idx) => {
        if (passenger.paxType !== "INF") {
          let passengerInfo = {
            additionalInfo: {
              passportCountryID: passenger.psptIssuedCountry.id,
              passportExpiryDateStr: moment(passenger.psptExpiryDate).format(
                "DD/MM/YYYY"
              ),
              passportNumber: passenger.psptNumber,
            },
            dateOfBirthStr: moment(passenger.dob).format("DD/MM/YYYY"),
            firstName: passenger.firstName,
            lastName: passenger.lastName,
            gender: passenger.gender,
            nationalityID: passenger.nationality.id,
            passengerTitleID: passenger.title.toString(),
            paxType: passenger.paxType,
            sequence: idx + 1,
            totalAmount: getTotalPricePerPassenger(passenger).toString(),
          };
          if (idx !== 0) {
            passengerInfo.displayOrder = idx + 1;
          }
          return passengerInfo;
        } else {
          let passengerInfo = {
            additionalInfo: {
              passportCountryID: passenger.psptIssuedCountry.id,
              passportExpiryDateStr: moment(passenger.psptExpiryDate).format(
                "DD/MM/YYYY"
              ),
              passportNumber: passenger.psptNumber,
            },
            dateOfBirthStr: moment(passenger.dob).format("DD/MM/YYYY"),
            firstName: passenger.firstName,
            lastName: passenger.lastName,
            gender: passenger.gender,
            nationalityID: passenger.nationality.id,
            travelWithPaxSeq: passenger.title,
            paxType: passenger.paxType,
            sequence: idx + 1,
            displayOrder: idx + 1,
            totalAmount: getTotalPricePerPassenger(passenger).toString(),
          };
          if (idx !== 0) {
            passengerInfo.displayOrder = idx + 1;
          }
          return passengerInfo;
        }
      }),
      pnr: pnr,
      priceQuoteRequest: {
        availabilitySearchType: searchType,
        currentPerPaxTotal: priceQuote.perPaxTotal.paxPrice,
        posInfo: null,
        pqPref: {
          fareRuleAgentDiscountInfo: null,
          preferredCurrencyCode: baseCurrencyCode,
          salesTypePref: "STANDARD",
        },
        rand: new Date().toISOString(),
        searchBehaviour: {
          quotePriceForAllJourneys: false,
          sameFareProductPerOnd: false,
        },
        searchRequestContext: {
          includeWaitlistFares: false,
          modifyBookingContext: false,
          opnRtnConfirmationSearch: false,
          opnRtnFareSearch: false,
          overbookingConfirmSearch: false,
          rescheduleSegmentWithoutReprice: false,
        },
        searchedOnDInfos: getSearchedOnDInfos(),
        travelPaxInfo: {
          adultCount: adults + childs + seniorCitizens + youths,
          infantCount: infants,
          paxQuantities: paxQuantities,
          extraSeatQuantities: {},
        },
        uuid: uuid,
      },
      searchUuid: searchUuid,
      sendEmailPerPax: false,
      sendEticket: false,
      sendItineraryNotification: false,
      sendSmsNotification: false,
      unacompaniedMinorPaxBooking: false,
      uuid: uuid,
    };

    let flightType = searchType;
    let departFlight = {
      flightNo: activeDepartFlight.flightDesignator,
      flightSegId: activeDepartFlight.fltSegID,
      aircraftModel: activeDepartFlight.aircraftModel,
      cabinClassCode: cabinClassCode,
      cabinClass: cabinClassCode == "Y" ? "Economy" : "Business",
      departTerminal:
        departAirport.terminals[departAirport.airlineAirportID].terminalName,
      departCode: activeDepartFlight.departureCode,
      departCity: departAirport.displayShortName,
      departDate: activeDepartFlight.departureDateLocalStr,
      arrivalTerminal:
        returnAirport.terminals[returnAirport.airlineAirportID].terminalName,
      arrivalCode: activeDepartFlight.arrivalCode,
      arrivalCity: returnAirport.displayShortName,
      arrivalDate: activeDepartFlight.arrivalDateLocalStr,
      duration: activeDepartFlight.duration,
      isExtraBaggage:
        Object.values(getBaggageRequest()).findIndex(
          (baggageRequest) =>
            baggageRequest[0].flightSegmentID === activeDepartFlight.fltSegID
        ) !== -1
          ? true
          : false,
    };
    let arrivalFlight = {};
    if (searchType === "RT") {
      arrivalFlight = {
        flightNo: activeReturnFlight.flightDesignator,
        flightSegId: activeDepartFlight.fltSegID,
        aircraftModel: activeReturnFlight.aircraftModel,
        cabinClassCode: cabinClassCode,
        cabinClass: cabinClassCode == "Y" ? "Economy" : "Business",
        departTerminal:
          returnAirport.terminals[returnAirport.airlineAirportID].terminalName,
        departCode: activeReturnFlight.departureCode,
        departCity: returnAirport.displayShortName,
        departDate: activeReturnFlight.departureDateLocalStr,
        arrivalTerminal:
          departAirport.terminals[departAirport.airlineAirportID].terminalName,
        arrivalCode: activeReturnFlight.arrivalCode,
        arrivalCity: departAirport.displayShortName,
        arrivalDate: activeReturnFlight.arrivalDateLocalStr,
        duration: activeReturnFlight.duration,
        isExtraBaggage:
          Object.values(getBaggageRequest()).findIndex(
            (baggageRequest) =>
              baggageRequest[0].flightSegmentID === activeReturnFlight.fltSegID
          ) !== -1
            ? true
            : false,
      };
    }

    let wheelchairs = [];
    passengers.map((passenger, idx) => {
      wheelchairs.push({
        sequence: idx + 1,
        departWheelchair: passenger.isDepartWheelchair,
        returnWheelchair: passenger.isReturnWheelchair,
      });
    });

    const { data } = await Http.post("/flight/books", {
      by: "global",
      email: contactEmail,
      phone: contactPhone,
      pnr: pnr,
      flightType: flightType,
      departFlight: departFlight,
      arrivalFlight: arrivalFlight,
      adults: adults,
      childs: childs,
      infants: infants,
      seniors: seniorCitizens,
      youths: youths,
      data: params,
      status: "pending",
      extraBaggagePrices: extraBaggagePrices,
      passengerPrices: passengerPrices,
      wheelchairs: wheelchairs,
      totalAmount: getTotalPrice(),
      currency: currency,
      actualExtraBaggagePrices: actualExtraBaggagePrices,
      actualPassengerPrices: actualPassengerPrices,
      actualTotalAmount: totalAmount.toFixed(1),
      actualCurrency: baseCurrencyCode,
      isPaid: false,
      accountLoginState: loginState,
    });

    setClientSecret(data.clientSecret);
    setAmount(getTotalPrice());
    setShowCheckoutDialog(true);
    setLoading(false);
  };

  const handleCreateBooking = async () => {
    handlePayStripe();
  };

  return (
    <Container>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Traveler Information - Extra baggage
      </Typography>
      <Button
        variant="outlined"
        onClick={() => navigate("/passenger-information")}
        sx={{ mb: 2 }}
      >
        Go back
      </Button>
      <Grid container spacing={3}>
        <Grid item lg={8} md={7} xs={12}>
          {passengers.map((passenger, idx) => (
            <ExtraBaggageForm
              key={idx}
              no={idx + 1}
              passenger={passenger}
              priceQuote={priceQuote}
            />
          ))}
        </Grid>
        <Grid item lg={4} md={5} xs={12}>
          <OrderDetailBox
            handleCreateBooking={handleCreateBooking}
            priceQuote={priceQuote}
          />
        </Grid>
      </Grid>
      <CheckoutDialog
        open={showCheckoutDialog}
        clientSecret={clientSecret}
        pnr={pnr}
        amount={amount}
        currency={currency}
        passengers={passengers.length}
        handleClose={() => setShowCheckoutDialog(false)}
      />
    </Container>
  );
};

export default ExtraBaggage;
