import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer wf-section'>
      <div className='wrapper foot'>
        <div className='w-layout-grid footer-grid'>
          <img
            src='images/IrFlight.svg'
            id='w-node-e1246cca-7336-23a8-4ddc-f5b1a046a21b-d1822f9d'
            alt=''
            className='logo'
          />
          <div
            id='w-node-_4cccd955-1110-6266-a922-4b5ac4677b9d-d1822f9d'
            className='footer-v1-column'
          >
            <Link to="/" className='footer-link'>
              Home
            </Link>
            <Link to="https://irvisa.online" className='footer-link'>
              IRAN Visa
            </Link>
            <Link to="/faq" className='footer-link'>
              FAQs
            </Link> 
          </div>
          <div
            id='w-node-_63f85f00-be05-c081-0d96-9fc6d1822fb9-d1822f9d'
            className='footer-v1-column'
          >
            <Link to="/contact" className='footer-link'>
              Contact
            </Link>
            <Link to="/privacy-notice" className='footer-link'>
              Privcay Notice
            </Link>
            <Link to="/business-booking" className="footer-link">
              B2B booking
            </Link>
          </div>
          <div
            id='w-node-ecf6cc58-22d3-8ae1-4b7e-213b09e4b74f-d1822f9d'
            className='footer-v1-column b'
          >
            {/* <Link
              to="/business-booking"
              className='button-3 color-2 color4 w-button'
            >
              Business Booking
            </Link> */}
            {/* <div className='div-block-6'>
              <img
                src='images/Facebook.svg'
                loading='lazy'
                alt=''
                className='image-4'
              />
              <img
                src='images/Instagram.svg'
                loading='lazy'
                alt=''
                className='image-3'
              />
              <img
                src='images/Twitter.svg'
                loading='lazy'
                alt=''
                className='image-2'
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
