import React, { useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";

const Faq = () => {
  useEffect(() => {
    const anchor = document.querySelector("body");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div classNameName="page-wrapper">
      <div
        data-w-id="9d7a0e08-e91c-0a1e-26f8-43972dc7186a"
        className="section top-section nocss"
      >
        <div className="wrapper">
          <div className="page-intro _4">
            <h1 className="page-heading normal-color">
              Questions &amp; Answers
            </h1>
          </div>
        </div>
        <div className="bg-elements">
          <div className="bg-element-1"></div>
          <div className="bg-element-2"></div>
          <div className="bg-element-3"></div>
          <div className="bg-element-4"></div>
        </div>
      </div>
      <div className="section haze just">
        <div className="wrapper">
          <div className="sidebar-grid">
            <div
              id="w-node-bdc693aa-93d6-f004-b043-32061db55d6a-68dd3f96"
              className="sidebar sticky"
            >
              <div className="sidebar-faq-links">
                <a href="#FAQ-1" className="faq-nav-link">
                  Airline Booking
                </a>
                <a href="#FAQ-2" className="faq-nav-link">
                  <strong>Check-In and Boarding</strong>
                </a>
                <a href="#FAQ-3" className="faq-nav-link">
                  <strong>Flight Details and Schedules</strong>
                </a>
                <a href="#FAQ-4" className="faq-nav-link">
                  <strong>Airport Information</strong>
                </a>
                <a href="#FAQ-5" className="faq-nav-link">
                  <strong>Ticket Usage and Refunds</strong>
                </a>
              </div>
            </div>
            <div id="w-node-bdc693aa-93d6-f004-b043-32061db55d0c-68dd3f96">
              <div id="FAQ-1" className="faq-block first">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading">
                    <span className="text-color-2">01.</span> Airline Booking
                  </h2>
                </div>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>How do I book a flight on your website?</strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      - Booking a flight with us is easy! Simply follow these
                      steps:
                      <br />
                      Enter your departure and destination cities, along with
                      your preferred travel dates.
                      <br />- Browse through the available flight options and
                      select the one that suits you best.
                      <br />- Provide the necessary passenger details and
                      proceed to the payment page.
                      <br />- Once payment is confirmed, your flight will be
                      booked, and you will receive a confirmation email with
                      your ticket details.
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>Can I make changes to my flight booking?</strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      Yes, you can make changes to your flight booking,
                      depending on the fare rules and airline policy. Please
                      note that certain changes may be subject to additional
                      fees. To modify your booking, contact our customer support
                      team at changeflight@irflight.online
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>What payment methods do you accept?</strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      We accept various payment methods, including major credit
                      cards, debit cards, and online payment platforms. Rest
                      assured that our payment process is secure and encrypted
                      to protect your sensitive information.
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                    "&:last-of-type": {
                      borderBottomLeftRadius: "12px",
                      borderBottomRightRadius: "12px",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>
                        Can I request special assistance for my travel?
                      </strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      If you require special assistance, such as wheelchair
                      support, medical requirements, or dietary preferences,
                      please inform us at the time of booking or contact our
                      customer support team. We will do our best to accommodate
                      your needs and ensure a comfortable journey.
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div id="FAQ-2" className="faq-block">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading">
                    <span className="text-color-2">02.</span>
                    <strong>Check-In and Boarding</strong>
                  </h2>
                </div>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>
                        What is the recommended check-in time for IranAir
                        flights?
                      </strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      assengers should check-in no less than 90 minutes before
                      the scheduled departure time for domestic flights and 180
                      minutes for international flights.
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>
                        When do the gates close for IranAir flights?
                      </strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      Gates close 30 minutes before the departure time for
                      domestic flights and 45 minutes before the departure time
                      for international flights.
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                    "&:last-of-type": {
                      borderBottomLeftRadius: "12px",
                      borderBottomRightRadius: "12px",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>How do you count monthly visits?</strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      A successful marketing plan relies heavily on the
                      <a href="/" className="text-link">
                        pulling-power
                      </a>
                      of advertising copy. Writing result-oriented ad copy is
                      difficult, as it must appeal to, entice, and convince
                      consumers to take action. There is no magic formula to
                      write perfect ad copy.
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div id="FAQ-3" className="faq-block">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading">
                    <span className="text-color-2">03.</span>
                    <strong>Flight Details and Schedules</strong>
                  </h2>
                </div>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                    "&:last-of-type": {
                      borderBottomLeftRadius: "12px",
                      borderBottomRightRadius: "12px",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>
                        Can I rely on the flight details and schedules printed
                        on my ticket?
                      </strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      The flight schedules, aircraft types, and details
                      mentioned on your ticket are not guaranteed. There may be
                      substitutions, changes in schedules, or alterations to
                      stopping places.
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div id="FAQ-4" className="faq-block">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading">
                    <span className="text-color-2">04.</span>
                    <strong>Airport Information</strong>
                  </h2>
                </div>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>
                        Which airports are used for domestic and international
                        flights to/from Tehran?
                      </strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      All domestic flights to/from Tehran operate from Terminal
                      2 of Mehrabad Airport (THR), while all international
                      flights operate from Tehran Imam Khomeini Airport (IKA).
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>
                        What documents are required for IranAir flights?
                      </strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      Domestic flights require passengers to hold their ID
                      cards, and international flights require a passport and
                      other travel documents. Passengers are responsible for the
                      accuracy and authenticity of these documents
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>
                        What happens if there is a discrepancy between the
                        information on my ticket and my ID document?
                      </strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      If there is a discrepancy between the information on your
                      ticket and your ID document, you will not be accepted for
                      boarding at the airport.
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                    "&:last-of-type": {
                      borderBottomLeftRadius: "12px",
                      borderBottomRightRadius: "12px",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>Do you have any discounts for students?</strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      A successful marketing plan relies heavily on the
                      <a href="/" className="text-link">
                        pulling-power
                      </a>
                      of advertising copy. Writing result-oriented ad copy is
                      difficult, as it must appeal to, entice, and convince
                      consumers to take action. There is no magic formula to
                      write perfect ad copy.
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div id="FAQ-5" className="faq-block">
                <div className="intro left margin-bottom">
                  <h2 className="faq-block-heading">
                    <span className="text-color-2">05.</span>
                    <strong>Ticket Usage and Refunds</strong>
                  </h2>
                </div>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>
                        Are there any restrictions on the use of ticket coupons?
                      </strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      Yes, ticket coupons must be used in sequence order. Unused
                      coupons are invalid for travel and can only be refunded.
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>
                        How long can I request a refund for an unused ticket
                        coupon?
                      </strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      Unused coupon refunds are possible until 18 months after
                      ticket issuance, subject to IranAir&#x27;s commercial
                      policies. After this period, the ticket will be considered
                      disqualified with no financial value.
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>
                        What happens if there is a discrepancy between the
                        information on my ticket and my ID document?
                      </strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      If there is a discrepancy between the information on your
                      ticket and your ID document, you will not be accepted for
                      boarding at the airport.
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    mb: 2,
                    "&::before": { display: "none" },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{ px: 4, py: 1 }}
                  >
                    <h5 className="accordion-heading">
                      <strong>
                        Is wearing a mask mandatory at the airport and onboard
                        IranAir flights?
                      </strong>
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="accordion-text acc">
                      Yes, wearing a mask is mandatory at the airport and
                      onboard IranAir flights.
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
