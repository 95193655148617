import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Chip,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Image from "../Image";
import moment from "moment";
import timezone from "moment-timezone";
import airports from "../../services/Airports.json";

const OrderDetailBox = (props) => {
  const navigate = useNavigate();
  const cabinClassCode = useSelector((state) => state.flight.cabinClassCode);
  const currency = useSelector((state) => state.flight.currency);
  const margins = useSelector((state) => state.flight.margins);
  const baggageMargins = useSelector((state) => state.flight.baggageMargins);
  const rate = useSelector((state) => state.flight.rate);
  const adults = useSelector((state) => state.flight.adults);
  const childs = useSelector((state) => state.flight.childs);
  const infants = useSelector((state) => state.flight.infants);
  const seniorCitizens = useSelector((state) => state.flight.seniorCitizens);
  const youths = useSelector((state) => state.flight.youths);
  const weights = useSelector((state) => state.flight.weights);
  const searchType = useSelector((state) => state.flight.searchType);
  const departAirport = useSelector((state) => state.flight.departAirport);
  const returnAirport = useSelector((state) => state.flight.returnAirport);
  const activeDepartFlight = useSelector(
    (state) => state.flight.activeDepartFlight
  );
  const activeReturnFlight = useSelector(
    (state) => state.flight.activeReturnFlight
  );
  const priceQuote = useSelector((state) => state.flight.priceQuote);
  const passengers = useSelector((state) => state.flight.paxDetailsDTOList);
  const segWiseAncillaryDetails = useSelector(
    (state) => state.flight.segWiseAncillaryDetails
  );
  const { handleCreateBooking } = props;
  const [isTermsAndCondition, setIsTermsAndCondition] = useState(false);
  const loginState = useSelector((state) => state.flight.loginState);

  const getCurrencySymbol = () => {
    switch (currency) {
      case "GBP":
        return "£";
      case "EURO":
        return "€";
      case "USD":
        return "$";
      default:
        return "$";
    }
  };

  const formatDate = (date) => {
    return moment(date, "DD/MM/YYYY HH:mm:ss").format("ddd DD MMM, YYYY");
  };

  const formatTime = (date) => {
    return moment(date, "DD/MM/YYYY HH:mm:ss").format("HH:mm");
  };

  const getPaxTypeName = (paxType) => {
    switch (paxType) {
      case "ADT":
        return "Adult";
      case "CHD":
        return "Child";
      case "INF":
        return "Infant";
      case "SRC":
        return "Senior Citizen";
      case "YTH":
        return "Youth";
      default:
        return "Adult";
    }
  };

  const getMargin = (type) => {
    switch (type) {
      case "ADT":
        return Number(margins[0].adult);
      case "CHD":
        return Number(margins[0].child);
      case "INF":
        return Number(margins[0].infant);
      case "SRC":
        return Number(margins[0].senior);
      case "YTH":
        return Number(margins[0].youth);
      default:
        return Number(margins[0].adult);
    }
  };

  const getPricePerPaxType = (type) => {
    let price = 0;
    if (rate === null) {
      price = Number(priceQuote.perPaxTotal.paxPrice[type]) + getMargin(type);
    } else {
      if (priceQuote && priceQuote.currency == "IRR")
        price =
          (Number(priceQuote.perPaxTotal.paxPrice[type]) +
            getMargin(type) * rate) /
          rate;
      else if (priceQuote && priceQuote.currency == "GBP")
        price = Number(priceQuote.perPaxTotal.paxPrice[type]) + getMargin(type);
      else price = 0;
    }
    return price.toFixed(2);
  };

  const getBaggageMargin = (type) => {
    switch (type) {
      case "ADT":
        return Number(baggageMargins[0].adult);
      case "CHD":
        return Number(baggageMargins[0].child);
      case "INF":
        return Number(baggageMargins[0].infant);
      case "SRC":
        return Number(baggageMargins[0].senior);
      case "YTH":
        return Number(baggageMargins[0].youth);
      default:
        return Number(baggageMargins[0].adult);
    }
  };

  const getBaggagePricePerPassenger = (passenger) => {
    let price = 0;

    if (
      passenger.isDepartExtraBaggage &&
      segWiseAncillaryDetails[activeDepartFlight.fltSegID].length > 0
    ) {
      let departAncillaryDetail = [
        ...segWiseAncillaryDetails[activeDepartFlight.fltSegID],
      ].sort((a, b) => a.toBand - b.toBand);
      if (rate === null) {
        price +=
          Number(departAncillaryDetail[0]?.amount) +
          getBaggageMargin(passenger.paxType);
      } else if (priceQuote) {
        if (priceQuote?.currency == "IRR")
          price +=
            (Number(departAncillaryDetail[0]?.amount) +
              getBaggageMargin(passenger.paxType) * rate) /
            rate;
        else
          price +=
            Number(departAncillaryDetail[0]?.amount) +
            getBaggageMargin(passenger.paxType);
      }
    }
    if (
      passenger.isReturnExtraBaggage &&
      segWiseAncillaryDetails[activeReturnFlight.fltSegID].length > 0
    ) {
      let arrivalAncillaryDetail = [
        ...segWiseAncillaryDetails[activeReturnFlight.fltSegID],
      ].sort((a, b) => a.toBand - b.toBand);
      if (rate === null) {
        price +=
          Number(arrivalAncillaryDetail[0]?.amount) +
          getBaggageMargin(passenger.paxType);
      } else {
        if (priceQuote?.currency == "IRR")
          price +=
            (Number(arrivalAncillaryDetail[0]?.amount) +
              getBaggageMargin(passenger.paxType) * rate) /
            rate;
        else
          price +=
            Number(arrivalAncillaryDetail[0]?.amount) +
            getBaggageMargin(passenger.paxType);
      }
    }
    return price.toFixed(2);
  };

  const getTotalPrice = () => {
    let price = 0;

    if (rate === null) {
      if (!priceQuote) {
        price = 0;
      } else {
        price =
          Number(priceQuote.total.total) +
          adults * getMargin("ADT") +
          childs * getMargin("CHD") +
          infants * getMargin("INF") +
          seniorCitizens * getMargin("SRC") +
          youths * getMargin("YTH");
      }
    } else {
      if (priceQuote && priceQuote?.currency == "IRR") {
        price =
          (Number(priceQuote.total.total) +
            adults * getMargin("ADT") * rate +
            childs * getMargin("CHD") * rate +
            infants * getMargin("INF") * rate +
            seniorCitizens * getMargin("SRC") * rate +
            youths * getMargin("YTH") * rate) /
          rate;
      } else if (priceQuote) {
        price =
          Number(priceQuote.total.total) +
          adults * getMargin("ADT") +
          childs * getMargin("CHD") +
          infants * getMargin("INF") +
          seniorCitizens * getMargin("SRC") +
          youths * getMargin("YTH");
      } else {
        price = 0;
      }
    }
    passengers.forEach((passenger, idx) => {
      if (passenger.paxType !== "INF") {
        if (
          passenger.isDepartExtraBaggage &&
          segWiseAncillaryDetails[activeDepartFlight.fltSegID].length > 0
        ) {
          let departAncillaryDetail = [
            ...segWiseAncillaryDetails[activeDepartFlight.fltSegID],
          ].sort((a, b) => a.toBand - b.toBand);
          if (rate === null) {
            price +=
              Number(departAncillaryDetail[0].amount) +
              getBaggageMargin(passenger.paxType);
          } else if (priceQuote) {
            if (priceQuote.currency == "IRR")
              price +=
                (Number(departAncillaryDetail[0].amount) +
                  getBaggageMargin(passenger.paxType) * rate) /
                rate;
            else
              price +=
                Number(departAncillaryDetail[0].amount) +
                getBaggageMargin(passenger.paxType);
          }
        }

        if (
          passenger.isReturnExtraBaggage &&
          segWiseAncillaryDetails[activeReturnFlight.fltSegID].length > 0
        ) {
          let arrivalAncillaryDetail = [
            ...segWiseAncillaryDetails[activeReturnFlight.fltSegID],
          ].sort((a, b) => a.toBand - b.toBand);
          if (rate === null) {
            price +=
              Number(arrivalAncillaryDetail[0].amount) +
              getBaggageMargin(passenger.paxType);
          } else if (priceQuote) {
            if (priceQuote.currency == "IRR")
              price +=
                (Number(arrivalAncillaryDetail[0].amount) +
                  getBaggageMargin(passenger.paxType) * rate) /
                rate;
            else
              price +=
                Number(arrivalAncillaryDetail[0].amount) +
                getBaggageMargin(passenger.paxType);
          }
        }
      }
    });

    return price.toFixed(2);
  };

  const getBaggageWeight = () => {
    const weight = weights.find(
      (weight) =>
        weight.paxType == "ADT" && weight.cabinClassCode == cabinClassCode
    );

    return loginState == "IR" ? weight.IRBaggageWeight : weight.UKBaggageWeight;
  };

  const isCanContinue = () => {
    if (isTermsAndCondition) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box sx={{ display: "flex", position: "sticky", top: 90 }}>
      <Card sx={{ flex: 1 }}>
        <CardHeader
          sx={{ py: 1 }}
          title={<Typography variant="h6">Your Order</Typography>}
        />
        <Divider />
        <CardContent>
          {activeDepartFlight && (
            <Box sx={{ mb: 1.5 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography variant="h6">Departure</Typography>
                  <Typography>
                    {formatDate(activeDepartFlight.departureDateLocalStr)}
                  </Typography>
                </Box>
                <Box>
                  <Image
                    src={"/images/iranair.png"}
                    alt="Iranair"
                    width={150}
                  />
                </Box>
              </Box>
              <Typography variant="h6">
                {formatTime(activeDepartFlight.departureDateLocalStr)} -{" "}
                {formatTime(activeDepartFlight.arrivalDateLocalStr)} (
                {activeDepartFlight.duration})
              </Typography>
              <Typography>
                ({departAirport.airportCode}) {departAirport.displayShortName} -
                ({returnAirport.airportCode}) {returnAirport.displayShortName}
              </Typography>
            </Box>
          )}
          {searchType === "RT" && activeReturnFlight && (
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography variant="h6">Return</Typography>
                  <Typography>
                    {formatDate(activeReturnFlight.departureDateLocalStr)}
                  </Typography>
                </Box>
                <Box>
                  <Image
                    src={"/images/iranair.png"}
                    alt="Iranair"
                    width={150}
                  />
                </Box>
              </Box>
              <Typography variant="h6">
                {formatTime(activeReturnFlight.departureDateLocalStr)} -{" "}
                {formatTime(activeReturnFlight.arrivalDateLocalStr)} (
                {activeReturnFlight.duration})
              </Typography>
              <Typography>
                ({returnAirport.airportCode}) {returnAirport.displayShortName} -
                ({departAirport.airportCode}) {departAirport.displayShortName}
              </Typography>
            </Box>
          )}
        </CardContent>
        <Divider>
          <Chip label="Travel details" />
        </Divider>
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ mb: 1.5 }}>
            <Typography variant="h6">Hand baggage</Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 13.5,
                fontWeight: 100,
                mb: 1,
              }}
            >
              <Image
                src={"/images/hand-baggage.png"}
                alt="Hand baggage"
                sx={{ width: 18, height: 19, marginRight: 5 }}
              />
              1x5 KG included for all passengers
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">Checked In Baggage</Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 13.5,
                fontWeight: 100,
              }}
            >
              <Image
                src={"/images/checked-baggage.png"}
                alt="Checked baggage"
                sx={{ width: 16, height: 15, marginRight: 5 }}
              />
              {getBaggageWeight()}KG included
            </Typography>
          </Box>
        </CardContent>
        <Divider />
        <CardContent>
          {passengers.map((passenger, idx) => (
            <Box key={idx} sx={{ mb: 1.5 }}>
              <Typography variant="h6">
                Passenger {idx + 1}, {getPaxTypeName(passenger.paxType)}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>
                  Price per{" "}
                  <Box component="span" sx={{ textTransform: "lowercase" }}>
                    {getPaxTypeName(passenger.paxType)}
                  </Box>
                </Typography>
                <Typography>
                  {getCurrencySymbol()}
                  {getPricePerPaxType(passenger.paxType)}
                </Typography>
              </Box>
              {passenger.paxType != "INF" &&
                (segWiseAncillaryDetails[activeDepartFlight?.fltSegID].length >
                  0 ||
                  (searchType == "RT" &&
                    segWiseAncillaryDetails[activeReturnFlight?.fltSegID]
                      .length > 0)) && (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>
                      {" "}
                      Extra checked baggage{" "}
                      {passenger.isDepartExtraBaggage &&
                      passenger.isReturnExtraBaggage
                        ? "2"
                        : "1"}{" "}
                      x{" "}
                      {
                        weights.find(
                          (weight, idx) => weight.paxType === passenger.paxType
                        ).weightExtraBaggage
                      }
                      KG
                    </Typography>
                    <Typography>
                      {getCurrencySymbol()}
                      {getBaggagePricePerPassenger(passenger)}
                    </Typography>
                  </Box>
                )}
            </Box>
          ))}
        </CardContent>
        <Divider />
        <CardContent>
          <Typography variant="h6">Total amount</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography>Subtotal</Typography>
            <Typography>
              {getCurrencySymbol()}
              {getTotalPrice()}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h6">Amount to pay</Typography>
            <Typography variant="h6">
              {getCurrencySymbol()}
              {getTotalPrice()}
            </Typography>
          </Box>
          <FormControlLabel
            sx={{ display: "flex", alignItems: "flex-start", mb: 3 }}
            control={
              <Checkbox
                checked={isTermsAndCondition}
                onChange={(ev) => setIsTermsAndCondition(ev.target.checked)}
              />
            }
            label={
              <Typography>
                By proceeding with the checkout, you are including your
                agreement to our{" "}
                <Link to="/privacy-notice">Terms of Service</Link>. We will
                process your personal data to process your application and for
                other purposes outlined in our{" "}
                <Link to="/privacy-notice">Privacy Policy</Link>.
              </Typography>
            }
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ background: "#2B0EAF", width: "100%", py: 1, mb: 2 }}
            disabled={!isCanContinue()}
            onClick={handleCreateBooking}
          >
            Issue Booking
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{ width: "100%" }}
            onClick={() => navigate("/")}
          >
            Edit Search
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default OrderDetailBox;
