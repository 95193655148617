import { createContext, useEffect, useState } from "react";
import Http from "../services/Http";
import toast from "react-hot-toast";

const defaultProvider = {
  loading: false,
  setLoading: () => Boolean,
  cookie: window.localStorage.getItem("cookie")
    ? window.localStorage.getItem("cookie")
    : null,
  login: () => Promise.resolve(),
};

const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }) => {
  const [cookie, setCookie] = useState(defaultProvider.cookie);
  const [loading, setLoading] = useState(defaultProvider.loading);

  useEffect(() => {
    // initAuth();
  }, []);

  const initAuth = () => {
    const cookie = window.localStorage.getItem("cookie");
    if (cookie) {
      setCookie(cookie);
    } else {
      login();
    }
  };

  const login = async () => {
    setLoading(true);
    let carrierID = 15,
      carrierCode = "IR",
      keySize = 128,
      iterationCount = 1000,
      passphrase = "pss@avileague",
      jUsername = "Lon09805",
      jPassword = "Atrac@2232",
      startLength = CryptoJS.lib.WordArray.random(128 / 8).toString(
        CryptoJS.enc.Hex
      ),
      endLength = CryptoJS.lib.WordArray.random(128 / 8).toString(
        CryptoJS.enc.Hex
      ),
      aes = new AesUtil(keySize, iterationCount),
      password = aes.encrypt(startLength, endLength, passphrase, jPassword);

    let body = {
      j_username: jUsername,
      username: `${carrierCode}:${jUsername}`.toUpperCase(),
      captcha: "",
      language: "en",
      carrierID: carrierID,
      carrierCode: carrierCode,
      password: `${password} ${endLength} ${startLength}`,
    };
    
    const { data } = await Http.post("/flight/login", body);

    if (data.status) {
      localStorage.setItem("cookie", data.cookie);
      localStorage.setItem("stateLogin", "UK");
      setCookie(data.cookie);
    } else {
      localStorage.removeItem("cookie");
      setCookie(null);
      toast.error(data.msg);
    }
    setLoading(false);
  };

  const values = {
    cookie,
    login,
    loading,
    setCookie,
    setLoading,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
