import React, { Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Layout from './layouts'
import { Backdrop, CircularProgress } from '@mui/material'
import { useAuth } from './hooks/useAuth'
import { Toaster } from 'react-hot-toast'

const history = createBrowserHistory()

const App = () => {
  const { loading } = useAuth()
  return (
    <Suspense fallback={null}>
      <Router history={history}>
        <Routes>
          <Route path='/*' element={<Layout />} />
        </Routes>
      </Router>
      <Backdrop sx={{ color: '#fff', zIndex: 10000 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Toaster
        position='top-right'
        reverseOrder={false}
        gutter={8}
        containerClassName=''
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff'
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black'
            }
          }
        }}
      />
    </Suspense>
  )
}

export default App
