import { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Tab,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Icon } from "@iconify/react";
import Image from "../Image";
import moment from "moment";
import toast from "react-hot-toast";
import { useAuth } from "../../hooks/useAuth";
import {
  setActiveDepartFlight,
  setActiveDepartRbdId,
  setActiveDepartTab,
  setPriceQuote,
  setDepartDate,
  setAvailableFltSegments,
  setJourneys,
  setBaseCurrencyCode,
  setSearchUuid,
} from "../../store/reducers/flightSlice";
import Http from "../../services/Http";

const DepartureFlightList = (props) => {
  const { cookie, setLoading } = useAuth();
  const dispatch = useDispatch();
  const showClasses = useSelector(state => state.flight.showClasses);
  const uuid = useSelector((state) => state.flight.uuid);
  const searchType = useSelector((state) => state.flight.searchType);
  const airlineId = useSelector((state) => state.flight.airlineId);
  const baseCurrencyCode = useSelector(
    (state) => state.flight.baseCurrencyCode
  );
  const cabinClassCode = useSelector((state) => state.flight.cabinClassCode);
  const adults = useSelector((state) => state.flight.adults);
  const childs = useSelector((state) => state.flight.childs);
  const infants = useSelector((state) => state.flight.infants);
  const seniorCitizens = useSelector((state) => state.flight.seniorCitizens);
  const youths = useSelector((state) => state.flight.youths);
  const departAirport = useSelector((state) => state.flight.departAirport);
  const returnAirport = useSelector((state) => state.flight.returnAirport);
  const departDate = useSelector((state) => state.flight.departDate);
  const returnDate = useSelector((state) => state.flight.returnDate);
  const weights = useSelector((state) => state.flight.weights);
  const activeDepartFlight = useSelector(
    (state) => state.flight.activeDepartFlight
  );
  const activeDepartRbdId = useSelector(
    (state) => state.flight.activeDepartRbdId
  );
  const activeReturnFlight = useSelector(
    (state) => state.flight.activeReturnFlight
  );
  const activeReturnRbdId = useSelector(
    state => state.flight.activeReturnRbdId
  );
  const activeDepartTab = useSelector((state) => state.flight.activeDepartTab);
  const loginState = useSelector(state => state.flight.loginState);
  const { flights, dates } = props;
  useEffect(() => {
    let flight = getFlights(dates[Number(activeDepartTab)])[0];
    if (flight) {
      setFlight(flight);
    } else {
      dispatch(setActiveDepartFlight(null));
      dispatch(setPriceQuote(null));
    }
  }, [activeReturnFlight, activeDepartTab, flights]);

  const getFlights = (date) => {
    return flights.filter((flight) =>
      moment(flight.departureDateLocalStr, "DD/MM/YYYY").isSame(
        moment(date, "DD/MM/YYYY"),
        "day"
      )
    );
  };

  const formatDate = (date) => {
    return moment(date, "DD/MM/YYYY HH:mm:ss").format("DD MMM, ddd");
  };

  const formatTime = (date) => {
    return moment(date, "DD/MM/YYYY HH:mm:ss").format("HH:mm");
  };

  const getAdultCount = () => {
    if (adults > 1) {
      return `${adults} Adults`;
    } else {
      return `${adults} Adult`;
    }
  };

  const getChildCount = () => {
    if (childs > 1) {
      return `${childs} Children`;
    } else {
      return `${childs} Child`;
    }
  };

  const getInfantCount = () => {
    if (infants > 1) {
      return `${infants} Infants`;
    } else {
      return `${infants} Infant`;
    }
  };

  const getSeniorCount = () => {
    if (seniorCitizens > 1) {
      return `${seniorCitizens} Senior Citizens`;
    } else {
      return `${seniorCitizens} Senior Citizen`;
    }
  };

  const getYouthCount = () => {
    if (youths > 1) {
      return `${youths} Youths`;
    } else {
      return `${youths} Youth`;
    }
  };

  const getStopCount = (stops) => {
    return stops > 0 ? `${stops} stops` : "non stop";
  };

  const handleArrow = async (date) => {
    setLoading(true);
    let searchedOnDInfos = [
      {
        advanceRoutingCode: "",
        airlineID: airlineId,
        cabinClassCode: cabinClassCode,
        depDayVariance: "PlusMinusThreeDays",
        destination: {
          airportCodes: [returnAirport.airportCode],
          code: returnAirport.airportCode,
          isAirport: true,
        },
        origin: {
          airportCodes: [departAirport.airportCode],
          code: departAirport.airportCode,
          isAirport: true,
        },
        selectedDepDateStr: moment(date).format("DD/MM/YYYY"),
        sequence: 1,
      },
    ];
    if (searchType === "RT") {
      searchedOnDInfos.push({
        advanceRoutingCode: "",
        airlineID: airlineId,
        cabinClassCode: cabinClassCode,
        depDayVariance: "PlusMinusThreeDays",
        destination: {
          airportCodes: [departAirport.airportCode],
          code: departAirport.airportCode,
          isAirport: true,
        },
        origin: {
          airportCodes: [returnAirport.airportCode],
          code: returnAirport.airportCode,
          isAirport: true,
        },
        selectedDepDateStr: moment(returnDate).format("DD/MM/YYYY"),
        sequence: 2,
      });
    }

    const params = {
      availabilitySearchType: searchType,
      posInfo: null,
      pqPref: {
        preferredCurrencyCode: baseCurrencyCode,
        salesTypePref: "STANDARD",
      },
      rand: new Date().toISOString(),
      searchBehaviour: {
        quotePriceForAllJourneys: false,
        sameFareProductPerOnd: false,
      },
      searchRequestContext: {
        includeWaitlistFares: false,
        modifyBookingContext: false,
        opnRtnConfirmationSearch: false,
        opnRtnFareSearch: false,
        overbookingConfirmSearch: false,
        rescheduleSegmentWithoutReprice: false,
      },
      searchedOnDInfos: searchedOnDInfos,
      travelPaxInfo: {
        adultCount: adults + childs + seniorCitizens + youths,
        infantCount: infants,
        extraSeatQuantities: {},
        paxQuantities: {
          ADT: adults.toString(),
          CHD: childs.toString(),
          INF: infants.toString(),
          SRC: seniorCitizens.toString(),
          YTH: youths.toString(),
        },
      },
      uuid: uuid,
    };

    const { data } = await Http.post("/flight/search", {
      cookie: cookie,
      params: params,
    });

    if (data.status) {
      if (data.result) {
        let availableFltSegments = Object.values(
          data.result.availableFltSegments
        ).map((flgSegment) => flgSegment[0]);
        dispatch(setDepartDate(new Date(date)));
        dispatch(setAvailableFltSegments(availableFltSegments));
        dispatch(setJourneys(data.result.journeys));
        dispatch(setPriceQuote(data.result.priceQuote));
        dispatch(setBaseCurrencyCode(data.result.baseCurrencyCode));
      } else {
        toast.error(
          "No availability for requested search. No flight combinations."
        );
      }
    } else {
      toast.error(data.msg);
    }
    setLoading(false);
  };

  const setFlight = async (flight) => {
    dispatch(setActiveDepartFlight(flight));
    dispatch(setActiveDepartRbdId(flight.rbd[0].rbdId));
    if (searchType === "OW") {
      getPriceQuote(flight, flight.rbd[0].rbdId);
    }
  };

  const getSearchedOnDInfos = (flight, rbdId) => {
    let departRbdIDMap = {},
      departRbdInvTypeMap = {},
      returnRbdIDMap = {},
      returnRbdInvTypeMap = {};

    departRbdIDMap[flight.segmentCode] = rbdId;
    departRbdInvTypeMap[flight.segmentCode] = "STANDARD";
    if (searchType === "RT" && activeReturnFlight) {
      returnRbdIDMap[activeReturnFlight.segmentCode] =
        activeReturnRbdId;
      returnRbdInvTypeMap[activeReturnFlight.segmentCode] = "STANDARD";
    }

    let searchedOnDInfos = [
      {
        advanceRoutingCode: "",
        airlineID: airlineId,
        cabinClassCode: cabinClassCode,
        depDayVariance: "PlusMinusThreeDays",
        destination: {
          airportCodes: [flight.arrivalCode],
          code: flight.arrivalCode,
          isAirport: true,
        },
        fareProductMap: {},
        origin: {
          airportCodes: [flight.departureCode],
          code: flight.departureCode,
          isAirport: true,
        },
        rbdIDMap: departRbdIDMap,
        rbdInvTypeMap: departRbdInvTypeMap,
        selectedDepDateStr: moment(
          flight.departureDateUTCStr,
          "DD/MM/YYYY HH:mm:ss"
        ).format("DD/MM/YYYY"),
        selectedFlightList: [
          {
            flightId: `${flight.flightID}`,
            flightSegId: `${flight.fltSegID}`,
          },
        ],
        sequence: 1,
      },
    ];

    if (searchType === "RT" && activeReturnFlight) {
      searchedOnDInfos.push({
        advanceRoutingCode: "",
        airlineID: airlineId,
        cabinClassCode: cabinClassCode,
        depDayVariance: "PlusMinusThreeDays",
        destination: {
          airportCodes: [activeReturnFlight.arrivalCode],
          code: activeReturnFlight.arrivalCode,
          isAirport: true,
        },
        fareProductMap: {},
        origin: {
          airportCodes: [activeReturnFlight.departureCode],
          code: activeReturnFlight.departureCode,
          isAirport: true,
        },
        rbdIDMap: returnRbdIDMap,
        rbdInvTypeMap: returnRbdInvTypeMap,
        selectedDepDateStr: moment(
          activeReturnFlight.departureDateUTCStr,
          "DD/MM/YYYY HH:mm:ss"
        ).format("DD/MM/YYYY"),
        selectedFlightList: [
          {
            flightId: `${activeReturnFlight.flightID}`,
            flightSegId: `${activeReturnFlight.fltSegID}`,
          },
        ],
        sequence: 2,
      });
    }

    return searchedOnDInfos;
  };

  const getPriceQuote = async (flight, rbdId) => {
    setLoading(true);

    let params = {
      availabilitySearchType: searchType,
      posInfo: null,
      pqPref: {
        preferredCurrencyCode: baseCurrencyCode,
        salesTypePref: "STANDARD",
        fareRuleAgentDiscountInfo: null,
      },
      rand: new Date().toISOString(),
      searchBehaviour: {
        quotePriceForAllJourneys: false,
        sameFareProductPerOnd: false,
      },
      searchRequestContext: {
        includeWaitlistFares: false,
        modifyBookingContext: false,
        opnRtnConfirmationSearch: false,
        opnRtnFareSearch: false,
        overbookingConfirmSearch: false,
        rescheduleSegmentWithoutReprice: false,
      },
      searchedOnDInfos: getSearchedOnDInfos(flight, rbdId),
      travelPaxInfo: {
        adultCount: adults + childs + seniorCitizens + youths,
        infantCount: infants,
        paxQuantities: {
          ADT: adults.toString(),
          CHD: childs.toString(),
          INF: infants.toString(),
          SRC: seniorCitizens.toString(),
          YTH: youths.toString(),
        },
        extraSeatQuantities: {},
      },
      uuid: uuid,
    };
    const { data } = await Http.post("flight/pricequote", {
      cookie,
      params,
    });
    dispatch(setPriceQuote(data.result.priceQuote));
    getLookBookStat(flight, rbdId);
    setLoading(false);
  };

  const getLookBookStat = async (flight, rbdId) => {
    const params = {
      availabilitySearchType: searchType,
      posInfo: null,
      pqPref: {
        fareRuleAgentDiscountInfo: null,
        preferredCurrencyCode: baseCurrencyCode,
        salesTypePref: "STANDARD",
      },
      rand: Date.now(),
      searchBehaviour: {
        quotePriceForAllJourneys: false,
        sameFareProductPerOnd: false,
      },
      searchRequestContext: {
        includeWaitlistFares: false,
        modifyBookingContext: false,
        opnRtnConfirmationSearch: false,
        opnRtnFareSearch: false,
        overbookingConfirmSearch: false,
        rescheduleSegmentWithoutReprice: false,
      },
      searchedOnDInfos: getSearchedOnDInfos(flight, rbdId),
      seatAvailability: "Y",
      travelPaxInfo: {
        adultCount: adults + childs + seniorCitizens + youths,
        infantCount: infants,
        extraSeatQuantities: {},
        paxQuantities: {
          ADT: adults.toString(),
          CHD: childs.toString(),
          INF: infants.toString(),
          SRC: seniorCitizens.toString(),
          YTH: youths.toString(),
        },
      },
      uuid: uuid,
    };

    const { data } = await Http.post("/flight/lookbookstat", {
      cookie,
      params,
    });

    if (data.status) {
      dispatch(setSearchUuid(data.result.value));
    }
  };

  const handleChangeRbd = (flight, rbd) => {
    dispatch(setActiveDepartRbdId(rbd.rbdId))
    getPriceQuote(flight, rbd.rbdId)
  }

  const getBaggageWeight = () => {
    const weight = weights.find(
      (weight) =>
        weight.paxType == "ADT" && weight.cabinClassCode == cabinClassCode
    );

    return loginState == "IR" ? weight.IRBaggageWeight : weight.UKBaggageWeight;
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            mb: 1,
          }}
        >
          <Typography variant="h6">
            {departAirport?.airportCode} ({departAirport?.displayShortName})
          </Typography>
          <Image
            src={"/images/circle-plane.png"}
            alt="Circle Plane"
            sx={{ width: 60, marginLeft: 15, marginRight: 15 }}
          />
          <Typography variant="h6">
            {returnAirport?.airportCode} ({returnAirport?.displayShortName})
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #ddd",
            mb: 1,
          }}
        >
          <Typography sx={{ p: 1 }}>{getAdultCount()}</Typography>
          {childs > 0 && (
            <Typography sx={{ p: 1 }}>{getChildCount()}</Typography>
          )}
          {infants > 0 && (
            <Typography sx={{ p: 1 }}>{getInfantCount()}</Typography>
          )}
          {seniorCitizens > 0 && (
            <Typography sx={{ p: 1 }}>{getSeniorCount()}</Typography>
          )}
          {youths > 0 && (
            <Typography sx={{ p: 1 }}>{getYouthCount()}</Typography>
          )}
        </Box>
      </Box>
      <TabContext value={activeDepartTab}>
        <Box sx={{ display: "flex" }}>
          <Button
            sx={{
              flex: 0.02,
              minWidth: 30,
              px: 0,
              mb: 0,
              borderRadius: 0,
              background: "#c6c6c6",
              "&:hover": { background: "#2b0eaf" },
            }}
            variant="contained"
            onClick={() => handleArrow(moment(departDate).subtract(1, "days"))}
          >
            <Icon icon="mdi:chevron-left" style={{ fontSize: 30 }} />
          </Button>
          <TabList
            variant="fullWidth"
            sx={{
              flex: 1,
              background: "#f4f5f6",
              "& .MuiButtonBase-root": { color: "#2f2f2f" },
              "& .MuiButtonBase-root.Mui-selected": {
                color: "#2b0eaf",
                border: "1px solid #2b0eaf",
                background: "#fff",
              },
              "& .MuiTabs-indicator": { height: 5, background: "#2b0eaf" },
            }}
            onChange={(ev, val) => dispatch(setActiveDepartTab(val))}
          >
            {dates.map((date, idx) => (
              <Tab
                key={idx}
                value={`${idx}`}
                label={`${formatDate(date)} (${getFlights(date).length})`}
                sx={{ textTransform: "none", px: 0.5 }}
              />
            ))}
          </TabList>
          <Button
            sx={{
              flex: 0.02,
              minWidth: 30,
              px: 0,
              mb: 0,
              borderRadius: 0,
              background: "#c6c6c6",
              "&:hover": { background: "#2b0eaf" },
            }}
            variant="contained"
            onClick={() => handleArrow(moment(departDate).add(1, "days"))}
          >
            <Icon icon="mdi:chevron-right" style={{ fontSize: 30 }} />
          </Button>
        </Box>
        {dates.map((date, idx) => (
          <TabPanel
            key={idx}
            value={`${idx}`}
            sx={{ p: 0, border: "2px solid #2b0eaf", overflow: "auto" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={showClasses ? 6 : 5}
                    sx={{ background: "#2b0eaf", color: "#fafafa", py: 1 }}
                  >
                    <Typography>
                      <Image
                        src={"/images/white-plane.svg"}
                        alt="Airplane"
                        height="18"
                        sx={{ marginRight: 10 }}
                      />
                      <Box component="span">Departure {formatDate(date)}</Box>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ color: "#262626" }}>
                {getFlights(date).length > 0 ? (
                  getFlights(date).map((flight, idx) => (
                    <Fragment key={idx}>
                      <TableRow sx={{ borderBottom: "4px solid #f3f3f3" }}>
                        <TableCell sx={{ textAlign: "center", py: 1 }}>
                          <Box
                            sx={{
                              background: "#f4f5f6",
                              borderRadius: 1.5,
                              display: "inline-block",
                              px: 2,
                              py: 2,
                            }}
                          >
                            <Image
                              src={"/images/iranair.png"}
                              alt="Iranair"
                              width="100"
                            />
                          </Box>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", py: 1 }}>
                          <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>
                            {formatTime(flight.departureDateLocalStr)}
                          </Typography>
                          <Typography sx={{ fontSize: 14 }}>
                            {departAirport.displayShortName}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", py: 1 }}>
                          <Typography>{flight.duration}</Typography>
                          <Image
                            src="/images/divider.png"
                            width="120"
                            alt="Divider"
                          />
                          <Typography>{getStopCount(flight.stops)}</Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", py: 1 }}>
                          <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>
                            {formatTime(flight.arrivalDateLocalStr)}
                          </Typography>
                          <Typography sx={{ fontSize: 14 }}>
                            {returnAirport.displayShortName}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", py: 1 }}>
                          <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>
                            AirCraft
                          </Typography>
                          <Typography sx={{ fontSize: 14 }}>
                            {flight.aircraftModel}
                          </Typography>
                        </TableCell>
                        {showClasses && (
                        <TableCell
                          sx={{ textAlign: "center", py: 1, maxWidth: 210 }}
                        >
                          {
                          flight.rbd.map((rbd, idx) => (
                            <Button 
                              key={idx}
                              size='small'
                              color='success'
                              variant={rbd.rbdId == activeDepartRbdId ? 'contained' : 'outlined'}
                              sx={{ 
                                position: 'relative', 
                                mx: 0.2, my: 0.5, 
                                borderRadius: '20px !important', 
                                background: rbd.rbdId == activeDepartRbdId ? 'green' : '#fff',
                                border: rbd.rbdId == activeDepartRbdId ? 'none' : '1px solid green', 
                                color: rbd.rbdId == activeDepartRbdId ? '#fff' : 'green',
                                p: '2px 0px !important',
                                minWidth: 60
                              }}
                              onClick={() => handleChangeRbd(flight, rbd)}
                            >
                              <Box sx={{
                                  display: "inline-block",
                                  position: "absolute",
                                  left: 4,
                                  width: 20,
                                  height: 20,
                                  borderRadius: 5,
                                  background: "#2b0eaf",
                                  color: "#fff",
                                  lineHeight: 1.8,
                                  fontSize: 12,
                                }}>{rbd.rbdCode}</Box>
                                <Box sx={{ ml: 2 }}>
                                  {rbd.availability >= 9 ? '9+' : rbd.availability}
                                </Box>
                            </Button>
                          ))
                        }
                        </TableCell>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                          <Chip
                            sx={{
                              px: 5,
                              background: "#e7f8e9",
                              color: "#23262f",
                              py: 2.2,
                            }}
                            label={
                              <Typography
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Icon
                                  icon="mdi:bag-suitcase"
                                  style={{ fontSize: 20, marginRight: 10 }}
                                />
                                Baggage included{" "}
                                {getBaggageWeight()}
                                KG
                              </Typography>
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{ textAlign: "center", color: "red" }}
                    >
                      No result found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default DepartureFlightList;
