import React, { useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Http from '../../services/Http'
import { toast } from "react-hot-toast";

const Contact = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const anchor = document.querySelector("body");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    reason: "",
    message: "",
  };

  const formSchema = yup.object().shape({
    firstName: yup.string().required("First name is a required field."),
    lastName: yup.string().required("Last name is a required field."),
    email: yup
      .string()
      .email("Email is invalid.")
      .required("Email is a required field."),
    phone: yup.string().required("Phone is a required field."),
    reason: yup.string().required("Reason is a required field."),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const sendMessage = async (contact) => {
    const { data } = await Http.post("/contact", contact);
    if (data.status) {
      reset(defaultValues);
      navigate("/contact-confirmation");
      toast.success(data.msg);
    } else {
      toast.error(data.msg);
    }
  };

  return (
    <div>
      <div className="form-section-04 oiuy wf-section">
        <div className="big-image-form-04"></div>
        <div className="form-section-inner-04">
          <div className="section-title">Contact us</div>
          <h2 className="heading-2 cdvvf">Customer Support Center 24/7</h2>
          <p className="paragraph-6">
            Fill out this form to get in touch with our expert team. 
          </p>
          <div className="contact-method-wrap in-form-04">
            <a
              href="mailto:email@company.com"
              className="contact-method w-inline-block"
            >
              <div className="contact-icon">
                <img
                  src="https://uploads-ssl.webflow.com/62a6d1b8a434506acc2006c9/62a6d1b8a4345025f3200743_email.svg"
                  loading="lazy"
                  alt=""
                />
              </div>
              <p className="contact-text">info@irflight.online</p>
            </a>
            <a
              href="mailto:email@company.com"
              className="contact-method w-inline-block"
            >
              <div className="contact-icon">
                <img
                  src="https://uploads-ssl.webflow.com/62a6d1b8a434506acc2006c9/62a6d1b8a4345025f3200743_email.svg"
                  loading="lazy"
                  alt=""
                />
              </div>
              <p className="contact-text">Business@irflight.online</p>
            </a>
          </div>
          <div className="form-block-04 w-form">
            <form
              id="email-form-2"
              name="email-form-2"
              data-name="Email Form 2"
              method="get"
              data-wf-page-id="64afbbf9647b16dcbfb2bf6d"
              data-wf-element-id="5b732de5-20c9-c748-8674-b4c99c692240"
              onSubmit={handleSubmit(sendMessage)}
            >
              <div className="_2-questions-wrap">
                <div style={{ width: "100%" }}>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        type="text"
                        className="text-field-inform w-input"
                        maxLength="256"
                        name="First-Name-2"
                        data-name="First Name 2"
                        placeholder="First Name"
                        id="First-Name-2"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.firstName && (
                    <p style={{ color: "red", marginBottom: 0 }}>
                      {errors.firstName.message}
                    </p>
                  )}
                </div>
                <div className="form-spacer"></div>
                <div style={{ width: "100%" }}>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        type="text"
                        className="text-field-inform w-input"
                        maxLength="256"
                        name="Last-Name-2"
                        data-name="Last Name 2"
                        placeholder="Last Name"
                        id="Last-Name-2"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.lastName && (
                    <p style={{ color: "red", marginBottom: 0 }}>
                      {errors.lastName.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="form-spacer"></div>
              <div className="_2-questions-wrap">
                <div style={{ width: "100%" }}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        type="email"
                        className="text-field-inform w-input"
                        maxLength="256"
                        name="Email-2"
                        data-name="Email 2"
                        placeholder="Email"
                        id="Email-2"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.email && (
                    <p style={{ color: "red", marginBottom: 0 }}>
                      {errors.email.message}
                    </p>
                  )}
                </div>
                <div className="form-spacer"></div>
                <div style={{ width: "100%" }}>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        type="tel"
                        className="text-field-inform w-input"
                        maxLength="256"
                        name="Phone-Number-2"
                        data-name="Phone Number 2"
                        placeholder="Phone Number"
                        id="Phone-Number-2"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.phone && (
                    <p style={{ color: "red", marginBottom: 0 }}>
                      {errors.phone.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="form-spacer"></div>
              <div style={{ width: "100%" }}>
                <Controller
                  name="reason"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <select
                      id="field-2"
                      name="field-2"
                      data-name="Field 2"
                      required=""
                      className="text-field-inform w-select"
                      value={value}
                      onChange={onChange}
                    >
                      <option value="">Reason For Inquiry</option>
                      <option value="General">General</option>
                      <option value="Business">Business</option>
                      <option value="Change flight">Change flight</option>
                      <option value="Cancellation and Refund">
                        Cancellation and Refund
                      </option>
                    </select>
                  )}
                />
                {errors.reason && (
                  <p style={{ color: "red", marginBottom: 0 }}>
                    {errors.reason.message}
                  </p>
                )}
              </div>
              <div className="form-spacer"></div>
              <Controller
                name="message"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <textarea
                    rows={4}
                    placeholder="Message"
                    maxLength="5000"
                    id="Message-2"
                    name="Message-2"
                    data-name="Message 2"
                    className="text-field-inform bigger w-input"
                    value={value}
                    onChange={onChange}
                  ></textarea>
                )}
              />
              <div className="form-spacer"></div>
              <input
                type="submit"
                value="Submit"
                data-wait="Please wait..."
                className="button-9 in-form w-button"
              />
            </form>
            <div className="w-form-done">
              <div>
                Thank you! We will get back in touch with you within 48 hours.
              </div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="section dark-mask white wf-section">
        <div className="wrapper contactwrapper">
          <div className="intro margin-bottom">
            <div className="text-label">Discover More</div>
            <h2 className="heading-7">Begin Your Expedition</h2>
            <div className="divider animated"></div>
            <p className="text-grey">
              Explore the captivating cities, cultural landmarks, and natural
              wonders that Iran Air connects you to.
            </p>
          </div>
          <div className="ticket">
            <img
              src="images/Ticket4.jpg"
              loading="lazy"
              sizes="(max-width: 479px) 97vw, (max-width: 816px) 98vw, 800px"
              srcSet="images/Ticket4-p-500.jpg 500w, images/Ticket4.jpg 800w"
              alt=""
            />
            <div className="div-block-28">
              <a href="#" className="button-3 tick w-button">
                Book your ticket
              </a>
            </div>
          </div>
        </div>
        <div className="bg-elements">
          <div className="bg-element-1"></div>
          <div className="bg-element-2"></div>
          <div className="bg-element-3"></div>
          <div className="bg-element-4"></div>
        </div>
      </div>
      <div className="section with-no oferf">
        <div className="wrapper-2">
          <div className="faq-box tr">
            <div className="faq-box-info">
              <div className="text-label-2">Answers to</div>
              <h2 className="hand">Frequently Asked Questions</h2>
              <p className="text-grey-2">
                If you couldn&#x27;t find the answer here please visit our
                complete FAQs page
              </p>
              <a href="/faq" className="button-2 w-button">
                Questions &amp; Answers
              </a>
              <a href="/contact" className="button-2 with w-button">
                Contact Us
              </a>
            </div>
            <div>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    What payment methods do you accept?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    We accept various payment methods, including major credit
                    cards such as Visa, Mastercard, and American Express. We
                    also support payment options like banking transfers. Rest
                    assured that our payment gateway is secure and your personal
                    information is protected.
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    Can I make changes to my booking after it&#x27;s confirmed?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    Yes, we understand that plans may change. Depending on the
                    fare rules and availability, you may be able to make changes
                    to your booking. However, please note that certain changes
                    may incur additional fees or charges. We recommend
                    contacting our customer support team as soon as possible to
                    inquire about any desired changes to your booking.
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    What is the baggage allowance for Iran Air flights?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    The baggage allowance for Iran Air flights may vary
                    depending on your ticket type and destination. Generally,
                    passengers are allowed to bring a certain number of checked
                    baggage and carry-on baggage within specified weight and
                    size limits. We recommend checking the baggage policy on our
                    website or contacting our customer support team for detailed
                    information regarding your specific flight.
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ p: 0, boxShadow: "none", backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined sx={{ color: "black" }} />}
                  sx={{ p: 0 }}
                >
                  <h4 className="accordion-heading-2">
                    What if my flight gets delayed or canceled?
                  </h4>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="accordion-text-2">
                    A: In the event of a flight delay or cancellation, our team
                    will work diligently to provide you with alternative
                    options. We will inform you of any changes via email or SMS,
                    and our customer support team will be available to assist
                    you in rebooking or arranging suitable alternatives.{" "}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
