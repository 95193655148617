import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div
      data-collapse='medium'
      data-animation='default'
      data-duration='400'
      data-w-id='3e665832-e661-9678-7e97-efacb32f4091'
      data-easing='ease'
      data-easing2='ease'
      role='banner'
      className='nav-bar w-nav'
    >
      <div className='wrapper nav-bar-wrapper'>
        <Link
          to='/'
          aria-current='page'
          className='brand w-nav-brand w--current'
        >
          <img src='images/IrFlight.svg' alt='' className='logo' />
          <div className='logo-text'>Jules</div>
        </Link>
        <div className='navigation'>
          <nav role='navigation' className='nav-menu w-nav-menu'>
            <Link
              to='/'
              className='nav-link w-nav-link'
            >
              Home
            </Link>
            <Link to='/' className='nav-link w-nav-link'>
              Book Flights
            </Link>
            <Link to='https://irvisa.online' className='nav-link w-nav-link'>
              IRAN Visa
            </Link>
            <Link to='/faq' className='nav-link w-nav-link'>
              FAQs
            </Link>
            <Link to='/contact' className='nav-link w-nav-link'>
              Contact
            </Link>
          </nav>
          <div className='nav-search'>
            <form action='/search' className='nav-search-form w-form'>
              <img
                src='images/search-dark.svg'
                alt=''
                className='big-form-icon'
              />
              <input
                type='search'
                className='form-big-input w-input'
                autoFocus={true}
                maxLength='256'
                name='query'
                placeholder='What are you looking for?'
                id='search'
                required=''
              />
              <input
                type='submit'
                value='Search'
                className='big-form-button w-button'
              />
            </form>
            <div
              data-w-id='9674a5c1-eed1-a2b0-d17d-9572e8f7b46a'
              className='popup-bg-overlay'
            >
              <div className='modal-close-button'></div>
            </div>
          </div>
          <div className='menu-button w-clearfix w-nav-button'>
            <div className='menu-icon'>
              <div className='menu-icon-line-top'></div>
              <div className='menu-icon-line-middle'></div>
              <div className='menu-icon-line-bottom'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
